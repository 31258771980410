// slices/accountInfo/stakedInventory.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getStakedInventory } from 'api/v2/getStakedInventory';
import { RootState } from 'store';
// import { getStakedInventory } from '../../api';
import { AccountNft } from 'types';

export interface NftStakingDataType {
  stakedNfts: AccountNft[];
  unbondingNfts: AccountNft[];
  canClaimUnbondedNfts: boolean;
}

const defaultNftStakingDataType: NftStakingDataType = {
  stakedNfts: [],
  unbondingNfts: [],
  canClaimUnbondedNfts: false
};

export interface StakedInventoryData {
  stakedSouls: NftStakingDataType;
  stakedLandChests: NftStakingDataType;
  stakedLandPlotTokens: NftStakingDataType;
  stakedKoson: AccountNft[];
}

interface StakedInventoryState {
  data: StakedInventoryData | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: StakedInventoryState = {
  data: {
    stakedSouls: defaultNftStakingDataType,
    stakedLandChests: defaultNftStakingDataType,
    stakedLandPlotTokens: defaultNftStakingDataType,
    stakedKoson: []
  },
  status: 'idle',
  error: null
};

export const fetchStakedInventory = createAsyncThunk(
  'stakedInventory/fetch',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await getStakedInventory(address); // replace with your API call
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const stakedInventorySlice = createSlice({
  name: 'stakedInventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStakedInventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchStakedInventory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(
        fetchStakedInventory.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export const getAllStakedInventory = (state: RootState) => {
  const data = state.accountInfo.stakedInventory.data;
  if (data === undefined || data === null) {
    return [];
  }
  return data.stakedSouls.stakedNfts
    .concat(data.stakedLandChests.stakedNfts)
    .concat(data.stakedLandPlotTokens.stakedNfts)
    .concat(data.stakedKoson);
};

export default stakedInventorySlice.reducer;
