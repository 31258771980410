import React from 'react';
import PendingSummoningCard from './PendingSummoningCard';

const PendingSummoningsContainer = ({
  pendingSummonings
}: {
  pendingSummonings: any[];
}) => {
  return (
    <div
      className='card text-center'
      style={{ minHeight: '600px', maxHeight: '600px' }}
    >
      <div className='card-header text-left'>
        <h5 className='card-title'>Ongoing summonings</h5>
      </div>
      <div className='card-body with-scroll'>
        {pendingSummonings.length === 0 && (
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
              <h4 className='mt-5 pt-5'>No pending summonings</h4>
            </div>
          </div>
        )}
        {pendingSummonings.map((ps, i) => (
          <PendingSummoningCard
            summoningId={ps.summoning_id}
            dateToClaim={1}
            key={`pending-summoning-card-item-key-${i}`}
            isSpecialSummon={ps.nfts.length === 6}
          />
        ))}
      </div>
    </div>
  );
};

export default PendingSummoningsContainer;
