import React from 'react';
import SummoningSlot from 'pages/Summoning/SummoningSlot';
import { AccountNft } from 'types';

export const SummoningContainer = ({
  handleToggleSelect,
  getUnselectedNfts,
  isRegularSummoning
}: {
  handleToggleSelect: (nft: AccountNft) => void;
  getUnselectedNfts: () => AccountNft[];
  isRegularSummoning: boolean;
}) => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5].map((i) => {
        return (
          <div
            key={`regular-summoning-item-key-${i}`}
            className='col-lg-4 col-md-6 col-sm-12 text-center'
          >
            <SummoningSlot
              toggleSelect={handleToggleSelect}
              index={i}
              getNfts={getUnselectedNfts}
              isDisabled={isRegularSummoning ? i > 1 : false}
            />
          </div>
        );
      })}
      {/* {selectionMessageComponent} */}
    </>
  );
};
