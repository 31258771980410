import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { routeNames } from 'routes';

export const MVXConnectPage = () => {
  return (
    <div className='home d-flex flex-fill align-items-center'>
      <div className='m-auto' data-testid='unlockPage'>
        <div className='card my-4 text-center'>
          <div className='card-body py-4 px-2 px-4 mx-4'>
            <h4 className='mb-4'>Login</h4>
            <p className='mb-4'>pick a login method</p>

            <div className='d-flex flex-column flex-sm-row justify-content-center'>
              <ExtensionLoginButton
                callbackRoute={routeNames.dashboard}
                loginButtonText={'Extension'}
                className='btn btn-primary custom-btn'
              />
              <WebWalletLoginButton
                callbackRoute={routeNames.dashboard}
                loginButtonText={'Web wallet'}
                className='btn btn-primary custom-btn'
              />
              <LedgerLoginButton
                loginButtonText={'Ledger'}
                callbackRoute={routeNames.dashboard}
                className='btn btn-primary custom-btn'
              />
              <WalletConnectLoginButton
                callbackRoute={routeNames.dashboard}
                loginButtonText={'xPortal'}
                className='btn btn-primary custom-btn'
                isWalletConnectV2={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
