import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const EarnCard = ({
  title,
  titleIcon,
  navRoute,
  description,
  backgroundImageUrl,
  rewardMessage
}: {
  title: string;
  titleIcon: any;
  navRoute: string;
  description: string;
  backgroundImageUrl: string;
  rewardMessage: string;
}) => {
  return (
    <div className='card p-4'>
      <div className='card-body text-center ai-icon row'>
        <div className='col-lg-12 col-sm-12 text-center'>
          <h3 className='my-2'>
            {/* <b style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}>{title}</b> */}
            {titleIcon && <FontAwesomeIcon icon={titleIcon} className='me-2' />}
            <b>{title}</b>
          </h3>
        </div>
        <div className='col-lg-12 col-sm-12 text-center'>
          {/* <h6 style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}> */}
          <h6>{description}</h6>
        </div>
        <div className='col-lg-12 col-sm-12 text-center text-primary'>
          <strong>{rewardMessage}</strong>
        </div>
        <div className='col-lg-12 col-sm-12 text-center'>
          <Link to={navRoute} className='btn my-2 btn-primary btn-lg px-4 mt-3'>
            <FontAwesomeIcon icon={faRocket} /> Stake now
          </Link>
        </div>
      </div>
    </div>
  );
};
export default EarnCard;
