import {
  EVENT_SETTINGS,
  OriginNFTCollections,
  summoningContractAddress,
  toLocaleStringOptions
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { ReactComponent as ElrondLogo } from './../../../assets/images/aoz/egld-symbol.svg';
import { ReactComponent as KosonLogo } from './../../../assets/images/aoz/koson-symbol.svg';
import { NotEnoughSouls } from './States/NotEnoughSouls';
import { AccountNft } from 'types';
import { SummoningContainer } from './States/SummoningContainer';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';

export const NewSummoningContainer = () => {
  const [errorMessage, setErrorMessage] = useState<string>(
    MUST_SELECT_AT_LEAST_2_ERR
  );
  const [selectedSouls, setSelectedSouls] = useState<AccountNft[]>([]);
  const [totalEgldCost, setTotalEgldCost] = useState(new BigNumber(0));
  const [totalKosonCost, setTotalKosonCost] = useState(new BigNumber(0));

  const { sendTransaction, nftState, summoningState, accountState } =
    React.useContext(Web3Context);

  useEffect(() => {
    const expectedSelectionCosts = getTotalCosts(
      selectedSouls.map((ss) => ss.identifier)
    );
    if (expectedSelectionCosts === undefined) {
      return;
    }
    const validationError = getValidationError(expectedSelectionCosts);
    setErrorMessage(validationError);
    setTotalEgldCost(expectedSelectionCosts.egld);
    setTotalKosonCost(expectedSelectionCosts.koson);
  }, [selectedSouls]);

  const getValidationError = (expectedSelectionCosts: any) => {
    const hasEnoughEgld = expectedSelectionCosts?.egld.isLessThan(
      new BigNumber(accountState?.egldBalanceDenominated ?? '0')
    );
    const hasEnoughKoson = expectedSelectionCosts?.koson.isLessThanOrEqualTo(
      new BigNumber(accountState?.kosonBalanceDenominated ?? '0')
    );

    switch (selectedSouls.length) {
      case 2:
        for (let i = 0; i < selectedSouls.length; i++) {
          if (
            (selectedSouls[0].regularSummoningCount ?? 6) > 6 ||
            (selectedSouls[1].regularSummoningCount ?? 6) > 6
          ) {
            return INVALID_SOUL_FOR_REG_SUM_ERR;
          }
        }
        break;
      case 6:
        for (let i = 0; i < selectedSouls.length; i++) {
          if (selectedSouls[i].specialSummoningCount === undefined) {
            return INVALID_SOUL_FOR_SPEC_SUM_ERR;
          }
          if (selectedSouls[i].specialSummoningCount !== 0) {
            return INVALID_SOUL_FOR_SPEC_SUM_ERR;
          }
          if (!OriginNFTCollections.includes(selectedSouls[i].collection)) {
            return INVALID_SOUL_TYPE_FOR_SPEC_SUM_ERR;
          }
        }
        const collectionsUsed = selectedSouls.map((ss) => ss.collection);
        for (let i = 1; i < OriginNFTCollections.length; i++) {
          if (!collectionsUsed.includes(OriginNFTCollections[i])) {
            return INVALID_ORIGIN_ONLY_SELECTION;
          }
        }
        break;
      case 0:
      case 1:
        return MUST_SELECT_AT_LEAST_2_ERR;
      default:
        return MUST_SELECT_2_OR_6_ERR;
    }

    if (!hasEnoughEgld) {
      return INSUFFICIENT_EGLD_ERR;
    }
    if (!hasEnoughKoson) {
      return INSUFFICIENT_KOSON_ERR;
    }

    return '';
  };

  const getTotalCosts = (nftIdentifiers: string[]) => {
    if (
      !summoningState?.egldSummoningPrices ||
      !summoningState.kosonSummoningPrices
    ) {
      return;
    }

    let totalKoson = new BigNumber(0);
    for (let i = 0; i < nftIdentifiers.length; i++) {
      const nft = nftState?.accountSoulNfts.filter(
        (n) => n.identifier === nftIdentifiers[i]
      )[0];
      totalKoson = totalKoson.plus(
        summoningState?.kosonSummoningPrices[nft?.regularSummoningCount ?? 0]
      );
    }

    return {
      egld: EVENT_SETTINGS.bendisCall
        ? new BigNumber(0)
        : summoningState?.egldSummoningPrices[0],
      koson: totalKoson.plus(
        EVENT_SETTINGS.bendisCall
          ? new BigNumber(150 * nftIdentifiers.length).multipliedBy(
              new BigNumber(10).pow(18)
            )
          : new BigNumber(0)
      )
    };
  };

  const handleToggleSelect = (nft: AccountNft) => {
    if (!selectedSouls.includes(nft)) {
      setSelectedSouls([...selectedSouls, nft]);
    } else {
      setSelectedSouls(
        selectedSouls.filter((s) => s.identifier !== nft.identifier)
      );
    }
  };

  const getUnselectedNfts = () => {
    if (!nftState) return [];
    return nftState.accountSoulNfts.filter(
      (nft) => !selectedSouls.includes(nft)
    );
  };

  const handleDepositNfts = async () => {
    let data = `MultiESDTNFTTransfer@${addr2Hex(
      summoningContractAddress
    )}@${int2hex(selectedSouls.length)}`;
    for (let i = 0; i < selectedSouls.length; i++) {
      // const spl = selectedSouls[i].split('-');
      // const collection = `${spl[0]}-${spl[1]}`;
      const collectionHex = str2hex(selectedSouls[i].collection);
      const nonceHex = int2hex(selectedSouls[i].nonce);
      data += `@${collectionHex}@${nonceHex}@01`;
    }
    data += `@${str2hex('depositSummoningNfts')}`;
    await sendTransaction(
      accountState?.account.address ?? '',
      10_000_000 + 4_000_000 * selectedSouls.length,
      data
    );
  };
  return (
    <>
      {(nftState?.canSummonRegularSummons || nftState?.canSummonDeathSoul) && (
        <>
          <div className='col-lg-12'>
            <small>This summon will cost you</small>
          </div>
          <div className='col-lg-5'></div>
          <div className='col-lg-2 d-flex justify-content-between'>
            <strong>
              {!EVENT_SETTINGS.bendisCall ? (
                <>
                  {totalEgldCost
                    .div(new BigNumber(10).pow(18))
                    .toNumber()
                    .toLocaleString(undefined, toLocaleStringOptions)}{' '}
                  <ElrondLogo style={{ width: '15px', marginLeft: '5px' }} />
                </>
              ) : (
                <>
                  {totalEgldCost
                    .div(new BigNumber(10).pow(18))
                    .toNumber()
                    .toLocaleString(undefined, toLocaleStringOptions)}{' '}
                  <KosonLogo
                    style={{ width: '15px', height: '39px', marginLeft: '5px' }}
                  />
                </>
              )}
            </strong>
            <strong>
              {totalKosonCost
                .div(new BigNumber(10).pow(18))
                .toNumber()
                .toLocaleString(undefined, toLocaleStringOptions)}{' '}
              <KosonLogo
                style={{ width: '15px', height: '39px', marginLeft: '5px' }}
              />
            </strong>
          </div>
          <div className='col-lg-5'></div>
        </>
      )}
      {!nftState?.canSummonRegularSummons && !nftState?.canSummonDeathSoul ? (
        <NotEnoughSouls />
      ) : (
        <SummoningContainer
          handleToggleSelect={handleToggleSelect}
          getUnselectedNfts={getUnselectedNfts}
          isRegularSummoning={
            nftState?.canSummonRegularSummons && !nftState?.canSummonDeathSoul
          }
        />
      )}
      <div className='row'>
        <div className='col-lg-12 mb-3'>
          <small>
            {errorMessage === ''
              ? 'You can now start the summoning process'
              : errorMessage}
          </small>
        </div>
        <div className='col-lg-3'></div>
        {errorMessage.length > 0 ? (
          <div className='col-lg-6'>
            <button className='btn btn-primary btn-lg btn-block disabled'>
              Summon
            </button>
          </div>
        ) : (
          <div className='col-lg-6'>
            <button
              className='btn btn-primary btn-lg btn-block'
              onClick={handleDepositNfts}
            >
              Summon
            </button>
          </div>
        )}
      </div>
    </>
  );

  // when can summon?
  // - any 2 souls with max 5/6 summonings
  // 6 origin souls with 0/1 special summonings

  /*
  // when cannot summon?
  // Common summon:
  1. when length is diff than 2
  2. when either one of the selected souls has the common summons at 6
  // Special summon:

  // general
  1. not enough EGLD
  2. not enough KOSON
  */
};

const MUST_SELECT_AT_LEAST_2_ERR =
  'You must select at least 2 souls to start a new summoning process';
const MUST_SELECT_2_OR_6_ERR =
  'You must select 2 souls to start a new summoning process or 6 different origin souls to start a new summoning process';
const INVALID_SOUL_FOR_REG_SUM_ERR =
  'At least one of the 2 selected souls is not eligible to start a new summoning. Each soul can be used for summoning for at most 6 times';
const INVALID_SOUL_FOR_SPEC_SUM_ERR =
  'At least one of the 6 selected souls is not eligible to start a new death soul summoning process. Each origin soul can only be used for summoning the death soul once';
const INVALID_SOUL_TYPE_FOR_SPEC_SUM_ERR =
  'You have selected a non ORIGIN soul. Only ORIGIN souls can be used for summoning a Death Soul';
const INVALID_ORIGIN_ONLY_SELECTION =
  'You must select 6 different ORIGIN souls. These are: Aether, Earth, Fire, Water, Air, Life';
const INSUFFICIENT_EGLD_ERR =
  'You do not have enough EGLD to perform this summoning';
const INSUFFICIENT_KOSON_ERR =
  'You do not have enough KOSON to perform this summoning';
