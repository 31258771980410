import React from 'react';

const ErrorModal = () => {
  return (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Stake $KOSON</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center'>
              <span>
                Something has occured. Please refresh the page and try again.
              </span>
              <br />
              <span>
                If the issue persists, please create a ticket on Age Of Zalmoxis
                discord.
              </span>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
