import axios from 'axios';
import { backendApiAddress, PLATFORM_ID } from 'config';
import { CreateUtrustOrderPayload, AccountOrderState } from 'types';

const createNewOrder = () =>
  async function createNewOrder({
    payload
  }: {
    payload: CreateUtrustOrderPayload;
  }) {
    try {
      const requestConfig = {
        headers: {
          platformId: PLATFORM_ID
        }
      };
      const { data } = await axios.post(
        `${backendApiAddress}/order/new`,
        payload,
        requestConfig
      );
      return {
        data: data as AccountOrderState,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchOrderState = () =>
  async function getOrderState(address: string) {
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/order/${address}/state/v2`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

const cancelOrder = () =>
  async function cancelOrder(orderId: string) {
    try {
      const { data } = await axios.post(
        `${backendApiAddress}/order/${orderId}/cancel`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

export const postNewUtrustOrder = createNewOrder();
export const getPendingOrderStatus = fetchOrderState();
export const cancelUtrustOrder = cancelOrder();
