import {
  getAllNftListings,
  getLatestNftListings,
  getUserListings,
  getUserOngoingBids
} from 'api/backendRequests';
import React from 'react';
import { MarketplaceListing } from 'types';

const useMarketplaceInfo = ({
  address
}: {
  address: string;
}): IMarketplaceInfoType => {
  const [freshListings, setFreshListings] = React.useState<
    MarketplaceListing[]
  >([]);
  const [allListings, setAllListings] = React.useState<MarketplaceListing[]>(
    []
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [userListings, setUserListings] = React.useState<MarketplaceListing[]>(
    []
  );
  const [userOngoingBids, setUserOngoingBids] = React.useState<
    MarketplaceListing[]
  >([]);

  React.useEffect(() => {
    refreshState().then((res) => {
      setIsLoading(false);
    });
  }, []);

  const refreshState = async () => {
    const latestListings = await getLatestNftListings();
    setFreshListings(latestListings.data);
    const allListings = await getAllNftListings();
    setAllListings(allListings.data);
    if (address && address.startsWith('erd1')) {
      const allUserListings = await getUserListings(address);
      const allOngoingBids = await getUserOngoingBids(address);
      setUserListings(allUserListings.data);
      setUserOngoingBids(allOngoingBids.data);
    }
  };

  const getListingById = (listingId: number) => {
    const filtered = allListings.filter((l) => l.listingId === listingId);
    if (filtered.length === 0) {
      return undefined;
    }
    return filtered[0];
  };

  return {
    freshListings: freshListings,
    allListings: allListings,
    userListings: userListings,
    ongoingBids: userOngoingBids,
    refreshState: refreshState,
    getListingById: getListingById,
    isLoading: isLoading
  };
};

export default useMarketplaceInfo;

export interface IMarketplaceInfoType {
  freshListings: MarketplaceListing[];
  allListings: MarketplaceListing[];
  userListings: MarketplaceListing[];
  ongoingBids: MarketplaceListing[];
  refreshState: () => Promise<void>;
  getListingById: (listingId: number) => MarketplaceListing | undefined;
  isLoading: boolean;
}
