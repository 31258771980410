import React, { useContext, useState } from 'react';
import ComingSoon from 'pages/ComingSoon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChestContentExplorer } from './ChestContentExplorer';
import './style.css';
import { ChestPrizeDistributionTable } from './ChestPrizeDistributionTable';
import { Web3Context } from 'contexts/Web3Context';
import Loading from 'components/Loading';
import { OpeningCard } from './OpeningCard';
import { OGList, ONLY_OG } from 'config';
import { useGetDropChances } from 'hooks/LandChestOpening/useGetDropChances';
export const LandChestOpening = () => {
  const { nftState, accountState } = useContext(Web3Context);
  const { isLoading, dropsRemaining, refreshState } = useGetDropChances();
  const loadingCard = () => {
    return (
      <div className='card'>
        <div className='card-header'>
          <h2>Open Land Chests</h2>
        </div>
        <div className='card-body p-5'>
          <Loading />
        </div>
      </div>
    );
  };

  const refreshData = async () => {
    await nftState?.refreshEntireState();
    await accountState?.refreshState();
    await refreshState();
  };

  return (
    <>
      <div className='row d-flex'>
        <div className='col-lg-7 col-md-12 col-sm-12'>
          {nftState?.accountLandChests === undefined && loadingCard()}
          {nftState?.accountLandChests !== undefined && (
            <OpeningCard
              landChests={nftState.accountLandChests}
              refreshDataTrigger={refreshData}
            />
          )}
        </div>
        <div className='col-lg-5 col-md-12 col-sm-12'>
          <ChestContentExplorer />
        </div>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body text-center'>
              <ChestPrizeDistributionTable
                isLoading={isLoading}
                dropsRemaining={dropsRemaining}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
