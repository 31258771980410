import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NftsSelectorModalContent from 'components/Modals/NftsSelectorModalContent';
import StakeNftsModalContent from 'components/Modals/StakeNftsModalContent';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { AccountNft } from 'types';

const SummoningSlot = ({
  index,
  toggleSelect,
  getNfts,
  isDisabled
}: {
  index: number;
  toggleSelect: (nft: AccountNft) => void;
  getNfts: () => AccountNft[];
  isDisabled: boolean;
}) => {
  const [selectableNfts, setSelectableNfts] = React.useState<AccountNft[]>([]);
  const [selectedNft, setSelectedNft] = React.useState<AccountNft>();
  const handleSelectNft = (nft: AccountNft) => {
    setSelectedNft(nft);
    toggleSelect(nft);
  };
  const handleDeselectNft = () => {
    if (!selectedNft) return;
    toggleSelect(selectedNft);
    setSelectedNft(undefined);
  };

  const noSelectedNftComponent = () => {
    return (
      <div className='card overflow-hidden' style={{ cursor: 'pointer' }}>
        <div
          className={`text-center p-5 ${
            isDisabled ? 'bg-dark' : 'overlay-box'
          }`}
          data-bs-toggle='modal'
          data-bs-target={`#selectSummoningNftsModal${index}`}
          onClick={() => setSelectableNfts(getNfts())}
          data-tip={
            isDisabled
              ? 'You do not possess an origin soul of each element for this summoning'
              : ''
          }
        >
          <h4 className='mt-1'>
            <FontAwesomeIcon icon={faPlusSquare} />
          </h4>
        </div>
        <div
          className='modal fade'
          id={`selectSummoningNftsModal${index}`}
          tabIndex={-1}
          role='dialog'
          aria-hidden='true'
        >
          <NftsSelectorModalContent
            nfts={selectableNfts}
            onSelect={handleSelectNft}
          />
        </div>
        <ReactTooltip />
      </div>
    );
  };

  const selectedNftComponent = () => {
    return (
      <div className='card overflow-hidden' style={{ cursor: 'pointer' }}>
        <div
          className='text-center p-5'
          style={{
            backgroundImage: `url(.${selectedNft?.thumbnailUrl})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'center'
          }}
        >
          <strong
            className='mb-0 text-white'
            style={{
              backgroundColor: 'rgba(0,0,0, 0.6)'
            }}
          >
            {selectedNft?.name}
          </strong>
          <br />
          <small
            style={{
              backgroundColor: 'rgba(0,0,0, 0.6)'
            }}
            onClick={handleDeselectNft}
          >
            Remove
          </small>
        </div>
      </div>
    );
  };
  return selectedNft === undefined
    ? noSelectedNftComponent()
    : selectedNftComponent();
};

export default SummoningSlot;
