import { KOSON_TICKER, summoningContractAddress } from 'config';
import React from 'react';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import BigNumber from 'bignumber.js';
import {
  AddressValue,
  Address,
  U64Value,
  TokenIdentifierValue,
  U8Value
} from '@multiversx/sdk-core/out';

const useSummoningInfo = ({ address }: { address: string }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [kosonSummoningPrices, setKosonSummoningPrices] = React.useState<
    BigNumber[]
  >([]);
  const [egldSummoningPrices, setEgldSummoningPrices] = React.useState<
    BigNumber[]
  >([]);
  const [allSummoningProcesses, setAllSummoningProcesses] = React.useState<
    any[]
  >([]);
  const [pendingSummoningProcesses, setPendingSummoningProcesses] =
    React.useState<any[]>([]);
  const [completedSummoningProcesses, setCompletedSummoningProcesses] =
    React.useState<any[]>([]);

  React.useEffect(() => {
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, []);

  const refreshState = async () => {
    if (!address.startsWith('erd1')) {
      return;
    }
    await loadSummoningCosts();
    const ids = await getUserSummonings(address);
    const summonings = [];
    for (let i = 0; i < ids.length; i++) {
      const summoningInfo = await getSummoningById(ids[i]);
      if (summoningInfo !== undefined) {
        summonings.push(summoningInfo);
      }
    }
    setAllSummoningProcesses(summonings);
  };

  React.useEffect(() => {
    const pending = allSummoningProcesses.filter(
      (p) =>
        p.payment.egld_payment_time.isEqualTo(new BigNumber(0)) ||
        p.payment.koson_payment_time.isEqualTo(new BigNumber(0))
    );
    const completed = allSummoningProcesses.filter(
      (p) =>
        p.payment &&
        p.payment.egld_payment_time.isGreaterThan(new BigNumber(0)) &&
        p.payment.koson_payment_time.isGreaterThan(new BigNumber(0))
    );
    setCompletedSummoningProcesses(completed);
    setPendingSummoningProcesses(pending);
  }, [allSummoningProcesses]);

  const loadSummoningCosts = async () => {
    const kosonPrices = [];
    const egldPrices = [];

    for (let i = 0; i < 6; i++) {
      const kosonPrice = await getSummoningCost(KOSON_TICKER, i);
      const egldPrice = await getSummoningCost('EGLD', i);
      kosonPrices.push(kosonPrice);
      egldPrices.push(egldPrice);
    }
    setKosonSummoningPrices(kosonPrices);
    setEgldSummoningPrices(egldPrices);
  };

  const getUserSummonings = async (address: string) => {
    const addressArg = new AddressValue(new Address(address));
    const contract = await getSmartContract(summoningContractAddress);
    const interaction = contract.methodsExplicit.getUserSummoningProcesses([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value;
    }
    return undefined;
  };

  const getSummoningById = async (id: BigNumber) => {
    const idArg = new U64Value(id);
    const contract = await getSmartContract(summoningContractAddress);
    const interaction = contract.methodsExplicit.getFullSummoningProcess([
      idArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value;
    }
    return [];
  };

  const getSummoningCost = async (currency: string, tier: number) => {
    const currencyArg = new TokenIdentifierValue(currency);
    const tierArg = new U8Value(tier);
    const contract = await getSmartContract(summoningContractAddress);
    const interaction = contract.methodsExplicit.getCurrentPriceTag([
      currencyArg,
      tierArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value;
    }
    return new BigNumber(0);
  };
  return {
    kosonSummoningPrices,
    egldSummoningPrices,
    pendingSummoningProcesses,
    completedSummoningProcesses,
    refreshState,
    isLoading
  };
};

export default useSummoningInfo;

export interface ISummoningInfoType {
  kosonSummoningPrices: BigNumber[];
  egldSummoningPrices: BigNumber[];
  pendingSummoningProcesses: any[];
  completedSummoningProcesses: any[];
  refreshState: () => Promise<any>;
  isLoading: boolean;
}
