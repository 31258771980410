import {
  LandChestContentCollections,
  StakedKosonCollections,
  toLocaleStringOptions
} from 'config';
import React from 'react';
import { AccountNft } from 'types';
import KossonsSymbolPlebea from './../../../assets/images/aoz/Koson_Plebea.svg';
import KossonsSymbolComati from './../../../assets/images/aoz/Koson_Comati.svg';
import KossonsSymbolPileati from './../../../assets/images/aoz/Koson_Pileati.svg';
import KossonsSymbolTarabostes from './../../../assets/images/aoz/Koson_Tarabostes.svg';
import KossonsSymbolStrategos from './../../../assets/images/aoz/Koson_Strategos.svg';
import KossonsSymbolBasileus from './../../../assets/images/aoz/Koson_Basileus.svg';
import './style.css';

const NFTThumbnail = ({
  nft,
  handleSelect,
  handleUpdateQuantity,
  isSelectable,
  customColSize,
  onClick,
  openModalName,
  isVideo
}: {
  nft: AccountNft;
  handleSelect?: () => void;
  handleUpdateQuantity?: (qty: number) => void;
  isSelectable?: boolean;
  customColSize?: string;
  onClick?: (clickedItem: AccountNft) => void;
  openModalName?: string;
  isVideo?: boolean;
}) => {
  const [isSelected, setIsSelected] = React.useState(false);
  const [selectedQuantity, setSelectedQuantity] = React.useState(0);

  const handleClick = () => {
    if (!handleSelect) {
      return;
    }
    if (nft.ETAUntilClaim !== undefined) {
      return;
    }
    if (isSelected) {
      setSelectedQuantity(0);
    }
    setIsSelected(!isSelected);
    handleSelect();
  };

  const handleQuantityChangeSelector = (step: number) => {
    if (!handleUpdateQuantity) {
      return;
    }
    let newQty = selectedQuantity + step;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty > nft.amount) {
      newQty = nft.amount;
      return;
    }
    setSelectedQuantity(newQty);
    handleUpdateQuantity(newQty);
  };

  const getBackgroundImageUrl = () => {
    let url = '';
    if (nft.isStakedKosonBatch) {
      const index = StakedKosonCollections.indexOf(nft.collection);
      switch (index) {
        case 0:
          url = KossonsSymbolPlebea;
          break;
        case 1:
          url = KossonsSymbolComati;
          break;
        case 2:
          url = KossonsSymbolPileati;
          break;
        case 3:
          url = KossonsSymbolTarabostes;
          break;
        case 4:
          url = KossonsSymbolStrategos;
          break;
        case 5:
          url = KossonsSymbolBasileus;
          break;
      }
    } else {
      url = nft.thumbnailUrl;
    }
    // return `url(.${url})`;
    return url;
  };

  return (
    <div className={customColSize ?? 'col-lg-2 col-sm-4 col-md-6'}>
      <div
        className={`card overflow-hidden ${
          isSelected ? ' selected' : isSelectable ? ' selectable' : ''
        }`}
        style={{
          cursor: onClick ? 'pointer' : undefined
        }}
        onClick={() => (onClick ? onClick(nft) : undefined)}
        data-bs-toggle={openModalName ? 'modal' : ''}
        data-bs-target={openModalName}
      >
        <div className='card-header m-auto bg-black' onClick={handleClick}>
          {!isVideo && (
            <img
              src={getBackgroundImageUrl()}
              className='img-responsive w-100 h-100 p-0 m-0 rounded'
            />
          )}
          {isVideo && (
            <div>
              <video
                autoPlay
                loop
                muted
                className='img-responsive p-0 m-0'
                style={{ minWidth: '100px' }}
              >
                <source src={nft.videoResourceUrl} />
              </video>
            </div>
          )}
          <strong
            className='text-white nft-name text-center'
            style={{
              backgroundColor: 'rgba(0,0,0, 0.6)'
            }}
          >
            {nft.name}
          </strong>
          <br />
          {nft.ETAUntilClaim ? (
            nft.canBeClaimed ? (
              <small
                className='text-white nft-status text-center'
                style={{
                  backgroundColor: 'rgba(0,0,0, 0.6)'
                }}
              >
                Claimable now
              </small>
            ) : (
              <small
                className='text-white nft-status text-center'
                style={{
                  backgroundColor: 'rgba(0,0,0, 0.6)'
                }}
              >
                Claimable in {nft.ETAUntilClaim}
              </small>
            )
          ) : (
            <br />
          )}
        </div>
        {nft.isSoul && (
          <div className='card-body bgl-primary' onClick={handleClick}>
            <div className='row text-center'>
              <div className='col-12 mb-3'>
                <div className='bgl-primary rounded p-1'>
                  <h4 className='mb-0'>{nft.regularSummoningCount}/6</h4>
                  <small>Common</small>
                </div>
              </div>
              {nft.specialSummoningCount !== undefined && (
                <div className='col-12'>
                  <div className='bgl-primary rounded p-1'>
                    <h4 className='mb-0'>{nft.specialSummoningCount}/1</h4>
                    <small>Special</small>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {(nft.isLandChest || nft.isTokenNft) && (
          <div className='card-body bgl-primary'>
            <div className='row text-center'>
              <div className='col-lg-12'>
                {!isSelected && (
                  <>
                    <div className='bgl-primary rounded p-1'>
                      <h4 className='mb-0'>{nft.amount}</h4>
                      <small>Quantity</small>
                    </div>
                    {nft.isTokenNft && (
                      <div className='bgl-primary rounded p-1 mt-3'>
                        <h4 className='mb-0'>{nft.rarity}</h4>
                        <small>
                          {nft.collection === LandChestContentCollections[0]
                            ? 'Plot Type'
                            : 'Rarity'}
                        </small>
                      </div>
                    )}
                  </>
                )}
                {isSelected && (
                  <div className='bgl-primary rounded p-1'>
                    <h4 className='mb-0'>
                      <button
                        type='button'
                        className='btn btn-rounded btn-danger btn-xs me-2'
                        onClick={() => handleQuantityChangeSelector(-1)}
                      >
                        -
                      </button>
                      {selectedQuantity}/{nft.amount}
                      <button
                        type='button'
                        className='btn btn-rounded btn-success btn-xs ms-2'
                        onClick={() => handleQuantityChangeSelector(+1)}
                      >
                        +
                      </button>
                    </h4>
                    <small>Selected Quantity</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {nft.isStakedKosonBatch && (
          <div className='card-body bgl-primary' onClick={handleClick}>
            <div className='row text-center'>
              <div className='bgl-primary rounded p-1'>
                <h4 className='mb-0'>
                  {nft.amount.toLocaleString(undefined, toLocaleStringOptions)}
                </h4>
                <small>Amount</small>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTThumbnail;
