import { AddressValue, Address } from '@multiversx/sdk-core/out';
import { getStakedKoson } from 'api/apiRequests';
import {
  nftStakingContractAddress,
  NFT_UNBONDING_TIME_PENALTY,
  LANDCHEST_UNBONDING_TIME_PENALTY,
  LandChestNFTCollection,
  LandPlotsTokenIdentifier,
  landChestStakingContractAddress
} from 'config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import {
  transformStakedNft,
  fillSummoningInfo,
  transformApiNft,
  fillPendingRewards
} from 'contexts/Web3Context/helpers/nftUtils';
import {
  NftStakingDataType,
  StakedInventoryData
} from 'store/slices/accountInfo/stakedInventory';

export const getStakedInventory = async (
  address: string
): Promise<StakedInventoryData> => {
  const soulStaking = await getSoulStakingNftData(address);
  const landChestStaking = await getLandChestStakingNftData(address);
  const landPlotStaking = await getLandPlotStakingNftData(address);

  const stakedKoson = (await getStakedKoson(address)()).data;
  let parsed = stakedKoson.map((nft: any) => transformApiNft(nft));
  parsed = await fillSummoningInfo(parsed);
  parsed = await fillPendingRewards(parsed);

  return {
    stakedSouls: soulStaking,
    stakedLandChests: landChestStaking,
    stakedLandPlotTokens: landPlotStaking,
    stakedKoson: parsed
  };
};

const getSoulStakingNftData = async (
  address: string
): Promise<NftStakingDataType> => {
  const stakedSouls = await getUserStakedNfts(address);
  const unbondingStakedSouls = await getUserUnbondingNfts(address);

  return {
    stakedNfts: stakedSouls,
    unbondingNfts: unbondingStakedSouls,
    canClaimUnbondedNfts:
      unbondingStakedSouls.filter((nft) => nft.canBeClaimed).length > 0
  };
};

const getLandChestStakingNftData = async (
  address: string
): Promise<NftStakingDataType> => {
  const stakedChests = await getUserStakedChests(address);
  const unbondingChests = await getUserUnbondingChests(address);

  return {
    stakedNfts: stakedChests,
    unbondingNfts: unbondingChests,
    canClaimUnbondedNfts:
      unbondingChests.filter((nft) => nft.canBeClaimed).length > 0
  };
};

const getLandPlotStakingNftData = async (
  address: string
): Promise<NftStakingDataType> => {
  const stakedPlots = await getUserStakedPlots(address);
  const unbondingPlots = await getUserUnbondingPlots(address);

  return {
    stakedNfts: stakedPlots,
    unbondingNfts: unbondingPlots,
    canClaimUnbondedNfts:
      unbondingPlots.filter((nft) => nft.canBeClaimed).length > 0
  };
};

const getUserStakedNfts = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(nftStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserStakedNfts([addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const parsedStakedNfts = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < parsedStakedNfts.length; i++) {
      const parsedNft = transformStakedNft(
        parsedStakedNfts[i].token_id,
        parsedStakedNfts[i].nonce.toNumber(),
        parsedStakedNfts[i].amount.toNumber()
      );
      items.push(parsedNft);
    }
    return fillSummoningInfo(items);
  }
  return [];
};

const getUserUnbondingNfts = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(nftStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserPendingUnstake([
    addressArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const unstakingNftsBatches = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < unstakingNftsBatches.length; i++) {
      const batch = unstakingNftsBatches[i];
      const unstakeTriggerTimestamp = batch.field0.toNumber();
      const expectedClaimTime =
        unstakeTriggerTimestamp + NFT_UNBONDING_TIME_PENALTY;
      for (let j = 0; j < batch.field1.length; j++) {
        const batchItem = batch.field1[j];
        const parsedNft = transformStakedNft(
          batchItem.token_id,
          batchItem.nonce.toNumber(),
          batchItem.amount.toNumber(),
          expectedClaimTime
        );
        items.push(parsedNft);
      }
    }
    return fillSummoningInfo(items);
  }
  return [];
};

const getUserStakedChests = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(landChestStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserStakedChests([
    addressArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const stakedNfts = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < stakedNfts.length; i++) {
      const parsedNft = transformStakedNft(
        LandChestNFTCollection,
        stakedNfts[i].nonce.toNumber(),
        stakedNfts[i].reward.toNumber()
      );
      items.push(parsedNft);
    }
    return items;
  }
  return [];
};

const getUserStakedPlots = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(landChestStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserStakedPlots([addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const stakedNfts = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < stakedNfts.length; i++) {
      const parsedNft = transformStakedNft(
        LandPlotsTokenIdentifier,
        stakedNfts[i].nonce.toNumber(),
        stakedNfts[i].reward.toNumber()
      );
      items.push(parsedNft);
    }
    return items;
  }
  return [];
};

const getUserUnbondingChests = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(landChestStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserPendingUnstake([
    addressArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const stakedNfts = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < stakedNfts.length; i++) {
      const unstakeTime = stakedNfts[i].field0.toNumber();
      const expectedClaimTime = unstakeTime + LANDCHEST_UNBONDING_TIME_PENALTY;
      const parsedNft = transformStakedNft(
        LandChestNFTCollection,
        stakedNfts[i].field1.nonce.toNumber(),
        stakedNfts[i].field1.reward.toNumber(),
        expectedClaimTime
      );
      items.push(parsedNft);
    }
    return items;
  }
  return [];
};

const getUserUnbondingPlots = async (address: string) => {
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContract(landChestStakingContractAddress);
  const interaction = contract.methodsExplicit.getUserPlotPendingUnstake([
    addressArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const items = [];
    const stakedNfts = parsedResponse.firstValue?.valueOf();
    for (let i = 0; i < stakedNfts.length; i++) {
      const unstakeTime = stakedNfts[i].field0.toNumber();
      const expectedClaimTime = unstakeTime + LANDCHEST_UNBONDING_TIME_PENALTY;
      const parsedNft = transformStakedNft(
        LandPlotsTokenIdentifier,
        stakedNfts[i].field1.nonce.toNumber(),
        stakedNfts[i].field1.reward.toNumber(),
        expectedClaimTime
      );
      items.push(parsedNft);
    }
    return items;
  }
  return [];
};
