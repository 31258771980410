import { toLocaleStringOptions } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { ReactComponent as ElrondLogo } from './../../../../assets/images/aoz/egld-symbol.svg';
import { ReactComponent as KosonLogo } from './../../../../assets/images/aoz/koson-symbol.svg';

const BalanceCard = ({
  amount,
  isEgld,
  text,
  secondaryText
}: {
  amount: number;
  isEgld: boolean;
  text: string;
  secondaryText?: string;
}) => {
  const context = React.useContext(Web3Context);
  const [exchangeRate, setExchangeRate] = React.useState(0);

  React.useEffect(() => {
    setExchangeRate(
      (isEgld
        ? context.priceState?.egldPrice
        : context.priceState?.kosonPrice) || 0
    );
  }, [context.priceState?.egldPrice, context.priceState?.kosonPrice]);
  return (
    <div className='bgl-primary rounded p-3'>
      <h4 className='mb-0 d-flex align-items-center justify-content-center'>
        {amount.toLocaleString(undefined, toLocaleStringOptions)}{' '}
        {isEgld ? (
          <ElrondLogo style={{ width: '15px', marginLeft: '5px' }} />
        ) : (
          <KosonLogo
            style={{ width: '15px', height: '39px', marginLeft: '5px' }}
          />
        )}
      </h4>
      {exchangeRate === 0 && <small>{text}</small>}
      {exchangeRate !== 0 && (
        <small>
          {text} ~
          {(exchangeRate * amount).toLocaleString(
            undefined,
            toLocaleStringOptions
          )}{' '}
          USD
        </small>
      )}
    </div>
  );
};

export default BalanceCard;
