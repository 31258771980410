import axios from 'axios';
import { backendApiAddress } from 'config';

const fetchKYCStatus = () =>
  async function getKYCStatus(address: string) {
    try {
      const { data } = await axios.get(`${backendApiAddress}/kyc/${address}`);
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

export const getKYCStatus = fetchKYCStatus();
