import { RootState } from 'store';
import { AccountNft } from 'types';
import { NftStakingDataType } from '../stakedInventory';
import { StoreStatusType, mergeStatuses } from 'helpers';

export interface NftStakingInfo {
  stake: NftStakingDataType | undefined;
  availableNfts: AccountNft[];
  pendingRewards: number;
  status: StoreStatusType;
}

export const getSoulNftStakingInfo = (state: RootState): NftStakingInfo => {
  const nfts = state.accountInfo.stakedInventory.data?.stakedSouls;
  const pendingRewards =
    state.accountInfo.pendingRewards.data?.soulStaking ?? 0;
  const availableNfts = state.accountInfo.inventory.data.souls;
  const storeStatuses = [
    state.accountInfo.inventory.status,
    state.accountInfo.pendingRewards.status,
    state.accountInfo.stakedInventory.status
  ];

  return {
    stake: nfts,
    status: mergeStatuses(storeStatuses),
    pendingRewards,
    availableNfts
  };
};

export const getLandChestStakingInfo = (state: RootState): NftStakingInfo => {
  const nfts = state.accountInfo.stakedInventory.data?.stakedLandChests;
  const pendingRewards =
    state.accountInfo.pendingRewards.data?.landChestStaking ?? 0;
  const availableNfts = state.accountInfo.inventory.data.landChests;
  const storeStatuses = [
    state.accountInfo.inventory.status,
    state.accountInfo.pendingRewards.status,
    state.accountInfo.stakedInventory.status
  ];

  return {
    stake: nfts,
    status: mergeStatuses(storeStatuses),
    pendingRewards,
    availableNfts
  };
};

export const getLandPlotStakingInfo = (state: RootState): NftStakingInfo => {
  const nfts = state.accountInfo.stakedInventory.data?.stakedLandPlotTokens;
  const pendingRewards =
    state.accountInfo.pendingRewards.data?.landChestStaking ?? 0;
  const availableNfts = state.accountInfo.inventory.data.landPlots;
  const storeStatuses = [
    state.accountInfo.inventory.status,
    state.accountInfo.pendingRewards.status,
    state.accountInfo.stakedInventory.status
  ];

  return {
    stake: nfts,
    status: mergeStatuses(storeStatuses),
    pendingRewards,
    availableNfts
  };
};
