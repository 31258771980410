import { Transaction } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  BuyoutPayload,
  useGetBuyoutTransaction,
  useGetOwnerTransaction
} from 'api/v2/transactions/marketplace';
import { Web3Context } from 'contexts/Web3Context';
import { AvailableListingInteractions } from 'helpers';
import React, { useContext, useState } from 'react';
import BigNumber from 'bignumber.js';

export const MarketplaceInteractionButton = ({
  isBuyout,
  buyoutPayload,
  buySettings
}: {
  isBuyout: boolean;
  buyoutPayload: BuyoutPayload;
  buySettings: AvailableListingInteractions;
}) => {
  const { sendTransactionV2 } = useContext(Web3Context);

  const getMarketplaceBuyTransaction = useGetBuyoutTransaction();
  const getOwnerTransaction = useGetOwnerTransaction();

  const handleBuyTransaction = async (action: 'buy' | 'bid') => {
    if (action === 'buy' && !buySettings.canBuy) {
      return;
    }
    if (action === 'bid' && !buySettings.canBid) {
      return;
    }

    const transaction = getMarketplaceBuyTransaction(buyoutPayload);
    await sendTransactionV2(transaction);
  };

  const handleCancelTransaction = async () => {
    if (!buySettings.canCancel) {
      return;
    }
    const transaction = getOwnerTransaction(
      'withdraw',
      buyoutPayload.listingId
    );
    await sendTransactionV2(transaction);
  };

  const handleClaimTransaction = async () => {
    if (!buySettings.canClaim) {
      return;
    }
    const transaction = getOwnerTransaction(
      'endAuction',
      buyoutPayload.listingId
    );
    await sendTransactionV2(transaction);
  };

  const [showBuyButton] = useState(!buySettings.isOwner && isBuyout);
  const [showBidButton] = useState(!buySettings.isOwner && !isBuyout);
  const [showCancelButton] = useState(
    buySettings.isOwner && buySettings.canCancel
  );
  const [showWithdrawButton] = useState(
    buySettings.isOwner && buySettings.canClaim
  );

  return (
    <>
      {showBuyButton && (
        <button
          className='btn btn-primary w-50 p-2 mt-2'
          disabled={!buySettings.canBuy}
          onClick={() => handleBuyTransaction('buy')}
        >
          Buy
        </button>
      )}
      {showBidButton && (
        <button
          className='btn btn-primary w-50 p-2 mt-2'
          disabled={!buySettings.canBid}
          onClick={() => handleBuyTransaction('bid')}
        >
          Bid (TODO)
        </button>
      )}
      {showCancelButton && (
        <button
          className='btn btn-primary w-50 p-2 mt-2'
          onClick={handleCancelTransaction}
        >
          Cancel
        </button>
      )}
      {showWithdrawButton && (
        <button
          className='btn btn-primary w-50 p-2 mt-2'
          onClick={handleClaimTransaction}
        >
          Claim
        </button>
      )}
    </>
  );
};
