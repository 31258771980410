import { ChainType } from '.';

const ChainTypes = {
  MVX: {
    name: 'MultiversX',
    logo: '/images/mvx/x-mint.png'
  },
  IMX: {
    name: 'Immutable X',
    logo: '/images/mvx/immutable-symbol-WHT-RGB.png'
  },
  MNT: {
    name: 'Mantle',
    logo: '/images/mvx/mantle_logo.png'
  }
};

export const SelectChainCard = ({
  chainType,
  onSelect
}: {
  chainType: ChainType;
  onSelect: (chainType: ChainType) => void;
}) => {
  return chainType === undefined ? null : (
    <div
      className='card card-body select-chain-card'
      onClick={() => onSelect(chainType)}
    >
      <img src={ChainTypes[chainType].logo} />
      <span>{ChainTypes[chainType].name}</span>
    </div>
  );
};
