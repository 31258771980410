import SoulFilterModalContent from 'components/Modals/SoulFilterModalContent';
import StakeNftsModalContent from 'components/Modals/StakeNftsModalContent';
import NFTThumbnail from 'components/NFT/NftThumbnail';
import { StakedAssetThumbnail } from 'components/NFT/StakedAssetThumbnail';
import { ImageAssetThumbnailCardHeader } from 'components/NFT/ThumbnailCardHeader';
import { SoulsNFTCollections } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { AccountNft } from 'types';

const NftStaking = ({
  headerText,
  nothingStakedMessage,
  stakeMoreNftsHeaderMessage,
  showFilter,
  stakeButtonDisabled,
  availableNfts,
  stakedNfts,
  canClaimUnbonded,
  handleStakeNfts,
  handleUnstake,
  handleUnstakeAllCustom,
  handleClaimUnbonded
}: {
  headerText?: string;
  nothingStakedMessage?: string;
  stakeMoreNftsHeaderMessage?: string;
  showFilter?: boolean;
  stakeButtonDisabled?: boolean;
  availableNfts: AccountNft[];
  stakedNfts: AccountNft[];
  canClaimUnbonded: boolean;
  handleStakeNfts: (
    nfts: AccountNft[],
    quantities: { [identifier: string]: number }
  ) => Promise<void>;
  handleUnstake: (
    nfts: AccountNft[],
    quantities: { [identifier: string]: number }
  ) => Promise<void>;
  handleUnstakeAllCustom?: () => Promise<void>;
  handleClaimUnbonded: () => Promise<void>;
}) => {
  const DEFAULT_HEADER_TEXT = 'You have no NFTs staked';
  const context = React.useContext(Web3Context);
  const [selectedNfts, setSelectedNfts] = React.useState<AccountNft[]>([]);
  const [selectedNftsQuantities, setSelectedNftsQuantities] = React.useState<{
    [identifier: string]: number;
  }>({});
  const [isAnySelected, setIsAnySelected] = React.useState(false);
  const [collectionsToDisplay, setCollectionsToDisplay] =
    React.useState(SoulsNFTCollections);

  const handleSelect = (nft: AccountNft) => {
    const selectedNftIndex = selectedNfts.indexOf(nft);
    const co = selectedNfts;
    if (selectedNftIndex < 0) {
      co.push(nft);
    } else {
      co.splice(selectedNftIndex);
    }
    setIsAnySelected(co.length > 0);
    setSelectedNfts(co);
  };

  const handleUpdateQuantity = (nft: AccountNft, qty: number) => {
    if (!nft.isLandChest && !nft.isTokenNft) {
      return;
    }
    const co = selectedNftsQuantities;
    co[nft.identifier] = qty;
    setSelectedNftsQuantities(co);
  };

  const handleUnstakeAll = async () => {
    if (handleUnstakeAllCustom === undefined) {
      const identifiers =
        context.nftState?.accountNfts
          .filter((nft) => nft.isLandChest)
          .map((nft) => nft.identifier) || [];
      await handleUnstake(identifiers, selectedNftsQuantities);
    } else {
      await handleUnstakeAllCustom();
    }
  };

  const handleUnstakeSelected = async () => {
    if (selectedNfts.length === 0) {
      window.alert('You must select at least one NFT to unstake');
      return;
    }
    await handleUnstake(selectedNfts, selectedNftsQuantities);
  };

  const updateFilters = (collections: string[]) => {
    setCollectionsToDisplay(collections);
  };

  return stakedNfts.length === 0 ? (
    <div
      className='card text-center'
      id='card-title-3'
      style={{ maxHeight: '600px' }}
    >
      <div
        className={`card-header ${showFilter ? ' text-between' : 'text-left'}`}
      >
        <h5 className='card-title'>{headerText ?? 'Staked NFTs'}</h5>
        {showFilter && (
          <div>
            <button
              className='btn btn-outline-primary text-white disabled'
              style={{ minWidth: '175px' }}
            >
              Filter
            </button>
          </div>
        )}
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          <h3 style={{ marginTop: '4.9%', marginBottom: '4.9%' }}>
            {nothingStakedMessage ?? DEFAULT_HEADER_TEXT}
          </h3>
        </div>
      </div>
      <div className='card-footer text-center'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            {!stakeButtonDisabled && (
              <>
                <button
                  className='btn btn-outline-primary text-white'
                  style={{ minWidth: '175px' }}
                  data-bs-toggle='modal'
                  data-bs-target='#stakeNftsModal2'
                >
                  Stake now
                </button>
                <div
                  className='modal fade'
                  id='stakeNftsModal2'
                  tabIndex={-1}
                  role='dialog'
                  aria-hidden='true'
                >
                  <StakeNftsModalContent
                    nfts={availableNfts}
                    handleStakeNfts={handleStakeNfts}
                    customHeaderText={
                      stakeMoreNftsHeaderMessage ?? 'Stake NFTs'
                    }
                  />
                </div>
              </>
            )}
            {stakeButtonDisabled && (
              <button
                className='btn btn-outline-primary text-white disabled'
                style={{ minWidth: '175px' }}
              >
                Stake now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className='card text-center'
      id='card-title-3'
      style={{ maxHeight: '600px' }}
    >
      <div
        className={`card-header ${showFilter ? ' text-between' : 'text-left'}`}
      >
        <h5 className='card-title'>{headerText ?? DEFAULT_HEADER_TEXT}</h5>
        {showFilter && (
          <div>
            <button
              className='btn btn-outline-primary text-white'
              style={{ minWidth: '175px' }}
              data-bs-toggle='modal'
              data-bs-target='#filtersModal'
            >
              Filter
            </button>
            <div className='modal fade' id='filtersModal'>
              <SoulFilterModalContent onApply={updateFilters} />
            </div>
          </div>
        )}
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          {stakedNfts
            .filter(
              (nft) =>
                collectionsToDisplay.includes(nft.collection) || !showFilter
            )
            .map((nft, i) => {
              return (
                <StakedAssetThumbnail
                  nft={nft}
                  key={`nft-staking-key-${i}`}
                  handleSelect={() =>
                    nft.ETAUntilClaim === undefined && handleSelect(nft)
                  }
                  handleUpdateQuantity={(qty) => handleUpdateQuantity(nft, qty)}
                />
              );
            })}
        </div>
      </div>
      <div className='card-footer'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12 text-center mb-2'>
            {isAnySelected && (
              <button
                className='btn btn-outline-primary text-white'
                style={{ minWidth: '175px' }}
                onClick={handleUnstakeSelected}
              >
                Unstake selected
              </button>
            )}
            {!isAnySelected && (
              <button
                className='btn btn-outline-primary text-white disabled'
                style={{ minWidth: '175px' }}
              >
                Unstake selected
              </button>
            )}
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 text-center mb-2'>
            <button
              className='btn btn-outline-primary text-white'
              style={{ minWidth: '175px' }}
              onClick={handleUnstakeAll}
            >
              Unstake all
            </button>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 text-center mb-2'>
            {canClaimUnbonded ? (
              <button
                className='btn btn-outline-primary text-white'
                style={{ minWidth: '175px' }}
                onClick={handleClaimUnbonded}
              >
                Claim unbonded NFTs
              </button>
            ) : (
              <button
                className='btn btn-outline-primary text-white disabled'
                style={{ minWidth: '175px' }}
              >
                Claim unbonded NFTs
              </button>
            )}
          </div>
          {!stakeButtonDisabled && (
            <div className='col-lg-3 col-md-6 col-sm-12 text-center mb-2'>
              <button
                className='btn btn-outline-primary text-white'
                style={{ minWidth: '175px' }}
                data-bs-toggle='modal'
                data-bs-target='#stakeNftsModal'
              >
                Stake more
              </button>
              <div
                className='modal fade'
                id='stakeNftsModal'
                tabIndex={-1}
                role='dialog'
                aria-hidden='true'
              >
                <StakeNftsModalContent
                  nfts={availableNfts}
                  handleStakeNfts={handleStakeNfts}
                  customHeaderText={stakeMoreNftsHeaderMessage ?? 'Stake NFTs'}
                />
              </div>
            </div>
          )}
          {stakeButtonDisabled && (
            <div className='col-lg-3 col-md-6 col-sm-12 text-center mb-2'>
              <button
                className='btn btn-outline-primary text-white disabled'
                style={{ minWidth: '175px' }}
              >
                Stake more
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NftStaking;
