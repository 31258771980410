import { dAppName } from 'config';
import Earn from 'pages/Earn';
import Inventory from 'pages/Inventory';
import Marketplace from 'pages/Marketplace';
import Summoning from 'pages/Summoning';
import withPageTitle from './components/PageTitle';
import Overview from './pages/Dashboard/Overview';
import Home from './pages/Home';
import Vesting from 'pages/Dashboard/Vesting';
import KosonStaking from 'pages/KosonStaking';
import SoulNftStaking from 'pages/SoulNftStaking';
import LandChestStaking from 'pages/LandChestStaking';
import {
  faHouseChimney,
  faMoneyBillTrendUp,
  faShieldCheck,
  faShopLock,
  faTableColumns,
  faWarehouseFull,
  faDna,
  faScrewdriverWrench,
  faShop,
  faMagnifyingGlassChart,
  faMap,
  faCartShopping,
  faBlockQuestion
} from '@fortawesome/pro-regular-svg-icons';
import { faTreasureChest } from '@fortawesome/pro-light-svg-icons';
import Verify from 'pages/Verify';
import Admin from 'pages/Admin';
import MarketplaceListingPage from 'pages/MarketplaceListing';
import LandChestSale from 'pages/LandChestSale';
import {
  faArrowRightArrowLeft,
  faVault
} from '@fortawesome/free-solid-svg-icons';
import Listings from 'pages/Dashboard/Listings';
import P2POffers from 'pages/Dashboard/P2POffers';
import ComingSoon from 'pages/ComingSoon';
import { LandChestOpening } from 'pages/LandChestOpening';
import { InteractiveMap } from 'pages/InteractiveMap';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  earn: '/earn',
  inventory: '/inventory',
  summoning: '/summoning',
  marketplace: '/marketplace',
  ownMarketplaceListings: '/dashboard-listings',
  ownMarketplaceListings2: '/my-listings',
  p2pOffers: '/p2p-offers',
  marketplaceListing: '/listings/:listingId',
  vesting: '/vesting',
  kosonStaking: '/koson-staking',
  nftStaking: '/nft-staking',
  landchestStaking: '/land-staking',
  verify: '/verify',
  admin: '/admin',
  landChestSale: '/land-sale',
  interactiveMap: '/map',
  landChestOpening: '/land-opening'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home,
    displayInNavbar: true,
    icon: faHouseChimney
  },
  {
    path: routeNames.marketplace,
    title: 'Marketplace',
    component: Marketplace,
    displayInNavbar: true,
    icon: faShop,
    subMenuItems: [
      {
        path: routeNames.marketplace,
        title: 'All Listings',
        component: Marketplace,
        displayInNavbar: true,
        icon: faShop
      },
      {
        path: routeNames.ownMarketplaceListings2,
        title: 'My Listings',
        component: Listings,
        displayInNavbar: true,
        icon: faShopLock,
        authenticatedRoute: true
      }
    ]
  },
  {
    path: routeNames.interactiveMap,
    title: 'Map',
    component: InteractiveMap,
    displayInNavbar: true,
    icon: faMap
  },
  {
    path: routeNames.landChestOpening,
    title: 'Land Chests',
    component: LandChestOpening,
    displayInNavbar: true,
    icon: faTreasureChest,
    subMenuItems: [
      {
        path: routeNames.landChestOpening,
        title: 'Open',
        component: LandChestOpening,
        displayInNavbar: true,
        icon: faBlockQuestion
      },
      {
        path: routeNames.landChestSale,
        title: 'Purchase',
        component: LandChestSale,
        displayInNavbar: true,
        icon: faCartShopping
      },
      {
        path: routeNames.landchestStaking,
        title: 'Stake',
        component: LandChestStaking,
        displayInNavbar: true,
        icon: faMoneyBillTrendUp,
        authenticatedRoute: true
      }
    ]
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Overview,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faTableColumns,
    subMenuItems: [
      {
        path: routeNames.dashboard,
        title: 'Overview',
        component: Overview,
        displayInNavbar: true,
        icon: faMagnifyingGlassChart,
        authenticatedRoute: true
      },
      {
        path: routeNames.ownMarketplaceListings,
        title: 'Listings',
        component: Listings,
        displayInNavbar: true,
        icon: faShopLock,
        authenticatedRoute: true
      },
      // {
      //   path: routeNames.p2pOffers,
      //   title: 'P2P offers',
      //   component: P2POffers,
      //   displayInNavbar: true,
      //   icon: faArrowRightArrowLeft,
      //   authenticatedRoute: true
      // },
      {
        path: routeNames.vesting,
        title: 'Vesting',
        component: Vesting,
        displayInNavbar: true,
        icon: faVault,
        authenticatedRoute: true
      }
    ]
  },
  {
    path: routeNames.earn,
    title: 'Earn',
    component: Earn,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faMoneyBillTrendUp
  },
  {
    path: routeNames.inventory,
    title: 'Inventory',
    component: Inventory,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faWarehouseFull
  },
  {
    path: routeNames.summoning,
    title: 'Summoning',
    component: Summoning,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faDna
  },
  {
    path: routeNames.verify,
    title: 'Verify assets',
    component: Verify,
    authenticatedRoute: false,
    displayInNavbar: true,
    icon: faShieldCheck
  },
  {
    path: routeNames.vesting,
    title: 'Vesting',
    component: Vesting,
    authenticatedRoute: true
  },
  {
    path: routeNames.kosonStaking,
    title: 'Koson Staking',
    component: KosonStaking,
    authenticatedRoute: true
  },
  {
    path: routeNames.nftStaking,
    title: 'Soul NFT Staking',
    component: SoulNftStaking,
    authenticatedRoute: true
  },
  {
    path: routeNames.landchestStaking,
    title: 'Land Chest Staking',
    component: LandChestStaking,
    authenticatedRoute: true
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: Admin,
    authenticatedRoute: true,
    displayInNavbar: true,
    icon: faScrewdriverWrench
  },
  {
    path: routeNames.marketplaceListing,
    title: 'Marketplace Listing',
    component: MarketplaceListingPage
  }
];

const mappedRoutes = routes
  .concat(
    routes
      .filter((r) => r.subMenuItems !== undefined && r.subMenuItems.length > 0)
      .map((r) => {
        return r.subMenuItems;
      })
      .reduce((prev, crt) => prev.concat(crt), [])
  )
  .map((route) => {
    const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

    const requiresAuth = Boolean(route.authenticatedRoute);
    const wrappedComponent = withPageTitle(title, route.component);

    return {
      path: route.path,
      component: wrappedComponent,
      authenticatedRoute: requiresAuth
    };
  });

export const NAVBAR_MENU_ITEMS: NavbarMenuItem[] = routes
  .filter((r) => r.displayInNavbar)
  .map((r) => {
    return {
      route: r.path,
      display: r.title,
      isProtectedRoute: r.authenticatedRoute,
      icon: r.icon,
      subMenuItems:
        r.subMenuItems?.length > 0
          ? r.subMenuItems.map((item: any): NavbarMenuItem => {
              return {
                route: item.path,
                display: item.title,
                isProtectedRoute: item.authenticatedRoute,
                icon: item.icon,
                subMenuItems: []
              };
            })
          : undefined
    };
  });

export default mappedRoutes;

export interface NavbarMenuItem {
  route: string;
  display: string;
  isProtectedRoute: boolean;
  icon: any;
  subMenuItems: NavbarMenuItem[];
}
