import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { CHEST_CONTENT } from './Constants';

export const ChestContentExplorer = () => {
  const [selectedChestNonce, setSelectedChestNonce] = useState(1);

  const toggleChest = (by: number) => {
    let newNumber = selectedChestNonce + by;
    if (newNumber > 4) {
      newNumber = 1;
    }
    if (newNumber < 1) {
      newNumber = 4;
    }
    setSelectedChestNonce(newNumber);
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h2>Chests content</h2>
      </div>
      <div className='card-body d-flex align-items-center'>
        <div>
          <div className='text-center'>
            <h3 className='mb-2'>
              <strong>{CHEST_CONTENT[selectedChestNonce].title}</strong>
            </h3>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <button className='btn me-2' onClick={() => toggleChest(-1)}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className='btn-carousel'
                role='button'
              />
            </button>
            <div>
              <img
                src={CHEST_CONTENT[selectedChestNonce].image}
                style={{
                  width: 'auto',
                  maxWidth: '100%'
                }}
              />
              <ul className='mt-3'>
                {CHEST_CONTENT[selectedChestNonce].contentComponent}
              </ul>
            </div>
            <button className='btn ms-2' onClick={() => toggleChest(1)}>
              <FontAwesomeIcon
                icon={faChevronRight}
                className='btn-carousel'
                role='button'
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
