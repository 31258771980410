import { U64Value, BigUIntValue } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { getSummoningCounts } from 'api/backendRequests';
import {
  kosonStakingPools,
  LandChestContentCollections,
  LandChestNFTCollection,
  LandPlotsTokenIdentifier,
  OriginNFTCollections,
  SoulsNFTCollections,
  StakedKosonCollections,
  stakePoolUnlockPenaltiesDays
} from 'config';
import { AccountNft, MarketplaceListing } from 'types';
import {
  getCurrentStakeDay,
  getTimeUntil,
  getTimeUntilShort,
  int2hex
} from './generalUtils';
import { getSmartContract, Provider, Parser } from './getScObj';
import { REWARD_IMG_MAPPING } from './constants';

export const transformStakedNft = (
  collection: string,
  nonce: number,
  amount: number,
  claimDate?: number
) => {
  let nonceHex = nonce.toString(16);
  if (nonceHex.length % 2 === 1) {
    nonceHex = '0' + nonceHex;
  }
  const identifier = `${collection}-${nonceHex}`;
  const item: AccountNft = {
    collection: collection,
    identifier: identifier,
    nonce: nonce,
    amount: amount,
    unparsedAmount: amount.toString(),
    isOrigin: OriginNFTCollections.includes(collection),
    isSoul: SoulsNFTCollections.includes(collection),
    isNotMigratedOrigin: OriginNFTCollections[0] === collection,
    isLandChest: LandChestNFTCollection === collection,
    isStakedKosonBatch: StakedKosonCollections.includes(collection),
    name: getNftName(collection, nonce),
    thumbnailUrl: getThumbnailUrl(collection, nonce),
    fullResourceUrl: getNftUrl(collection, nonce),
    description: '',
    ETAUntilClaim: claimDate ? getTimeUntil(claimDate) : undefined,
    canBeClaimed: claimDate
      ? claimDate * 1000 < new Date().getTime()
      : undefined,
    isTokenNft: LandChestContentCollections.includes(collection),
    videoResourceUrl: getVideoUrl({ collection, nonce })
  };
  return item;
};

export const transformApiNft = (nft: any) => {
  const isStakedKosonBatch = StakedKosonCollections.includes(nft.collection);
  const isTokenNft = LandChestContentCollections.includes(nft.collection);
  const item: AccountNft = {
    collection: nft.collection,
    identifier: nft.identifier,
    nonce: nft.nonce,
    amount: isStakedKosonBatch
      ? new BigNumber(nft.balance)
          .dividedBy(new BigNumber(10).pow(18))
          .toNumber()
      : parseInt(nft.balance),
    unparsedAmount: nft.balance,
    isOrigin: OriginNFTCollections.includes(nft.collection),
    isSoul: SoulsNFTCollections.includes(nft.collection),
    isNotMigratedOrigin: OriginNFTCollections[0] === nft.collection,
    isLandChest: LandChestNFTCollection === nft.collection,
    isStakedKosonBatch: isStakedKosonBatch,
    name: nft.name,
    thumbnailUrl: getThumbnailUrl(nft.collection, nft.nonce),
    fullResourceUrl: getNftUrl(nft.collection, nft.nonce),
    videoResourceUrl: getVideoUrl(nft),
    description: '',
    stakeDay: isStakedKosonBatch
      ? parseInt(Buffer.from(nft.attributes, 'base64').toString('hex'), 16)
      : undefined,
    isTokenNft: isTokenNft,
    rarity: isTokenNft ? nft.name.split(' ')[0] : undefined
  };
  if (isStakedKosonBatch) {
    const crtStakeDay = getCurrentStakeDay();
    const penalty =
      stakePoolUnlockPenaltiesDays[
        StakedKosonCollections.indexOf(nft.collection)
      ];
    item.daysUntilClaimDay = (item.stakeDay || 0) + penalty - crtStakeDay;
  }

  return item;
};

export const transformMarketplaceListing = (nft: any) => {
  // const listingDetails: NFTListingDetails = {
  //   listingId: nft.listingId,
  //   tokenIdentifier: nft.tokenIdentifier,
  //   nonce: nft.nonce,
  //   nftName: nameSplit[0],
  //   royalties: nft.royalties,
  //   minBid: nft.minBid,
  //   maxBid: nft.maxBid,
  //   currentBid: nft.currentBid,
  //   originalOwner: nft.ownerAddress,
  //   winnerAddress: nft.winnerAddress,
  //   startTime: new Date(Date.parse(`${nft.startTime}Z`)),
  //   endTime: new Date(Date.parse(`${nft.endTime}Z`)),
  //   listingType: nft.listingType == 0 ? 'buyout' : 'auction',
  //   soulType: getSoulType(nft.tokenIdentifier),
  //   imageUrl: getNftUrl(nft.tokenIdentifier),
  //   thumbnailImageUrl: getThumbnailUrl(nft.tokenIdentifier),
  //   description: nft.description,
  //   sixthSummons: `${nft.sixthSummons}`,
  //   seventhSummon: `${nft.seventhSummon}`,
  //   specialAbility: getSpecialAbility(nft.tokenIdentifier)
  // };
  const endTime = new Date(Date.parse(`${nft.endTime}Z`)).getTime() / 1000;
  const regularSummoningCount = parseInt(nft.sixthSummons.split('/')[0]);
  const specialSummoningCount =
    nft.seventhSummon === 'N/A' || nft.seventhSummon === null
      ? undefined
      : parseInt(nft.seventhSummon.split('/')[0]);
  const isBuyout = nft.denominatedMinBid === nft.denominatedMaxBid;
  const minBid = isBuyout
    ? new BigNumber(nft.denominatedMinBid)
    : nft.currentBid === 0
    ? new BigNumber(nft.denominatedMinBid)
    : new BigNumber(nft.denominatedCurrentBid);
  const item: MarketplaceListing = {
    listingId: nft.listingId,
    collection: nft.tokenIdentifier,
    identifier: `${nft.tokenIdentifier}-${int2hex(nft.nonce)}`,
    nonce: nft.nonce,
    isOrigin: OriginNFTCollections.includes(nft.tokenIdentifier),
    name: nft.nftName,
    thumbnailUrl: getThumbnailUrl(nft.tokenIdentifier, nft.nonce),
    fullResourceUrl: getNftUrl(nft.tokenIdentifier, nft.nonce),
    isBuyout: isBuyout,
    minBid: minBid,
    maxBid: new BigNumber(nft.denominatedMaxBid),
    originalOwnerAddress: nft.ownerAddress,
    currentWinnerAddress:
      nft.winnerAddress ===
      'erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu'
        ? 'N/A'
        : nft.winnerAddress,
    specialAbility: getSpecialAbility(nft.tokenIdentifier),
    startTime: Math.floor(
      new Date(Date.parse(`${nft.startTime}Z`)).getTime() / 1000
    ),
    endTime: Math.floor(endTime),
    regularSummoningCount: regularSummoningCount,
    specialSummoningCount: specialSummoningCount,
    hasExpired: getTimeUntilShort(endTime).startsWith('-'),
    description: nft.description
  };

  return item;
};

export const transformMarketplaceListing2 = (nft: any) => {
  // const listingDetails: NFTListingDetails = {
  //   listingId: nft.listingId,
  //   tokenIdentifier: nft.tokenIdentifier,
  //   nonce: nft.nonce,
  //   nftName: nameSplit[0],
  //   royalties: nft.royalties,
  //   minBid: nft.minBid,
  //   maxBid: nft.maxBid,
  //   currentBid: nft.currentBid,
  //   originalOwner: nft.ownerAddress,
  //   winnerAddress: nft.winnerAddress,
  //   startTime: new Date(Date.parse(`${nft.startTime}Z`)),
  //   endTime: new Date(Date.parse(`${nft.endTime}Z`)),
  //   listingType: nft.listingType == 0 ? 'buyout' : 'auction',
  //   soulType: getSoulType(nft.tokenIdentifier),
  //   imageUrl: getNftUrl(nft.tokenIdentifier),
  //   thumbnailImageUrl: getThumbnailUrl(nft.tokenIdentifier),
  //   description: nft.description,
  //   sixthSummons: `${nft.sixthSummons}`,
  //   seventhSummon: `${nft.seventhSummon}`,
  //   specialAbility: getSpecialAbility(nft.tokenIdentifier)
  // };
  const endTime = new Date(Date.parse(`${nft.endTime}Z`)).getTime() / 1000;
  const regularSummoningCount = parseInt(nft.sixthSummons.split('/')[0]);
  const specialSummoningCount =
    nft.seventhSummon === 'N/A' || nft.seventhSummon === null
      ? undefined
      : parseInt(nft.seventhSummon.split('/')[0]);
  const isBuyout = nft.denominatedMinBid === nft.denominatedMaxBid;
  const minBid = isBuyout
    ? nft.denominatedMinBid
    : nft.currentBid === '0'
    ? nft.denominatedMinBid
    : nft.denominatedCurrentBid;
  const item: MarketplaceListing = {
    listingId: nft.listingId,
    collection: nft.tokenIdentifier,
    identifier: `${nft.tokenIdentifier}-${int2hex(nft.nonce)}`,
    nonce: nft.nonce,
    isOrigin: OriginNFTCollections.includes(nft.tokenIdentifier),
    name: nft.nftName,
    thumbnailUrl: getThumbnailUrl(nft.tokenIdentifier, nft.nonce),
    fullResourceUrl: getNftUrl(nft.tokenIdentifier, nft.nonce),
    isBuyout: isBuyout,
    minBid: minBid,
    maxBid: nft.denominatedMaxBid,
    originalOwnerAddress: nft.ownerAddress,
    currentWinnerAddress:
      nft.winnerAddress ===
      'erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu'
        ? 'N/A'
        : nft.winnerAddress,
    specialAbility: getSpecialAbility(nft.tokenIdentifier),
    startTime: Math.floor(
      new Date(Date.parse(`${nft.startTime}Z`)).getTime() / 1000
    ),
    endTime: Math.floor(endTime),
    regularSummoningCount: regularSummoningCount,
    specialSummoningCount: specialSummoningCount,
    hasExpired: getTimeUntilShort(endTime).startsWith('-'),
    description: nft.description
  };

  return item;
};

export const fillSummoningInfo = async (nfts: AccountNft[]) => {
  const identifiers = nfts.filter((n) => n.isSoul).map((n) => n.identifier);
  const fullSummoningInfo = await getSummoningCounts(identifiers);
  if (!fullSummoningInfo.success) {
    return nfts;
  }
  for (let i = 0; i < nfts.length; i++) {
    if (!SoulsNFTCollections.includes(nfts[i].collection)) {
      continue;
    }
    const nftSummoningInfo = fullSummoningInfo.data[nfts[i].identifier];
    if (nftSummoningInfo.length > 0) {
      nfts[i].regularSummoningCount = nftSummoningInfo[0];
    }
    if (nftSummoningInfo.length > 1 && nftSummoningInfo[1] !== null) {
      nfts[i].specialSummoningCount = nftSummoningInfo[1];
    }
  }
  return nfts;
};

export const fillPendingRewards = async (batches: AccountNft[]) => {
  for (let i = 0; i < batches.length; i++) {
    const identifierIndex = StakedKosonCollections.indexOf(
      batches[i].collection
    );
    if (identifierIndex === -1) {
      continue;
    }
    const contract = kosonStakingPools[identifierIndex];
    const rewards = await fetchPendingRewards(
      contract,
      batches[i].stakeDay || 0,
      new BigNumber(batches[i].unparsedAmount)
    );
    batches[i].pendingReward = rewards;
  }
  return batches;
};

const fetchPendingRewards = async (
  scAddress: string,
  stakeDay: number,
  stakeBalance: BigNumber
) => {
  const stakeDayArg = new U64Value(stakeDay);
  const stakeBalanceArg = new BigUIntValue(stakeBalance);

  const contract = await getSmartContract(scAddress);
  const interaction = contract.methodsExplicit.getPendingRewards([
    stakeDayArg,
    stakeBalanceArg
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.div(new BigNumber(10).pow(18)).toNumber();
  }
  return 0;
};

const getNftUrl = (tokenIdentifier: string, nonce: number) => {
  let baseUrl = 'https://nft.ageofzalmoxis.com/ipfs/';
  switch (tokenIdentifier) {
    case SoulsNFTCollections[1]:
      baseUrl +=
        'QmSkiEotTxx1f75yr9oWFPyKVSqwaiUJvfKRcn2scuEb5g/Origin-Air-Soul-Element.png';
      break;
    case SoulsNFTCollections[2]:
      baseUrl +=
        'QmNwAMvP3AjAFknoaELM537ShKVHkQS5BEYNZtWmdkRWyr/Origin-Earth-Soul-Element.png';
      break;
    case SoulsNFTCollections[3]:
      baseUrl +=
        'QmUVQprYBqXQmm5gtvqFCjwhGuAXTc1rouCSKK21y2Meyf/Origin-Fire-Soul-Element.png';
      break;
    case SoulsNFTCollections[4]:
      baseUrl +=
        'QmRb8Ptz7Wprn1mtQeKdEQrSPEN5CEZ3dhM9vdVY3dKwVi/Origin-Life%20Soul%20Element.png';
      break;
    case SoulsNFTCollections[5]:
      baseUrl +=
        'Qmf3KBEMzhqyp4bDadK62BbA64iFBNhF2tz9bWK6VJHuKM/Origin-Aether-Soul-Element.png';
      break;
    case SoulsNFTCollections[6]:
      baseUrl +=
        'QmSdpbzWt6kUk2fPQFweN94z6CvgSTSZzMZ2JqyrCzwCEk/Origin-Water-Soul-Element.png';
      break;
    case SoulsNFTCollections[7]:
      baseUrl +=
        'QmdJek7DhZhkqoznYZHbYemiRR8CHuzoFXEcAABG9FZAC8/Summoned-Air-Soul-Element.png';
      break;
    case SoulsNFTCollections[8]:
      baseUrl +=
        'QmUELf8NF2KvtMzmG69GMj47YQxjcP4NqxvqseZ53x2R1D/Summoned-Earth-Soul-Element.png';
      break;
    case SoulsNFTCollections[9]:
      baseUrl +=
        'QmWzLjFArH2qVABWPkPJGnxHUT1uH3kWBXJ8RVNcc9eMrU/Summoned-Fire-Soul-Element.png';
      break;
    case SoulsNFTCollections[10]:
      baseUrl +=
        'Qmct9YaEdv8HBjCyXgg3cWSK1i2k5zpM7LEVoAMbrd6oD7/Summoned-Life-Soul-Element.png';
      break;
    case SoulsNFTCollections[11]:
      baseUrl +=
        'QmcKdBCeDMDZz9FRFJJ8wB8ao349RZAV3LJCus9S9ryjR2/Summoned-Aether-Soul-Element.png';
      break;
    case SoulsNFTCollections[12]:
      baseUrl +=
        'QmQnsTvC6UTxdGuUpaub7RzsW1D8nAqP2bGU9F6WHaAv2a/Summoned-Water-Soul-Element.png';
      break;
    case SoulsNFTCollections[13]:
      baseUrl +=
        'QmNxi8QXb5GbGRPU2GfzG1veXniS1Lvc9MXiyb8FXaExut/Origin-Death-Soul-Element.png';
      break;
  }
  if (LandChestContentCollections.includes(tokenIdentifier)) {
    baseUrl = REWARD_IMG_MAPPING[`${tokenIdentifier}-${int2hex(nonce || 0)}`];
  }
  return baseUrl;
};

const getThumbnailUrl = (tokenIdentifier: string, nonce?: number) => {
  let thumbnailBaseUrl = '/images/aoz/thumbnails/';
  switch (tokenIdentifier) {
    case SoulsNFTCollections[1]:
      thumbnailBaseUrl += 'souls/air.png';
      break;
    case SoulsNFTCollections[2]:
      thumbnailBaseUrl += 'souls/earth.png';
      break;
    case SoulsNFTCollections[3]:
      thumbnailBaseUrl += 'souls/fire.png';
      break;
    case SoulsNFTCollections[4]:
      thumbnailBaseUrl += 'souls/life.png';
      break;
    case SoulsNFTCollections[5]:
      thumbnailBaseUrl += 'souls/aether.png';
      break;
    case SoulsNFTCollections[6]:
      thumbnailBaseUrl += 'souls/water.png';
      break;
    case SoulsNFTCollections[7]:
      thumbnailBaseUrl += 'summoned-souls/air.png';
      break;
    case SoulsNFTCollections[8]:
      thumbnailBaseUrl += 'summoned-souls/earth.png';
      break;
    case SoulsNFTCollections[9]:
      thumbnailBaseUrl += 'summoned-souls/fire.png';
      break;
    case SoulsNFTCollections[10]:
      thumbnailBaseUrl += 'summoned-souls/life.png';
      break;
    case SoulsNFTCollections[11]:
      thumbnailBaseUrl += 'summoned-souls/aether.png';
      break;
    case SoulsNFTCollections[12]:
      thumbnailBaseUrl += 'summoned-souls/water.png';
      break;
    case SoulsNFTCollections[13]:
      thumbnailBaseUrl += 'summoned-souls/death.png';
      break;
    case LandChestNFTCollection:
      thumbnailBaseUrl += `chests/${nonce}.png`;
      break;
  }
  if (LandChestContentCollections.includes(tokenIdentifier)) {
    thumbnailBaseUrl =
      REWARD_IMG_MAPPING[`${tokenIdentifier}-${int2hex(nonce || 0)}`];
  }
  return thumbnailBaseUrl;
};

const getVideoUrl = (nft: any) => {
  if (LandChestContentCollections.includes(nft.collection)) {
    return REWARD_IMG_MAPPING[`${nft.collection}-${int2hex(nft.nonce || 0)}`];
  }
  if (nft.collection !== LandChestNFTCollection) {
    return '';
  }
  switch (nft.nonce) {
    case 1:
      return 'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Continental.mp4';
    case 2:
      return 'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Steepe.mp4';
    case 3:
      return 'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Panonic.mp4';
    case 4:
      return 'https://nft.ageofzalmoxis.com/ipfs/QmT1J6L5QztvLNHiBzfMnLf6MGpqvnDqVCmvx9eGLfkoru/SFT_Pontic.mp4';
  }
};

export const getSpecialAbility = (token: string) => {
  switch (token) {
    case SoulsNFTCollections[1]:
      return 'Cyclone';
    case SoulsNFTCollections[2]:
      return 'Quake';
    case SoulsNFTCollections[3]:
      return 'Inferno';
    case SoulsNFTCollections[4]:
      return 'Serenity';
    case SoulsNFTCollections[5]:
      return 'Prism';
    case SoulsNFTCollections[6]:
      return 'Maelstrom';
    case SoulsNFTCollections[13]:
      return 'Reincarnate';
    default:
      return '';
  }
};

export const getNftName = (tokenIdentifier: string, nonce: number) => {
  switch (tokenIdentifier) {
    case SoulsNFTCollections[1]:
      return `Origin: Air #${nonce}`;
    case SoulsNFTCollections[2]:
      return `Origin: Earth #${nonce}`;
    case SoulsNFTCollections[3]:
      return `Origin: Fire #${nonce}`;
    case SoulsNFTCollections[4]:
      return `Origin: Life #${nonce}`;
    case SoulsNFTCollections[5]:
      return `Origin: Aether #${nonce}`;
    case SoulsNFTCollections[6]:
      return `Origin: Water #${nonce}`;
    case SoulsNFTCollections[7]:
      return `Summoned: Air #${nonce}`;
    case SoulsNFTCollections[8]:
      return `Summoned: Earth #${nonce}`;
    case SoulsNFTCollections[9]:
      return `Summoned: Fire #${nonce}`;
    case SoulsNFTCollections[10]:
      return `Summoned: Life #${nonce}`;
    case SoulsNFTCollections[11]:
      return `Summoned: Aether #${nonce}`;
    case SoulsNFTCollections[12]:
      return `Summoned: Water #${nonce}`;
    case SoulsNFTCollections[13]:
      return `Origin: Death #${nonce}`;
    case LandChestNFTCollection: {
      switch (nonce) {
        case 1:
          return 'Continental Chest';
        case 2:
          return 'Steepe Chest';
        case 3:
          return 'Panonic Chest';
        case 4:
          return 'Pontic Chest';
        default:
          return '';
      }
    }
    case LandPlotsTokenIdentifier: {
      switch (nonce) {
        case 5:
          return 'Continental Token';
        case 4:
          return 'Steepe Token';
        case 3:
          return 'Panonic Token';
        case 2:
          return 'Pontic Token';
        default:
          return '';
      }
    }
  }
  return '';
};
