// slices/accountInfo/inventory.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccountInventory } from 'api/v2/getAccountInventory';
import { AccountNft } from 'types';

export interface InventoryData {
  souls: AccountNft[];
  landChests: AccountNft[];
  landPlots: AccountNft[];
  items: AccountNft[];
  koson: string;

  hasFullOriginSet: boolean;
  canSummonRegularSoul: boolean;
  canSummonDeathSoul: boolean;
}
export const DefaultInventoryData: InventoryData = {
  souls: [],
  landChests: [],
  landPlots: [],
  items: [],
  koson: '0',
  hasFullOriginSet: false,
  canSummonRegularSoul: false,
  canSummonDeathSoul: false
};

interface inventoryState {
  // your state fields
  data: InventoryData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: inventoryState = {
  // initialize fields
  data: DefaultInventoryData,
  status: 'loading',
  error: null
};

export const fetchInventory = createAsyncThunk(
  'accountInfo/inventory',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await getAccountInventory(address);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchInventory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(fetchInventory.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default inventorySlice.reducer;
