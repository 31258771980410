import React from 'react';

const ActionAlert = ({
  alertText,
  actionText,
  action
}: {
  alertText: string;
  actionText: string;
  action: () => Promise<void>;
}) => {
  return (
    <div className='row'>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <div className='alert alert-outline-warning alert-rounded text-center'>
          <span className='text-center' style={{ fontSize: '14px' }}>
            {alertText}{' '}
            <a href='#' onClick={action}>
              {actionText}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActionAlert;
