import { faTreasureChest } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const LandChestSale = () => {
  return (
    <div className='text-center m-auto p-5'>
      <FontAwesomeIcon
        icon={faTreasureChest as any}
        className='text-gray mt-5'
        size='9x'
      />
      <h1 className='mt-5'>
        The Land Chest sale for the MultiversX community has ended
      </h1>
      <h3>Thank you for participating!</h3>
    </div>
  );
};

export default LandChestSale;
