import React from 'react';
import AccountInfo from './AccountInfo';
import AccountStatsCards from './AccountStatsCards';
import Activity from 'components/Activity';
import { Web3Context } from 'contexts/Web3Context';
import Loading from 'components/Loading';

const Overview = () => {
  const {
    accountState,
    nftState,
    stakedLandChestsState,
    nftStakingState,
    vestingState,
    kosonStakingState
  } = React.useContext(Web3Context);
  return (
    <>
      {accountState?.isLoading ||
      nftStakingState?.isLoading ||
      nftState?.isLoading ||
      stakedLandChestsState?.isLoading ||
      vestingState?.isLoading ||
      kosonStakingState?.isLoading ? (
        <Loading coverFullPage={true} />
      ) : (
        <div className='row'>
          <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
            <AccountInfo />
          </div>
          <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
            <AccountStatsCards />
          </div>
        </div>
      )}
      <div className='row mt-2'>
        <div className='col-xl-12 col-lg-12 col-sm-12'>
          <Activity />
        </div>
      </div>
    </>
  );
};

export default Overview;
