import NFTThumbnail from 'components/NFT/NftThumbnail';
import { nftStakingContractAddress } from 'config';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import React from 'react';
import { AccountNft } from 'types';

const NftsSelectorModalContent = ({
  nfts,
  onSelect
}: {
  nfts: AccountNft[];
  onSelect: (nft: AccountNft) => void;
}) => {
  const cancelButtonRef = React.createRef<HTMLButtonElement>();
  const handleSelectNft = (nft: AccountNft) => {
    onSelect(nft);
    cancelButtonRef.current?.click();
  };
  return (
    <div className='modal-dialog modal-lg'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Select NFT</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div
          className='modal-body with-scroll'
          style={{ maxHeight: `${(window.innerHeight * 0.7).toFixed(2)}px` }}
        >
          <div className='row'>
            {nfts.map((nft, i) => (
              <NFTThumbnail
                nft={nft}
                key={`nfts-selector-item-key-${i}`}
                handleSelect={() => handleSelectNft(nft)}
                isSelectable={true}
                customColSize='col-lg-4 col-sm-6 col-md-6'
              />
            ))}
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default NftsSelectorModalContent;
