import {
  gatewayAddress,
  kosonStakingPools,
  landChestOpeningContractAddress,
  landChestSaleContractAddress,
  landChestStakingContractAddress,
  nftStakingContractAddress,
  summoningContractAddress,
  vestingContractAddress
} from 'config';
import vestingJson from 'abi/vesting.abi.json';
import nftStakingJson from 'abi/nftStaking.abi.json';
import landChestStakingJson from 'abi/landChestStaking.abi.json';
import kosonStakingJson from 'abi/kosonStaking.abi.json';
import summoningJson from 'abi/summoning.abi.json';
import landChestSaleJson from 'abi/landChestSale.abi.json';
import landChestOpeningJson from 'abi/landChestOpening.abi.json';
import {
  ResultsParser,
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';

const abiMapping = (address: string) => {
  switch (address) {
    case vestingContractAddress:
      return vestingJson;
    case nftStakingContractAddress:
      return nftStakingJson;
    case landChestStakingContractAddress:
      return landChestStakingJson;
    case summoningContractAddress:
      return summoningJson;
    case landChestSaleContractAddress:
      return landChestSaleJson;
    case landChestOpeningContractAddress:
      return landChestOpeningJson;
    default:
      if (kosonStakingPools.includes(address)) {
        return kosonStakingJson;
      }
      return undefined;
  }
};

export const Provider = new ProxyNetworkProvider(gatewayAddress, {
  timeout: 10000
});
export const Parser = new ResultsParser();

export const getSmartContract = (scAddress: string) => {
  const abi = loadAbiByAddress(scAddress);
  return new SmartContract({
    address: new Address(scAddress),
    abi
  });
};

const loadAbiByAddress = (address: string): SmartContractAbi | undefined => {
  const json = abiMapping(address);
  if (json === undefined) {
    return undefined;
  }
  const abiRegistry = AbiRegistry.create(formatAbiJson(json));
  const abi = new SmartContractAbi(abiRegistry);
  return abi;
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};
