import NFTStakingCard from 'components/Cards/NFTStakingCard';
import StatsCard from 'components/Cards/ColoredStatsCard';
import React from 'react';
import { ReactComponent as KossonsSymbolPlebea } from './../../assets/images/aoz/Koson_Plebea.svg';
import { ReactComponent as KossonsSymbolComati } from './../../assets/images/aoz/Koson_Comati.svg';
import { ReactComponent as KossonsSymbolPileati } from './../../assets/images/aoz/Koson_Pileati.svg';
import { ReactComponent as KossonsSymbolTarabostes } from './../../assets/images/aoz/Koson_Tarabostes.svg';
import { ReactComponent as KossonsSymbolStrategos } from './../../assets/images/aoz/Koson_Strategos.svg';
import { ReactComponent as KossonsSymbolBasileus } from './../../assets/images/aoz/Koson_Basileus.svg';
import {
  kosonStakingPoolNames,
  StakedKosonCollections,
  toLocaleStringOptions
} from 'config';
import TabContent from './TabContent';
import Activity from 'components/Activity';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import { Web3Context } from 'contexts/Web3Context';
import Loading from 'components/Loading';
import './style.css';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const KosonStaking = () => {
  const stakedKoson = useSelector(
    (state: RootState) => state.accountInfo.stakedInventory.data?.stakedKoson
  );
  const context = React.useContext(Web3Context);
  const getKosonStakingIcon = (index: number, fs?: string) => {
    let kosonStakingIcon;
    switch (index) {
      case 0:
        kosonStakingIcon = (
          <KossonsSymbolPlebea style={{ width: fs ?? '14px' }} />
        );
        break;
      case 1:
        kosonStakingIcon = (
          <KossonsSymbolComati style={{ width: fs ?? '14px' }} />
        );
        break;
      case 2:
        kosonStakingIcon = (
          <KossonsSymbolPileati style={{ width: fs ?? '14px' }} />
        );
        break;
      case 3:
        kosonStakingIcon = (
          <KossonsSymbolTarabostes style={{ width: fs ?? '14px' }} />
        );
        break;
      case 4:
        kosonStakingIcon = (
          <KossonsSymbolStrategos style={{ width: fs ?? '14px' }} />
        );
        break;
      case 5:
        kosonStakingIcon = (
          <KossonsSymbolBasileus style={{ width: fs ?? '14px' }} />
        );
        break;
    }
    return kosonStakingIcon;
  };

  const tabNav = (index: number) => {
    const kosonStakingIcon = getKosonStakingIcon(index);
    const poolName = kosonStakingPoolNames[index];
    const apy = context.kosonStakingState?.getPoolApy(index);
    return (
      <li className='nav-item' key={`tab-nav-header-item-key-${index}`}>
        <a
          className={index === 0 ? 'nav-link active' : 'nav-link'}
          data-bs-toggle='tab'
          href={`#${poolName}`}
        >
          {kosonStakingIcon} {poolName}
        </a>
      </li>
    );
  };

  return context.kosonStakingState?.isLoading || context.nftState?.isLoading ? (
    <Loading coverFullPage={true} />
  ) : (
    <div className='row'>
      {/* <KosonStakingCardInfo /> */}
      <div className='custom-tab-1'>
        <ul className='nav nav-tabs'>
          {[0, 1, 2, 3, 4, 5].map((i) => tabNav(i))}
        </ul>
        <div className='tab-content'>
          {[0, 1, 2, 3, 4, 5].map((i) => {
            const poolInfo = context.kosonStakingState?.getPoolInfo(i);
            return (
              <TabContent
                index={i}
                key={`tab-nav-content-item-key-${i}`}
                kosonStakingIcon={getKosonStakingIcon(i, '32px')}
                poolInfo={poolInfo}
                nfts={
                  (stakedKoson ?? []).filter(
                    (b) => b.collection === StakedKosonCollections[i]
                  ) ?? []
                }
              />
            );
          })}
        </div>
      </div>
      <div className='col-lg-12'>
        <Activity interaction={ACTIVITY_INTERACTION_TYPES.KosonStaking} />
      </div>
    </div>
  );
};

export default KosonStaking;
