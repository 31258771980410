import { Web3Context } from 'contexts/Web3Context';
import { PoolInfo } from 'hooks/useKosonStakingPoolInfo';
import React from 'react';
import ErrorModal from './ErrorModal';
import BigNumber from 'bignumber.js';
import { str2hex } from 'contexts/Web3Context/helpers/generalUtils';
import {
  kosonStakingPoolNames,
  KOSON_TICKER,
  toLocaleStringOptions
} from 'config';

const StakeKosonModalContent = ({ poolInfo }: { poolInfo?: PoolInfo }) => {
  const { sendTransaction, accountState } = React.useContext(Web3Context);
  const [amountToStakeDenominated, setAmountToStakeDenominated] =
    React.useState<BigNumber>(new BigNumber(0));
  const [inputKosonRatio, setInputKosonRation] = React.useState(0);
  const kosonInputRef = React.createRef<HTMLInputElement>();

  const handleSliderChange = (ratioStr: string) => {
    if (ratioStr === '') {
      ratioStr = '0';
    }
    const ratio = new BigNumber(ratioStr);
    let ratioAmountOfTotalDenominated = ratio
      .dividedBy(100)
      .multipliedBy(
        new BigNumber(accountState?.kosonBalanceDenominated || '0')
      );
    if (ratioAmountOfTotalDenominated.isLessThan(new BigNumber(0))) {
      ratioAmountOfTotalDenominated = new BigNumber(0);
    }
    setAmountToStakeDenominated(
      ratioAmountOfTotalDenominated.decimalPlaces(0, BigNumber.ROUND_FLOOR)
    );
    setInputKosonRation(parseFloat(ratio.toNumber().toFixed(2)));
    if (kosonInputRef?.current === null) return;
    kosonInputRef.current.value = ratioAmountOfTotalDenominated
      .div(new BigNumber(10).pow(18))
      .toFixed(2);
  };

  const handleInputChange = (inputStr: string) => {
    if (inputStr.endsWith('.')) return;
    if (inputStr.includes('-')) {
      inputStr = inputStr.replace('-', '');
      if (kosonInputRef?.current === null) return;
      kosonInputRef.current.value = inputStr;
    }
    if (inputStr === '') {
      inputStr = '0';
    }
    let input = new BigNumber(inputStr);
    if (input.isLessThan(new BigNumber(0))) {
      input = new BigNumber(0);
    }
    let inputDenominated = input.multipliedBy(new BigNumber(10).pow(18));
    const availableKosonDenominated = new BigNumber(
      accountState?.kosonBalanceDenominated || 0
    );
    if (inputDenominated.isGreaterThan(availableKosonDenominated)) {
      inputDenominated = availableKosonDenominated;
      if (kosonInputRef?.current === null) return;
      kosonInputRef.current.value = accountState?.kosonBalanceString || '0';
    }
    setAmountToStakeDenominated(
      inputDenominated.decimalPlaces(0, BigNumber.ROUND_FLOOR)
    );
    const inputKosonRatio = inputDenominated
      .dividedBy(availableKosonDenominated)
      .multipliedBy(100)
      .toNumber();
    setInputKosonRation(parseFloat(inputKosonRatio.toFixed(2)));
  };

  const handleStake = async () => {
    if (
      amountToStakeDenominated === undefined ||
      amountToStakeDenominated.isLessThan(new BigNumber(0))
    ) {
      window.alert('You need to stake more than 0 KOSON!');
      return;
    }
    if (
      amountToStakeDenominated.isGreaterThan(
        new BigNumber(accountState?.kosonBalanceDenominated || 0)
      )
    ) {
      window.alert('You do not have enough KOSON available');
      return;
    }

    let hexAmount = amountToStakeDenominated.toString(16);
    if (hexAmount.length % 2 === 1) {
      hexAmount = '0' + hexAmount;
    }

    const data = `ESDTTransfer@${str2hex(KOSON_TICKER)}@${hexAmount}@${str2hex(
      'stake'
    )}`;
    await sendTransaction(poolInfo?.contractAddress || '', 25_000_000, data);
  };

  return !poolInfo ? (
    <ErrorModal />
  ) : (
    <div className='modal-dialog modal-dialog-centered modal-lg'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            Stake $KOSON in {kosonStakingPoolNames[poolInfo.poolIndex]} Pool for
            ~{poolInfo.apy.toLocaleString(undefined, toLocaleStringOptions)}%
            APY
          </h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div
          className='modal-body with-scroll'
          style={{ maxHeight: `${(window.innerHeight * 0.7).toFixed(2)}px` }}
        >
          <div className='row'>
            <div className='col-lg-10 col-sm-12'>
              <input
                type='range'
                min='0'
                max='100'
                step='1'
                className='w-100'
                onChange={(e) => handleSliderChange(e.target.value)}
                value={inputKosonRatio}
              />
            </div>
            <div className='col-lg-2 col-sm-12'>
              <span>{inputKosonRatio}%</span>
            </div>
            <div className='col-lg-10 col-md-10 col-sm-10'>
              <input
                type='number'
                className='form-control input-rounded'
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder='$KOSON amount to be staked'
                style={{ fontSize: '16px' }}
                lang='en-US'
                step='0.01'
                ref={kosonInputRef}
              />
            </div>
            <div className='col-lg-2 col-sm-12 mt-2'>
              <a href='#' onClick={() => handleSliderChange('100')}>
                MAX
              </a>
            </div>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 text-left mt-4'>
            <ul>
              {stakePoolCharacteristics[poolInfo?.poolIndex].map((t, i) => (
                <li key={`stake-koson-modal-key-${poolInfo.poolIndex}-${i}`}>
                  <p>{t}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Cancel
          </button>
          {!amountToStakeDenominated.isGreaterThan(new BigNumber(0)) && (
            <button type='button' className='btn btn-primary disabled'>
              Stake
            </button>
          )}
          {amountToStakeDenominated.isGreaterThan(new BigNumber(0)) && (
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleStake}
              data-bs-dismiss='modal'
            >
              Stake
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StakeKosonModalContent;

const stakePoolCharacteristics = [
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of one day',
    '* Staking in this pool has an unstake penalty of one day',
    '* Claiming or unstaking resets the time counter'
  ],
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of three months',
    '* Staking in this pool has an unstake penalty of three months',
    '* Claiming or unstaking resets the time counter',
    '* You will still earn rewards after this period has ended for as long as you have your KOSON staked here'
  ],
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of six months',
    '* Staking in this pool has an unstake penalty of six months',
    '* Claiming or unstaking resets the time counter',
    '* You will still earn rewards after this period has ended for as long as you have your KOSON staked here'
  ],
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of twelve months',
    '* Staking in this pool has an unstake penalty of twelve months',
    '* Claiming or unstaking resets the time counter',
    '* You will still earn rewards after this period has ended for as long as you have your KOSON staked here'
  ],
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of up to two years, until the Battle Royale mode is released',
    '* Staking in this pool has an unstake penalty of up to two years, until the Battle Royale mode is released',
    '* Claiming or unstaking resets the time counter',
    '* You will earn rewards up until this period has ended',
    '* This pool is available for up to one year prior the Battle Royale mode release'
  ],
  [
    '* The staking pool automatically compounds your stakes on a daily basis',
    '* Staking in this pool has a claim rewards penalty of up to four years, until the MMORPG game mode is released',
    '* Staking in this pool has an unstake penalty of up to four years, until the  MMORPG game mode is released',
    '* Claiming or unstaking resets the time counter',
    '* You will earn rewards up until this period has ended',
    '* This pool is available for up to one year prior to MMORPG Game mode release'
  ]
];
