// slices/accountInfo/vesting.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface VestingData {
  // replace with your actual data type
  _: boolean;
}

interface VestingState {
  data: VestingData[] | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: VestingState = {
  data: [],
  status: 'idle',
  error: null
};

export const fetchVesting = createAsyncThunk(
  'vesting/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const data: VestingData[] = []; //await getVesting(); // replace with your API call
      return data;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVesting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchVesting.fulfilled,
        (state, action: PayloadAction<VestingData[]>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(fetchVesting.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default vestingSlice.reducer;
