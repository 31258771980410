import React from 'react';
import {
  U64Value,
  AddressValue,
  Address,
  BooleanValue
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { getRemainingLandChests } from 'api/apiRequests';
import { getCurrentLandChestPrices, getKosonPrice } from 'api/backendRequests';
import { landChestSaleContractAddress } from 'config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import { LandChestPrice } from 'types';

const useLandChestSaleInfo = ({
  address
}: {
  address: string;
}): ILandChestSaleInfoType => {
  const isLoggedIn = new Boolean(address);
  const [landChestPrices, setLandChestPrices] = React.useState<
    LandChestPrice[]
  >([]);
  const [landChestQuantities, setLandChestQuantities] = React.useState<
    number[]
  >([0, 0, 0, 0]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getSCLandChestKosonPrice = async (chestId: number) => {
    const nonceArg = new U64Value(chestId);
    const quantityArg = new U64Value(1);
    const addressArg = isLoggedIn
      ? new AddressValue(new Address(address))
      : new AddressValue(Address.Zero());

    const contract = await getSmartContract(landChestSaleContractAddress);
    const interaction = contract.methodsExplicit.getCalculatedExpectedPrice([
      nonceArg,
      quantityArg,
      addressArg,
      new BooleanValue(false)
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue?.valueOf();
    }
    return 0;
  };

  const loadPrices = async () => {
    const backendPrices = await getCurrentLandChestPrices(address);
    const kosonPriceUsd = (await getKosonPrice())?.data ?? 0.5;
    if (backendPrices.success) {
      const prices = [];
      for (let i = 0; i < backendPrices.data.length; i++) {
        const currentPrice = backendPrices.data[i];
        // const discount =
        //   currentPrice.discountedPriceKosonAmount /
        //   currentPrice.fullPriceKosonAmount;
        if (address.startsWith('erd')) {
          const scKosonPrice = await getSCLandChestKosonPrice(
            currentPrice.chestId
          );
          currentPrice.discountedPriceKosonAmount = scKosonPrice;
        } else {
          currentPrice.discountedPriceKosonAmount = new BigNumber(
            currentPrice.fullPriceUsd
          )
            .dividedBy(kosonPriceUsd)
            .dividedBy(4)
            .multipliedBy(new BigNumber(10).pow(18));
        }
        // currentPrice.discountedPriceKosonAmount *= discount;
        prices.push(currentPrice);
      }
      setLandChestPrices(prices);
    }
  };

  const loadRemainingQuantities = async () => {
    const remainingChests = await getRemainingLandChests();
    const quantities = [0, 0, 0, 0];
    for (let i = 0; i < 4; i++) {
      const currentChestNonce = remainingChests.data.filter(
        (rc: any) => rc.nonce === i + 1
      );
      if (currentChestNonce.length === 0) {
        continue;
      }
      quantities[i] = parseInt(currentChestNonce[0].balance);
    }
    setLandChestQuantities(quantities);
  };

  const refreshState = async () => {
    await loadPrices();
    await loadRemainingQuantities();
  };

  const getLandChestPrice = (chestId: number) => {
    if (isLoading || landChestPrices.length === 0) {
      const defaultPrice: LandChestPrice = {
        chestId: 0,
        fullPriceUsd: 0,
        discountedPriceUsd: 0,
        fullPriceKosonUsd: 0,
        discountPriceKosonUsd: 0,
        fullPriceKosonAmount: 0,
        discountedPriceKosonAmount: 0
      };
      return defaultPrice;
    }
    return landChestPrices[chestId - 1];
  };

  React.useEffect(() => {
    // refreshState().then(() => {
    //   setIsLoading(false);
    // });
  }, []);

  const getExpectedPrice = async (
    nonce: number,
    quantity: number,
    withUsdAmount: boolean
  ) => {
    //
    const nonceArg = new U64Value(nonce);
    const quantityArg = new U64Value(quantity);
    const addressArg = isLoggedIn
      ? new AddressValue(new Address(address))
      : new AddressValue(Address.Zero());

    const contract = await getSmartContract(landChestSaleContractAddress);
    const interaction = contract.methodsExplicit.getCalculatedExpectedPrice([
      nonceArg,
      quantityArg,
      addressArg,
      new BooleanValue(withUsdAmount)
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      return parsedResponse.firstValue?.valueOf();
    }
    return new BigNumber(0);
  };

  return {
    landChestPrices: landChestPrices,
    landChestQuantities: landChestQuantities,
    isLoading: isLoading,
    getLandChestPrice: getLandChestPrice,
    refreshState: refreshState,
    getExpectedPrice: getExpectedPrice
  };
};

export default useLandChestSaleInfo;

export interface ILandChestSaleInfoType {
  landChestPrices: LandChestPrice[];
  landChestQuantities: number[];
  isLoading: boolean;
  getLandChestPrice: (chestId: number) => LandChestPrice;
  getExpectedPrice: (
    nonce: number,
    quantity: number,
    withUsdAmount: boolean
  ) => Promise<BigNumber>;
  refreshState: () => Promise<void>;
}
