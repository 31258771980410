// slices/marketplace/marketplaceListings.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllNftListings,
  getAllNftListings2,
  getLatestNftListings,
  getLatestNftListings2
} from 'api/backendRequests';
import { RootState } from 'store';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';

interface MarketplaceListingsData {
  freshListings: MarketplaceListingV2[];
  allListings: MarketplaceListingV2[];
}

interface MarketplaceListingsState {
  data: MarketplaceListingsData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: MarketplaceListingsState = {
  data: {
    freshListings: [],
    allListings: []
  },
  status: 'idle',
  error: null
};

export const fetchMarketplaceListings = createAsyncThunk(
  'marketplaceListings/fetch',
  async (_, { rejectWithValue }) => {
    try {
      // Initialize with empty arrays
      const latestListings = await getLatestNftListings2();
      const allListings = await getAllNftListings2();
      const data: MarketplaceListingsData = {
        freshListings: latestListings.success ? latestListings.data : [],
        allListings: allListings.success ? allListings.data : []
      };
      console.log('Fetched marketplace listings', data);
      return data;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const marketplaceListingsSlice = createSlice({
  name: 'marketplaceListings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketplaceListings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMarketplaceListings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchMarketplaceListings.rejected, (state, action: any) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default marketplaceListingsSlice.reducer;

export const selectListingById = (state: RootState, listingId: number) =>
  state.marketplace.marketplaceListings.data?.allListings.find(
    (listing) => listing.listingId === listingId
  );

/*
import { useSelector } from 'react-redux';
import { selectListingById } from 'slices/accountInfo/marketplaceListings';

// In your component:
const listing = useSelector(state => selectListingById(state, listingId));
 */
