import {
  LandChestNFTCollection,
  OriginNFTCollections,
  SoulsNFTCollections,
  StakedKosonCollections,
  SummonedNFTCollections
} from 'config';
import React, { useState } from 'react';

export const _defaultFilters: MarketplaceNFTFilters = {
  showOrigin: true,
  showSummoned: true,
  showEarth: true,
  showFire: true,
  showAether: true,
  showLife: true,
  showAir: true,
  showWater: true,
  showDeath: true,
  showCommonSummoningCounts: [0, 1, 2, 3, 4, 5, 6],
  showSpecialSummoningCounts: [0, 1],
  showExpired: false,
  showExpiringLess1D: true,
  showExpiringLess1W: true,
  showExpiringLess1M: true
};

const MarketplaceFilterModalContent = ({
  defaultFilters,
  onApply
}: {
  defaultFilters?: MarketplaceNFTFilters;
  onApply: (remainingKeys: MarketplaceNFTFilters) => void;
}) => {
  const [currentFilters, setCurrentFilters] = useState<MarketplaceNFTFilters>(
    defaultFilters ?? _defaultFilters
  );

  const [showOrigin, setShowOrigin] = useState(_defaultFilters.showOrigin);
  const [showSummoned, setShowSummoned] = useState(
    _defaultFilters.showSummoned
  );
  const [showEarth, setShowEarth] = useState(_defaultFilters.showEarth);
  const [showFire, setShowFire] = useState(_defaultFilters.showFire);
  const [showAether, setShowAether] = useState(_defaultFilters.showAether);
  const [showLife, setShowLife] = useState(_defaultFilters.showLife);
  const [showAir, setShowAir] = useState(_defaultFilters.showAir);
  const [showWater, setShowWater] = useState(_defaultFilters.showWater);
  const [showDeath, setShowDeath] = useState(_defaultFilters.showDeath);

  const [showCommonSummoningCounts, setShowCommonSummoningCounts] = useState(
    _defaultFilters.showCommonSummoningCounts
  );
  const [showSpecialSummoningCounts, setShowSpecialSummoningCounts] = useState(
    _defaultFilters.showSpecialSummoningCounts
  );
  const [showExpired, setShowExpired] = useState(_defaultFilters.showExpired);
  const [showExpiringLess1D, setShowExpiringLess1D] = useState(
    _defaultFilters.showExpiringLess1D
  );
  const [showExpiringLess1W, setShowExpiringLess1W] = useState(
    _defaultFilters.showExpiringLess1W
  );
  const [showExpiringLess1M, setShowExpiringLess1M] = useState(
    _defaultFilters.showExpiringLess1M
  );

  const checkboxComponent = (
    display: string,
    checked: boolean,
    onChangeAction: () => void
  ) => {
    return (
      <div className='form-check'>
        <input
          type='checkbox'
          className='form-check-input'
          defaultChecked={checked}
          onChange={onChangeAction}
        />
        <label className='form-check-label mt-1'>{display}</label>
      </div>
    );
  };

  const handleApply = () => {
    const newFilters: MarketplaceNFTFilters = {
      showOrigin: showOrigin,
      showSummoned: showSummoned,
      showEarth: showEarth,
      showFire: showFire,
      showAether: showAether,
      showLife: showLife,
      showAir: showAir,
      showWater: showWater,
      showDeath: showDeath,
      showCommonSummoningCounts: showCommonSummoningCounts,
      showSpecialSummoningCounts: showSpecialSummoningCounts,
      showExpired: showExpired,
      showExpiringLess1D: showExpiringLess1D,
      showExpiringLess1W: showExpiringLess1W,
      showExpiringLess1M: showExpiringLess1M
    };

    onApply(newFilters);
  };

  const toggleInArray = (
    value: number,
    prop: number[],
    propSetter: (d: number[]) => void
  ) => {
    if (prop.includes(value)) {
      propSetter(prop.filter((v) => v != value));
    } else {
      propSetter([...prop, value]);
    }
  };

  const resetToDefault = () => {
    setShowOrigin(_defaultFilters.showOrigin);
    setShowSummoned(_defaultFilters.showSummoned);
    setShowEarth(_defaultFilters.showEarth);
    setShowFire(_defaultFilters.showFire);
    setShowAether(_defaultFilters.showAether);
    setShowLife(_defaultFilters.showLife);
    setShowAir(_defaultFilters.showAir);
    setShowWater(_defaultFilters.showWater);
    setShowDeath(_defaultFilters.showDeath);
    setShowCommonSummoningCounts(_defaultFilters.showCommonSummoningCounts);
    setShowSpecialSummoningCounts(_defaultFilters.showSpecialSummoningCounts);
    setShowExpired(_defaultFilters.showExpired);
    setShowExpiringLess1D(_defaultFilters.showExpiringLess1D);
    setShowExpiringLess1W(_defaultFilters.showExpiringLess1W);
    setShowExpiringLess1M(_defaultFilters.showExpiringLess1M);

    const checkboxes = document.getElementsByClassName('form-check-input');
    for (let i = 0; i < checkboxes.length; i++) {
      (checkboxes[i] as HTMLInputElement).checked = true;
    }
    onApply(_defaultFilters);
  };

  return (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Filters</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-lx-12 col-lg-12 col-md-12 col-sm-12 text-left'>
              <small>Soul element</small>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('Show Earth', showEarth, () =>
                setShowEarth(!showEarth)
              )}
              {checkboxComponent('Show Fire', showFire, () =>
                setShowFire(!showFire)
              )}
              {checkboxComponent('Show Aether', showAether, () =>
                setShowAether(!showAether)
              )}
              {checkboxComponent('Show Death', showDeath, () =>
                setShowDeath(!showDeath)
              )}
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('Show Life', showLife, () =>
                setShowLife(!showLife)
              )}
              {checkboxComponent('Show Air', showAir, () =>
                setShowAir(!showAir)
              )}
              {checkboxComponent('Show Water', showWater, () =>
                setShowWater(!showWater)
              )}
            </div>

            <div className='col-lx-12 col-lg-12 col-md-12 col-sm-12 text-left mt-3'>
              <small>Soul type</small>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('Show Origin', showOrigin, () =>
                setShowOrigin(!showOrigin)
              )}
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('Show Summoned', showSummoned, () =>
                setShowSummoned(!showSummoned)
              )}
            </div>
            <div className='col-lx-12 col-lg-12 col-md-12 col-sm-12 text-left mt-3'>
              <small>Elemental soul summoning counts</small>
            </div>
            <div className='row'>
              <div className='col-lg-12 col-sm-12 col-md-12 text-left'>
                {checkboxComponent(
                  'Never used in elemental soul summonings',
                  showCommonSummoningCounts.includes(0),
                  () =>
                    toggleInArray(0, showCommonSummoningCounts, (d) =>
                      setShowCommonSummoningCounts(d)
                    )
                )}
              </div>
              {[1, 2, 3, 4, 5, 6].map((v, id) => (
                <div
                  className='col-lg-2 col-md-2 col-sm-2 text-left'
                  key={`regular-summonings-picker-v${id}`}
                >
                  {checkboxComponent(
                    `${v}/6`,
                    showCommonSummoningCounts.includes(v),
                    () =>
                      toggleInArray(v, showCommonSummoningCounts, (d) =>
                        setShowCommonSummoningCounts(d)
                      )
                  )}
                </div>
              ))}
            </div>
            <div className='col-lx-12 col-lg-12 col-md-12 col-sm-12 text-left mt-3'>
              <small>Death soul summoning counts</small>
            </div>
            {[0, 1].map((v, id) => (
              <div
                className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'
                key={`special-summonings-picker-v${id}`}
              >
                {checkboxComponent(
                  `${v}/1`,
                  showSpecialSummoningCounts.includes(v),
                  () =>
                    toggleInArray(v, showSpecialSummoningCounts, (d) =>
                      setShowSpecialSummoningCounts(d)
                    )
                )}
              </div>
            ))}

            <div className='col-lx-12 col-lg-12 col-md-12 col-sm-12 text-left mt-3'>
              <small>Expire time</small>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('Expired', showExpired, () =>
                setShowExpired(!showExpired)
              )}
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('<1M', showExpiringLess1M, () =>
                setShowExpiringLess1M(!showExpiringLess1M)
              )}
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('<1W', showExpiringLess1W, () =>
                setShowExpiringLess1W(!showExpiringLess1W)
              )}
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 text-left'>
              {checkboxComponent('<1D', showExpiringLess1D, () =>
                setShowExpiringLess1D(!showExpiringLess1D)
              )}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={resetToDefault}
            data-bs-dismiss='modal'
          >
            Reset
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => handleApply()}
            data-bs-dismiss='modal'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceFilterModalContent;

export interface MarketplaceNFTFilters {
  showOrigin: boolean;
  showSummoned: boolean;
  showEarth: boolean;
  showFire: boolean;
  showAether: boolean;
  showLife: boolean;
  showAir: boolean;
  showWater: boolean;
  showDeath: boolean;
  showCommonSummoningCounts: number[];
  showSpecialSummoningCounts: number[];
  showExpired: boolean;
  showExpiringLess1D: boolean;
  showExpiringLess1W: boolean;
  showExpiringLess1M: boolean;
}
