import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faGhost as faGhostSolid } from '@fortawesome/pro-solid-svg-icons';
import {
  faCoins,
  faGhost as faGhostRegular,
  faTreasureChest
} from '@fortawesome/pro-regular-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import {
  OriginNFTCollections,
  SummonedNFTCollections,
  toLocaleStringOptions,
  toLocaleStringOptionsNoDecimals
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
const InventoryCardInfo = () => {
  const context = React.useContext(Web3Context);

  return (
    <>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Origin souls'}
          value={(
            context.nftState?.accountSoulNfts.filter((nft) =>
              OriginNFTCollections.includes(nft.collection)
            ).length || 0
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          icon={faGhostSolid}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Summoned souls'}
          value={(
            context.nftState?.accountSoulNfts.filter((nft) =>
              SummonedNFTCollections.includes(nft.collection)
            ).length || 0
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          icon={faGhostRegular}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Land chests'}
          value={(
            context.nftState?.accountLandChests.length || 0
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          icon={faTreasureChest}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Staked Koson Batches'}
          value={(
            context.nftState?.stakedKosonBatches.length || 0
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          icon={faCoins}
        />
      </div>
    </>
  );
};
export default InventoryCardInfo;
