import {
  faHSquare,
  faLineChart,
  faLock,
  faMoneyBill1Wave,
  faPercent,
  faPercentage
} from '@fortawesome/free-solid-svg-icons';
import { faVault } from '@fortawesome/pro-regular-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import {
  kosonStakingPoolNames,
  KosonStakingStartTime,
  toLocaleStringOptions
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import {
  getTimeUntil,
  getTimeUntilFull,
  getTimeUntilShort
} from 'contexts/Web3Context/helpers/generalUtils';
import { PoolInfo } from 'hooks/useKosonStakingPoolInfo';
import React from 'react';
import { AccountNft } from 'types';
import StakedBatchesContainer from './StakedBatchesContainer';

const TabContent = ({
  index,
  kosonStakingIcon,
  poolInfo,
  nfts
}: {
  index: number;
  kosonStakingIcon: any;
  poolInfo?: PoolInfo;
  nfts: AccountNft[];
}) => {
  const getTotalPendingRewards = () => {
    let pendingRewardsSum = 0;
    for (let i = 0; i < nfts.length; i++) {
      pendingRewardsSum += nfts[i].pendingReward || 0;
    }
    return pendingRewardsSum;
  };

  const [poolName, _] = React.useState(kosonStakingPoolNames[index]);
  const [pendingRewards, setPendingRewards] = React.useState<number>(
    getTotalPendingRewards()
  );
  const [firstClaimableTime, setFirstClaimableTime] = React.useState('');
  const { nftState } = React.useContext(Web3Context);

  React.useEffect(() => {
    const pendingRewardsSum = getTotalPendingRewards();
    setPendingRewards(pendingRewardsSum);
    const daysUntilClaim = nfts.map((n) =>
      n.daysUntilClaimDay === undefined ? 4 * 365 : n.daysUntilClaimDay
    );
    if (nfts.length !== 0) {
      const earliestToClaim = Math.min(...daysUntilClaim);
      if (earliestToClaim === 0) {
        setFirstClaimableTime('Claimable today');
      } else if (earliestToClaim < 0) {
        setFirstClaimableTime('Claimable now');
      } else {
        const claimTimestamp =
          earliestToClaim * 24 * 3600 + Math.floor(new Date().getTime() / 1000);
        const timeTillClaim = getTimeUntilShort(claimTimestamp);
        setFirstClaimableTime(`Claimable in > ${timeTillClaim}`);
      }
    }
  }, [nftState?.stakedKosonBatches]);

  return (
    <div
      className={`tab-pane fade show ${index === 0 ? 'active' : ''}`}
      id={poolName}
      role='tabpanel'
      key={`koson-staking-tab-content-inner-key-${index}`}
    >
      <div className='pt-4'>
        <div className='d-flex align-content-center mb-2'>
          {kosonStakingIcon}
          <h1 className='mt-2 ms-3'>{poolName} staking pool</h1>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <SimpleStatsCard
              title={'KOSON staked in this pool'}
              value={(poolInfo?.totalStakedByUser || 0).toLocaleString(
                undefined,
                toLocaleStringOptions
              )}
              icon={faVault}
              showKosonSymbol
            />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <SimpleStatsCard
              title={'Pending Rewards'}
              value={pendingRewards.toLocaleString(
                undefined,
                toLocaleStringOptions
              )}
              icon={faLineChart}
              showKosonSymbol
              badgeText={
                firstClaimableTime !== '' ? firstClaimableTime : undefined
              }
            />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <SimpleStatsCard
              title={'Earn rate'}
              value={`~${poolInfo?.apy.toLocaleString(
                undefined,
                toLocaleStringOptions
              )}% APY`}
              icon={faPercent}
              badgeText={`~${poolInfo?.apr.toLocaleString(
                undefined,
                toLocaleStringOptions
              )}% APR compounded daily`}
            />
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12'>
            <SimpleStatsCard
              title={'Lockup timeframe'}
              value={poolInfo?.lockupTimeframe || 'N/A'}
              icon={faLock}
            />
          </div>
        </div>
      </div>
      <StakedBatchesContainer
        nfts={nfts}
        icon={kosonStakingIcon}
        poolInfo={poolInfo}
      />
    </div>
  );
};

export default TabContent;
