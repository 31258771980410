import React from 'react';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';

export const ListingAttributes = ({
  listing
}: {
  listing: MarketplaceListingV2;
}) => {
  return (
    <div className='attribute-wrapper'>
      <div className='attribute'>
        <div className='value'>{listing.regularSummoningCount}/6</div>
        <div className='key'>Souls summoning</div>
      </div>
      {listing.isOrigin && (
        <>
          <div className='attribute'>
            <div className='value'>{listing.specialSummoningCount ?? -1}/1</div>
            <div className='key'>Death soul summoning</div>
          </div>
          <div className='attribute'>
            <div className='value'>{listing.specialAbility}</div>
            <div className='key'>Special ability</div>
          </div>
        </>
      )}
    </div>
  );
};
