import React, { useState } from 'react';
import './style.css';

export const InteractiveMap = () => {
  return (
    <div className='row'>
      <div className='col-12 w-100 p-0'>
        <div className='w-100 text-center'>
          <h2>
            <b>The Dacian Kingdom</b>
          </h2>
        </div>
        <div className='map-container'>
          <iframe
            id='map-frame'
            src={`${document.location}box/index.html`}
            width='100%'
            height='100%'
          ></iframe>
        </div>
      </div>
    </div>
  );
};
