import React, { useState } from 'react';
import { OpenChestTypeModal } from './OpenChestTypeModal';
import './style.css';

export const ThumbnailCard = ({
  chest,
  idx,
  handleOpening
}: {
  chest: any;
  idx: number;
  handleOpening: (quantity: number, nonce?: number) => Promise<void>;
}) => {
  const [isActive] = useState(chest.amount > 0);
  const [imgChestName] = useState(chest.name.split(' ')[0]);
  return (
    <div
      className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-self-center'
      key={`open_land_chests_container_${idx}`}
    >
      <div
        className='opening-card card'
        data-bs-toggle='modal'
        data-bs-target={`#openLandChestsModal${chest.nonce}`}
      >
        <div className='message'>OPEN</div>
        <div className='card-body'>
          <img
            src={`images/aoz/landChests/Button_${imgChestName}${
              isActive ? '_Light' : ''
            }.png`}
            style={{ width: 'auto', maxWidth: '100%' }}
          />
          <p className='w-100 text-center'>
            <strong>{chest.amount}</strong> {chest.name}
            {chest.amount > 1 ? 's' : ''} to open
          </p>
        </div>
        <div className='modal fade' id={`openLandChestsModal${chest.nonce}`}>
          <OpenChestTypeModal chest={chest} handleOpenAction={handleOpening} />
        </div>
      </div>
    </div>
  );
};
