import {
  getAccountSmartContracts,
  getSmartContractOwnerAddress
} from 'api/apiRequests';
import { contractAddress, SMARTCONTRACT_ADDRESSES } from 'config';
import React from 'react';

const ChangeContractOwnershipContent = ({ address }: { address: string }) => {
  const mapping: { [key: string]: string } = {
    erd1qqqqqqqqqqqqqpgqrg2yady60jezk3zpks0hr4uzq9cslt4jyl5s2rdn3h:
      'Koson Staking: Plebea SC',
    erd1qqqqqqqqqqqqqpgqgts4dfjyh28p07zjye326kwdqq58wsasyl5s2duk0j:
      'Koson Staking: Comati SC',
    erd1qqqqqqqqqqqqqpgqt2czgzjm4j7enrer9uqxyd0tdvkkqaw2yl5sdpmuk9:
      'Koson Staking: Pileati SC',
    erd1qqqqqqqqqqqqqpgqvlnjqtqnxpyt87qgczgj5tc08n3007aqyl5sp726e9:
      'Koson Staking: Tarabostes SC',
    erd1qqqqqqqqqqqqqpgqk05lklxqd679zf30qfc90wl3ahcf4s0myl5slvw9xz:
      'Koson Staking: Strategos SC',
    erd1qqqqqqqqqqqqqpgqhp5ex0at4e8fy2gen0vl2a8k6ln7f325yl5sk3yzk8:
      'Koson Staking: Basileus SC',
    erd1qqqqqqqqqqqqqpgqe5nrv2wpcq8jrc307klfhpg7xxc2xszqlwfqymjaxd:
      'Vesting SC',
    erd1qqqqqqqqqqqqqpgqv9pzdh94lnr2s9aha3zlf4yxmjuhjwhtyl5squ2whs:
      'Minting SC',
    erd1qqqqqqqqqqqqqpgqfz8nmyy6gfvn48hpffnlnmulnnuzx25cyl5s9w8m2v:
      'Marketplace SC',
    erd1qqqqqqqqqqqqqpgqz5jh90tx3d45rjxd8yqkmgt69dqp5ckeyl5s34wdmf:
      'NFT Staking SC',
    erd1qqqqqqqqqqqqqpgqkv9uee67h5rq6604zfczdmupvtgqymkeyl5sy6lkvh:
      'NFT Swaps SC',
    erd1qqqqqqqqqqqqqpgqcuws9ujvmp984x53y3f4dg7h66axualuyl5sxdhdxw:
      'Summoning SC',
    erd1qqqqqqqqqqqqqpgq68rc5hdt9ssnzvufe056sc5nxelqygjtyl5s066emh:
      'Land Chest Sale SC',
    erd1qqqqqqqqqqqqqpgqyg86jc0u9tuxyn49fzcyygjtpcqjmzajyl5sep0a4g:
      'Land Chest Staking SC'
  };
  const [ownedSmartContracts, setOwnedSmartContracts] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    loadSmartContractInfo().then((res) => {
      // do nothing
    });
  }, []);

  const loadSmartContractInfo = async () => {
    const ownedScs = [];
    for (let i = 0; i < SMARTCONTRACT_ADDRESSES.length; i++) {
      const contractOwner = await getSmartContractOwnerAddress(
        SMARTCONTRACT_ADDRESSES[i]
      );
      if (contractOwner.data === address) {
        ownedScs.push(SMARTCONTRACT_ADDRESSES[i]);
      }
    }
    setOwnedSmartContracts(ownedScs);
  };

  return (
    <div className='row'>
      {SMARTCONTRACT_ADDRESSES.map((sc, i) => (
        <div
          className='col-lg-12 col-sm-12 col-md-12 d-flex justify-content-between mt-2 border-bottom p-2'
          key={`contract-management-admin-${i}`}
        >
          <a
            href={`https://explorer.elrond.com/accounts/${sc}`}
            target='_blank'
            rel='noreferrer'
          >
            {mapping[sc]}
          </a>
          <div>
            {ownedSmartContracts.includes(sc) && (
              <button className='btn btn-primary me-3'>Change ownership</button>
            )}
            {!ownedSmartContracts.includes(sc) && (
              <strong>Don&apos;t have ownership on this SC</strong>
            )}
            {/* <button className='btn btn-primary'>Claim royalties</button> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChangeContractOwnershipContent;
