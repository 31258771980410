import React from 'react';
import { Web3Context } from 'contexts/Web3Context';
import { nftStakingContractAddress } from 'config';
import Activity from 'components/Activity';
import { AccountNft, ACTIVITY_INTERACTION_TYPES } from 'types';
import ActionAlert from 'components/Alerts/ActionAlert';
import NftStakingCardInfo from './CardInfo';
import NftStaking from 'components/Containers/NftStaking';
import {
  strNestedEncode,
  bigUint2hex,
  padBeforeString,
  int2hex,
  addr2Hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import Loading from 'components/Loading';
import { BigUIntValue } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useSelector } from 'react-redux';
import { getSoulNftStakingInfo } from 'store/slices/accountInfo/selectors/getNftStakingInfo';
import { RootState } from 'store';

const SoulNftStaking = () => {
  const { stake, availableNfts, pendingRewards, status } = useSelector(
    getSoulNftStakingInfo
  );

  const stakingData = useSelector((state: RootState) => {
    const d = state.generalStats.stakedAssets.data;
    return {
      totalStakedSouls: d?.stakedSoulsCount,
      rewardPerNft: d?.soulStakingRewardPerNft
    };
  });

  const context = React.useContext(Web3Context);
  const { address } = useGetAccountInfo();

  const handleClaimRewards = async () => {
    if (context.nftStakingState === undefined) {
      return;
    }
    console.log(context.nftStakingState.stakedNfts);
    const daysToIterate = Math.ceil(
      context.nftStakingState.pendingRewards /
        context.nftStakingState.totalDailyReward
    );
    await context.sendTransaction(
      nftStakingContractAddress,
      35_000_000 + daysToIterate * 300_000,
      'claimReward'
    );
  };
  const handleUnstake = async (
    nftsToUnstake: AccountNft[],
    _: { [identifier: string]: number }
  ) => {
    let nfts = nftsToUnstake;
    if (nfts.length === 0) {
      nfts = context.nftStakingState?.stakedNfts || [];
    }
    if (nfts.length === 0) {
      window.alert('No NFTs selected for unstaking');
      return;
    }
    let data = 'unstakeNfts';
    for (let i = 0; i < nfts.length; i++) {
      const collection = strNestedEncode(nfts[i].collection);
      const qty = bigUint2hex(new BigUIntValue(1));
      const nonce = padBeforeString(int2hex(nfts[i].nonce), 16);
      const nftPayload = `@${collection}${nonce}${qty}`;
      data += nftPayload;
    }
    const gasLimit = 25_000_000 + nfts.length * 8_000_000;

    await context.sendTransaction(nftStakingContractAddress, gasLimit, data);
  };
  const handleClaimUnbonded = async () => {
    if (!context.nftStakingState?.hasClaimableUnbondingNfts) {
      window.alert('No unbonded NFTs ready to claim');
      return;
    }
    const pendingNfts = context.nftStakingState?.unbondingNfts.filter(
      (nft) => nft.canBeClaimed
    ).length;
    const gasLimit = 25_000_000 + 5_000_000 * pendingNfts;
    await context.sendTransaction(
      nftStakingContractAddress,
      gasLimit,
      'claimUnstake'
    );
  };

  const handleStakeNfts = async (
    nfts: AccountNft[],
    _: { [identifier: string]: number }
  ) => {
    let data = `MultiESDTNFTTransfer@${addr2Hex(
      nftStakingContractAddress
    )}@${int2hex(nfts.length)}`;

    for (let i = 0; i < nfts.length; i++) {
      const token = str2hex(nfts[i].collection);
      const nonce = int2hex(nfts[i].nonce);
      const qty = '01';
      data += `@${token}@${nonce}@${qty}`;
    }
    data += `@${str2hex('stakeNfts')}`;

    await context.sendTransaction(
      address,
      35_000_000 + nfts.length * 10_000_000,
      data
    );
  };

  return status === 'loading' ? (
    <Loading coverFullPage={true} />
  ) : (
    <>
      {(pendingRewards || 0) > 0 && (
        <ActionAlert
          alertText='You have pending rewards.'
          actionText='Claim now!'
          action={handleClaimRewards}
        />
      )}
      <div className='row'>
        <NftStakingCardInfo
          pendingRewards={pendingRewards}
          totalDailyRewardPerNft={stakingData.rewardPerNft ?? 0}
          totalStakedSouls={stakingData.totalStakedSouls ?? 0}
          totalUnbondingSouls={stake?.unbondingNfts.length ?? 0}
          totalStakedSoulsByUser={stake?.stakedNfts.length ?? 0}
        />
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <NftStaking
            showFilter
            headerText='Staked Soul NFTs'
            nothingStakedMessage="You haven't staked any soul yet"
            stakeMoreNftsHeaderMessage='Stake Soul NFTs'
            stakeButtonDisabled={availableNfts.length === 0}
            stakedNfts={(stake?.unbondingNfts ?? []).concat(
              stake?.stakedNfts ?? []
            )}
            availableNfts={availableNfts ?? []}
            canClaimUnbonded={
              ((stake?.unbondingNfts || []).length > 0 &&
                stake?.canClaimUnbondedNfts) ??
              false
            }
            handleStakeNfts={handleStakeNfts}
            handleUnstake={handleUnstake}
            handleClaimUnbonded={handleClaimUnbonded}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <Activity interaction={ACTIVITY_INTERACTION_TYPES.NftStaking} />
        </div>
      </div>
    </>
  );
};

export default SoulNftStaking;
