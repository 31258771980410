import MarketplaceFilterModalContent, {
  MarketplaceNFTFilters,
  _defaultFilters
} from 'components/Modals/MarketplaceFilterModalContent';
import MarketplaceListingThumbnail from 'components/NFT/MarketplaceListingThumbnail';
import { OriginNFTCollections, SummonedNFTCollections } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import {
  extractTimeDifference,
  getTimeUntilShort
} from 'contexts/Web3Context/helpers/generalUtils';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';
import './style.css';

const AllMarketplaceListings = ({
  listings,
  hasLimit
}: {
  listings: MarketplaceListingV2[];
  hasLimit: boolean;
}) => {
  const [displayedListings, setDisplayedListings] = React.useState<
    MarketplaceListingV2[]
  >(listings.filter((l) => !l.hasExpired));
  const [currentFilters, setCurrentFilters] =
    useState<MarketplaceNFTFilters>(_defaultFilters);
  React.useEffect(() => {
    if (displayedListings.length === 0) {
      setDisplayedListings(listings.filter((l) => !l.hasExpired));
    }
  }, [listings]);

  const applyFilters = (filters: MarketplaceNFTFilters) => {
    setCurrentFilters(filters);
    setDisplayedListings(
      listings.filter((nft) => shouldDisplayNftFilter(filters, nft))
    );
  };

  const shouldDisplayNftFilter = (
    filters: MarketplaceNFTFilters,
    listing: MarketplaceListingV2
  ) => {
    let shouldDisplay = true;
    // prettier-ignore
    {
      const timeDiffSeconds = listing.endTime - Math.floor(new Date().getTime() / 1000);
      const timeDiffObj = extractTimeDifference(timeDiffSeconds);
      const conditions = [
        filters.showOrigin || !(OriginNFTCollections.includes(listing.collection)),
        filters.showSummoned || !(SummonedNFTCollections.includes(listing.collection)),
        filters.showEarth || !(listing.collection.includes('EARTH')),
        filters.showFire || !(listing.collection.includes('FIRE')),
        filters.showAether || !(listing.collection.includes('AETH')),
        filters.showLife || !(listing.collection.includes('LIFE')),
        filters.showAir || !(listing.collection.includes('AIR')),
        filters.showWater || !(listing.collection.includes('WATER')),
        filters.showDeath || !(listing.collection.includes('DEATH')),
        filters.showExpired || !(listing.hasExpired),
        filters.showExpiringLess1D || (timeDiffObj.days === 0 && timeDiffObj.months === 0 && timeDiffObj.weeks === 0),
        filters.showExpiringLess1W || ((timeDiffObj.days ?? 0) > 0 && timeDiffObj.months === 0 && timeDiffObj.weeks === 0),
        filters.showExpiringLess1M || ((timeDiffObj.weeks ?? 0) > 0 && timeDiffObj.months === 0),
      ];
      for (let i = 0; i < conditions.length && shouldDisplay; i++) {
        shouldDisplay = shouldDisplay && conditions[i];
      }
    }

    return shouldDisplay;
  };

  const handleQuickFilters = (type: 'origin' | 'summoned' | 'death') => {
    switch (type) {
      case 'origin':
        applyFilters(showOriginSouls);
        break;
      case 'summoned':
        applyFilters(showSummonedSouls);
        break;
      case 'death':
        applyFilters(showDeathSouls);
        break;
    }
  };

  const fullSizeFilters = () => (
    <div className={'card-header text-between'}>
      {/* <h5 className='card-title'>🔥 Latest Soul Listings</h5> */}
      <div>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('origin')}
        >
          Origin souls
        </button>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('summoned')}
        >
          Summoned souls
        </button>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('death')}
        >
          Death souls
        </button>
      </div>
      {/* <div>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('auctions')}
        >
          Auctions
        </button>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('buyouts')}
        >
          Buyouts
        </button>
      </div> */}
      <button
        className='btn btn-outline-primary text-white mt-2'
        style={{ minWidth: '175px' }}
        data-bs-toggle='modal'
        data-bs-target='#fsMarketplaceListingsFiltersModal'
      >
        More filters
      </button>
      <div className='modal fade' id='fsMarketplaceListingsFiltersModal'>
        <MarketplaceFilterModalContent
          onApply={applyFilters}
          defaultFilters={currentFilters}
        />
      </div>
    </div>
  );

  const smallSizeFilters = () => (
    <div className={'card-header d-flex justify-content-center'}>
      {/* <h5 className='card-title'>🔥 Latest Soul Listings</h5> */}
      <button
        className='btn btn-outline-primary text-white mt-2'
        style={{ minWidth: '175px' }}
        data-bs-toggle='modal'
        data-bs-target='#ssMarketplaceListingsFiltersModal'
      >
        Filters
      </button>
      <div className='modal fade' id='ssMarketplaceListingsFiltersModal'>
        <MarketplaceFilterModalContent
          onApply={applyFilters}
          defaultFilters={currentFilters}
        />
      </div>
    </div>
  );

  const handleShowExpiredListings = () => {
    const newFilters = currentFilters;
    newFilters.showExpired = true;
    applyFilters(newFilters);
  };

  return (
    <div
      className='card text-center'
      id='card-title-3'
      style={{ maxHeight: '60%' }}
    >
      <div className={'card-header filter-box'}>
        {/* <h5 className='card-title'>🔥 Latest Soul Listings</h5> */}
        <div className='quick-filters'>
          <button
            className='btn btn-outline-primary text-white me-2 mt-2'
            onClick={() => handleQuickFilters('origin')}
          >
            Origin souls
          </button>
          <button
            className='btn btn-outline-primary text-white me-2 mt-2'
            onClick={() => handleQuickFilters('summoned')}
          >
            Summoned souls
          </button>
          <button
            className='btn btn-outline-primary text-white me-2 mt-2'
            onClick={() => handleQuickFilters('death')}
          >
            Death souls
          </button>
        </div>
        {/* <div>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('auctions')}
        >
          Auctions
        </button>
        <button
          className='btn btn-outline-primary text-white me-2 mt-2'
          onClick={() => handleQuickFilters('buyouts')}
        >
          Buyouts
        </button>
      </div> */}
        <button
          className='btn btn-outline-primary text-white mt-2'
          style={{ minWidth: '175px' }}
          data-bs-toggle='modal'
          data-bs-target='#fsMarketplaceListingsFiltersModal'
        >
          More filters
        </button>
        <div className='modal fade' id='fsMarketplaceListingsFiltersModal'>
          <MarketplaceFilterModalContent
            onApply={applyFilters}
            defaultFilters={currentFilters}
          />
        </div>
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          {displayedListings.length > 0 && (
            <>
              {displayedListings.map((nft, i) => {
                return (
                  <MarketplaceListingThumbnail
                    nft={nft}
                    key={`nft-staking-key-${i}`}
                  />
                );
              })}
              {!currentFilters.showExpired && (
                <div className='col-12 text-center text-gray'>
                  There are {listings.filter((l) => l.hasExpired).length} hidden
                  expired listings.
                  <div onClick={handleShowExpiredListings} role='button'>
                    Click here to show them.
                  </div>
                </div>
              )}
            </>
          )}

          {displayedListings.length === 0 && listings.length > 0 && (
            <div className='col-lg-12 text-center p-5'>
              <h4>
                <strong>No listings corresponding to these filters</strong>
              </h4>
            </div>
          )}
          {displayedListings.length === 0 && listings.length === 0 && (
            <div className='col-lg-12 text-center p-5'>
              <h4>
                <strong>There are currently no listings available</strong>
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AllMarketplaceListings;

const showOriginSouls: MarketplaceNFTFilters = {
  showOrigin: true,
  showEarth: true,
  showFire: true,
  showAether: true,
  showLife: true,
  showAir: true,
  showWater: true,
  showCommonSummoningCounts: [0, 1, 2, 3, 4, 5, 6],
  showSpecialSummoningCounts: [0, 1],
  showExpired: false,
  showExpiringLess1D: true,
  showExpiringLess1W: true,
  showExpiringLess1M: true,
  showSummoned: false,
  showDeath: false
};

const showSummonedSouls: MarketplaceNFTFilters = {
  showSummoned: true,
  showEarth: true,
  showFire: true,
  showAether: true,
  showLife: true,
  showAir: true,
  showWater: true,
  showCommonSummoningCounts: [0, 1, 2, 3, 4, 5, 6],
  showSpecialSummoningCounts: [0, 1],
  showExpired: false,
  showExpiringLess1D: true,
  showExpiringLess1W: true,
  showExpiringLess1M: true,
  showOrigin: false,
  showDeath: false
};

const showDeathSouls: MarketplaceNFTFilters = {
  showSummoned: true,
  showDeath: true,
  showCommonSummoningCounts: [0, 1, 2, 3, 4, 5, 6],
  showSpecialSummoningCounts: [0, 1],
  showExpired: false,
  showExpiringLess1D: true,
  showExpiringLess1W: true,
  showExpiringLess1M: true,
  showOrigin: false,
  showEarth: false,
  showFire: false,
  showAether: false,
  showLife: false,
  showAir: false,
  showWater: false
};
