// slices/accountInfo/smartContractStats.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface SmartContractStatsData {
  // your state fields
  nft: number;
  land: number;
  soulStakingAllocation: number;
  landStakingAllocation: number;
}

interface SmartContractStatsState {
  data: SmartContractStatsData[] | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SmartContractStatsState = {
  data: [],
  status: 'idle',
  error: null
};

export const fetchSmartContractStats = createAsyncThunk(
  'smartContractStats/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const data: SmartContractStatsData[] = []; // Initialize with empty array
      return data;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const smartContractStatsSlice = createSlice({
  name: 'smartContractStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSmartContractStats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSmartContractStats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSmartContractStats.rejected, (state, action: any) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default smartContractStatsSlice.reducer;
