import React, { useState } from 'react';
import { AccountNft } from 'types';
import {
  ImageAssetThumbnailCardHeader,
  VideoAssetThumbnailCardHeader
} from '../ThumbnailCardHeader';
import './style.css';

export const InventoryThumbnail = ({ nft }: { nft: AccountNft }) => {
  const [isVideo] = useState(nft.videoResourceUrl !== '');

  return (
    <div className='col-12 col-xl-2 col-lg-4 col-md-6 col-sm12'>
      <div className='card bg-transparent border-0 inventory-item p-relative'>
        {isVideo ? (
          <VideoAssetThumbnailCardHeader
            assetName={nft.name}
            videoUrl={nft.videoResourceUrl || ''}
            assetNonce={nft.nonce}
            quantity={!Number.isNaN(nft.amount) ? nft.amount : undefined}
          />
        ) : (
          <ImageAssetThumbnailCardHeader
            assetName={nft.name}
            assetNonce={nft.nonce}
            thumbnailUrl={nft.thumbnailUrl}
            summoningInfo={{
              regularSummons: nft.regularSummoningCount ?? 0,
              specialSummons: nft.specialSummoningCount
            }}
            quantity={!Number.isNaN(nft.amount) ? nft.amount : undefined}
          />
        )}
        {nft.isSoul && (
          <div className='actions'>
            <button className='btn btn-primary'>List for sale</button>
          </div>
        )}
      </div>
    </div>
  );
};
