// slices/accountInfo/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import stakingRewardsReducer from './stakingRewards';
import stakedAssetsReducer from './stakedAssets';
import tokenAllocationReducer from './tokenAllocation';
import tokenStatsReducer from './tokenStats';
import smartContractStatsReducer from './smartContractStats';
// import other reducers

const generalStatsReducer = combineReducers({
  // stakingRewards: stakingRewardsReducer,
  stakedAssets: stakedAssetsReducer,
  tokenAllocation: tokenAllocationReducer,
  tokenStats: tokenStatsReducer,
  smartContractStats: smartContractStatsReducer
  // other subfeatures
});

export default generalStatsReducer;
