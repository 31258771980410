import NFTStakingCard from 'components/Cards/NFTStakingCard';
import StatsCard from 'components/Cards/ColoredStatsCard';
import React from 'react';
import EarnCard from './EarnCard';
import { routeNames } from 'routes';
import ReactTooltip from 'react-tooltip';
import { Web3Context } from 'contexts/Web3Context';
import { toLocaleStringOptions } from 'config';
import {
  faCoins,
  faGhost,
  faTreasureChest
} from '@fortawesome/pro-solid-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';

const Earn = () => {
  const context = React.useContext(Web3Context);
  const [landChestStakingRewardMsg, setLandChestStakingRewardMsg] =
    React.useState('');

  React.useEffect(() => {
    setLandChestStakingRewardMsg(getLandChestStakingMessage());
  }, [context.stakedLandChestsState]);

  const getLandChestStakingMessage = () => {
    if ((context.stakedLandChestsState?.dailyRewards || []).length === 4) {
      return `Earn up to ${context.stakedLandChestsState?.dailyRewards[3].toLocaleString(
        undefined,
        toLocaleStringOptions
      )} $KOSON per land chest/plot on a daily basis`;
    } else {
      return '';
    }
  };

  return (
    <div className='row'>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <EarnCard
          title='Soul staking'
          titleIcon={faGhost}
          navRoute={routeNames.nftStaking}
          description={
            'Lock your Soul NFTs and get a share of the daily $KOSON reward.'
          }
          backgroundImageUrl={'images/aoz/death.png'}
          rewardMessage={`Earn ~${context.nftStakingState?.singleNftDailyReward.toLocaleString(
            undefined,
            toLocaleStringOptions
          )} $KOSON per NFT on a daily basis`}
        />
      </div>
      <div className='col-lg-6 col-md-12 col-sm-12'>
        <EarnCard
          title='Land chest & plot staking'
          titleIcon={faTreasureChest}
          navRoute={routeNames.landchestStaking}
          description={
            'Earn a share of the daily $KOSON reward by staking your Land Chests and Land Plots'
          }
          backgroundImageUrl={
            // 'images/aoz/landPlots/combinedcard-land-chests.png'
            'images/aoz/landPlots/Alpine.png'
          }
          rewardMessage={landChestStakingRewardMsg}
        />
      </div>
      <div
        className='col-lg-12 col-md-12 col-sm-12'
        style={{ minHeight: '250px' }}
      >
        <EarnCard
          title='Koson staking'
          titleIcon={faCoins}
          navRoute={routeNames.kosonStaking}
          description={
            'Lock your $KOSON tokens for different time frames and be rewarded with different APYs'
          }
          backgroundImageUrl={'images/aoz/money.jpg'}
          rewardMessage='Earn up to 138.47% APY and, if staking in the Basileus pool, a share of the $1M prize awarded on game launch'
        />
      </div>
    </div>
  );
};

export default Earn;
