import React from 'react';
import BigNumber from 'bignumber.js';
import {
  EVENT_SETTINGS,
  KOSON_TICKER,
  summoningContractAddress,
  toLocaleStringOptions
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import { int2hex, str2hex } from 'contexts/Web3Context/helpers/generalUtils';

const PendingPaymentContainer = ({
  isKosonOffering,
  stepNo,
  expectedPayment,
  summoningId
}: {
  isKosonOffering: boolean;
  stepNo: number;
  expectedPayment: BigNumber;
  summoningId: number;
}) => {
  const { sendTransaction } = React.useContext(Web3Context);
  const getText = () => {
    if (stepNo === 2) {
      return 'Step 2: Deposit $KOSON offering';
    }
    if (stepNo === 3) {
      return 'Step 3: Deposit fee';
    }
  };

  const handleDeposit = async () => {
    if (isKosonOffering) {
      let hex = expectedPayment.toString(16);
      if (hex.length % 2 === 1) {
        hex = '0' + hex;
      }
      const data = `ESDTTransfer@${str2hex(KOSON_TICKER)}@${hex}@${str2hex(
        EVENT_SETTINGS.bendisCall ? 'fulfillBendisCall' : 'depositOffering'
      )}@${int2hex(summoningId)}`;
      await sendTransaction(summoningContractAddress, 25_000_000, data);
    } else {
      await sendTransaction(
        summoningContractAddress,
        25_000_000,
        `startSummoning@${int2hex(summoningId)}`,
        expectedPayment
      );
    }
  };

  const handleCancel = async () => {
    await sendTransaction(
      summoningContractAddress,
      35_000_000,
      `cancelSummoning@${int2hex(summoningId)}`
    );
  };

  return (
    <div className='row'>
      <div className='col-lg-12 col-sm-12'>
        <h4>{getText()}</h4>
      </div>
      <div className='col-lg-12 col-sm-12'>
        <h6>
          {expectedPayment
            .div(new BigNumber(10).pow(18))
            .toNumber()
            .toLocaleString(undefined, toLocaleStringOptions)}{' '}
          {isKosonOffering ? '$KOSON' : 'EGLD'}
        </h6>
      </div>
      <div className='col-lg-12 col-sm-12 d-flex justify-content-center mt-5'>
        <button className='btn btn-primary btn-lg' onClick={handleDeposit}>
          {stepNo === 2 ? 'Deposit $KOSON Offering' : 'Summon'}
        </button>
      </div>
      <div className='col-lg-12 col-sm-12 d-flex justify-content-center mt-3'>
        <button className='btn btn-danger' onClick={handleCancel}>
          Cancel summoning
        </button>
      </div>
    </div>
  );
};

export default PendingPaymentContainer;
