import {
  getLandChestNfts,
  getLandPlotNfts,
  getTotalStakedSoulNfts
} from 'api/apiRequests';
import { landChestStakingContractAddress } from 'config';
import { StakedAssetsData } from 'store/slices/generalStats/stakedAssets';
import { NonceAmountPair } from 'types';

export const getStakedAssetsData = async (): Promise<StakedAssetsData> => {
  const totalStakedSouls = await getTotalStakedSoulNfts();
  const totalStakedChests = await loadStakedChests();
  const totalStakedPlots = await loadStakedPlots();

  return {
    stakedSoulsCount: totalStakedSouls.data,
    stakedLandChests: totalStakedChests,
    stakedLandPlots: totalStakedPlots,
    soulStakingRewardPerNft: 0,
    landStakingRewardPerNft: [],
    soulStakingAllocation: 0,
    landStakingAllocation: []
  };
};

const loadStakedChests = async (): Promise<NonceAmountPair[]> => {
  const stakedLandChests = await getLandChestNfts(
    landChestStakingContractAddress
  );
  if (stakedLandChests.success) {
    const stakedSupplies = [];
    for (let i = 0; i < stakedLandChests.data.length; i++) {
      const crt = stakedLandChests.data[i];
      const pair: NonceAmountPair = {
        nonce: crt.nonce,
        amount: parseInt(crt.balance)
      };
      stakedSupplies.push(pair);
    }
    return stakedSupplies;
  }
  return [];
};

const loadStakedPlots = async (): Promise<NonceAmountPair[]> => {
  const stakedPlots = await getLandPlotNfts(landChestStakingContractAddress);
  if (stakedPlots.success) {
    const stakedSupplies = [];
    for (let i = 0; i < stakedPlots.data.length; i++) {
      const crt = stakedPlots.data[i];
      const pair: NonceAmountPair = {
        // 5 - crt.nonce because the staked plots have a descending order based on rarities + the alpine plot which takes the slot 1
        nonce: 5 - crt.nonce,
        amount: parseInt(crt.balance)
      };
      stakedSupplies.push(pair);
    }
    return stakedSupplies;
  }
  return [];
};
