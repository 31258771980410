import { useState } from 'react';
import { AccountNft } from 'types';
import {
  ImageAssetThumbnailCardHeader,
  VideoAssetThumbnailCardHeader
} from '../ThumbnailCardHeader';
import './style.css';

export const StakedAssetThumbnail = ({
  nft,
  handleSelect,
  handleUpdateQuantity,
  customColSize
}: {
  nft: AccountNft;
  handleSelect?: () => void;
  handleUpdateQuantity?: (qty: number) => void;
  customColSize?: string;
}) => {
  const [isVideo] = useState(nft.videoResourceUrl !== '');
  const [isMultiple] = useState(nft.amount > 1);
  const [isSelectable] = useState(nft.ETAUntilClaim === undefined);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const toggleSelect = () => {
    if (!isSelectable) {
      return;
    }
    if (isSelected) {
      setSelectedQuantity(1);
    }
    setIsSelected((prev) => !prev);
    if (handleSelect && !isMultiple) {
      handleSelect();
    }
  };

  const handleQuantityChangeSelector = (step: number) => {
    console.log('qty update', step);
    if (!handleUpdateQuantity) {
      return;
    }
    let newQty = selectedQuantity + step;
    if (newQty < 0) {
      newQty = 0;
    }
    if (newQty > nft.amount) {
      newQty = nft.amount;
      return;
    }
    setSelectedQuantity(newQty);
    handleUpdateQuantity(newQty);
  };

  return (
    <div
      className={customColSize ?? 'col-12 col-xl-2 col-lg-4 col-md-6 col-sm-12'}
    >
      <div className='card bg-transparent staked-item p-relative border-0'>
        <div
          onClick={toggleSelect}
          className={`${isSelectable && 'selectable '} ${
            isSelected && 'selected'
          }`}
        >
          {isVideo ? (
            <VideoAssetThumbnailCardHeader
              assetName={nft.name}
              videoUrl={nft.videoResourceUrl || ''}
              assetNonce={nft.nonce}
              quantity={!Number.isNaN(nft.amount) ? nft.amount : undefined}
            />
          ) : (
            <ImageAssetThumbnailCardHeader
              assetName={nft.name}
              assetNonce={nft.nonce}
              thumbnailUrl={nft.thumbnailUrl}
              summoningInfo={{
                regularSummons: nft.regularSummoningCount ?? 0,
                specialSummons: nft.specialSummoningCount
              }}
              quantity={!Number.isNaN(nft.amount) ? nft.amount : undefined}
            />
          )}
        </div>
        {nft.ETAUntilClaim && (
          <div className='stake-info'>
            {nft.canBeClaimed && 'Claimable now'}
            {!nft.canBeClaimed && `Claimable in ${nft.ETAUntilClaim}`}
          </div>
        )}
        {isSelected && isMultiple && handleUpdateQuantity && (
          <div className='card-body qty-picker'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              Select quantity:
              <div className='d-flex justify-content-center align-items-center mt-3'>
                <button
                  type='button'
                  className='btn btn-rounded btn-danger btn-xs me-2'
                  onClick={() => handleQuantityChangeSelector(-1)}
                >
                  -
                </button>
                {selectedQuantity}/{nft.amount}
                <button
                  type='button'
                  className='btn btn-rounded btn-success btn-xs ms-2'
                  onClick={() => handleQuantityChangeSelector(+1)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
