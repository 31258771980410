import React, { useState } from 'react';
import { routeNames } from 'routes';
import { Web3Context } from 'contexts/Web3Context';
import { ConnectPageWrapper } from './ConnectPageWrapper';
import { SelectChainCard } from './SelectChainCard';
import './style.css';

export const UnlockRoute: () => JSX.Element = () => {
  const { accountState } = React.useContext(Web3Context);
  const isLoggedIn = Boolean(accountState?.account.address);
  const [selectedChain, setSelectedChain] = useState<ChainType>();

  return isLoggedIn && window.location.pathname === routeNames.home ? (
    <div className='row'>
      <div className='col-lg-12 col-sm-12 text-center'>
        <img
          src='images/aoz/logoaoz.png'
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%'
          }}
        />
      </div>
      <div
        className='col-lg-12 col-sm-12 text-center'
        style={{ marginTop: '50px' }}
      >
        <h2 className='disabled' style={{ color: '#aaafb4' }}>
          Coming soon
        </h2>
      </div>
    </div>
  ) : (
    <>
      {selectedChain === undefined && (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-sm-12 col-md-6'>
              <SelectChainCard chainType='MVX' onSelect={setSelectedChain} />
            </div>
            <div className='col-lg-3 col-sm-12 col-md-6'>
              <SelectChainCard chainType='MNT' onSelect={setSelectedChain} />
            </div>
            <div className='col-lg-3 col-sm-12 col-md-6'>
              <SelectChainCard chainType='IMX' onSelect={setSelectedChain} />
            </div>
          </div>
        </div>
      )}
      <ConnectPageWrapper
        selectedChain={selectedChain}
        onCancel={() => setSelectedChain(undefined)}
      />
    </>
  );
};

export default UnlockRoute;
export type ChainType = 'MVX' | 'IMX' | 'MNT' | undefined;
