import {
  faHourglassStart,
  faMoneyBill,
  faMoneyBillTrendUp,
  faSackDollar
} from '@fortawesome/free-solid-svg-icons';
import { faTreasureChest } from '@fortawesome/pro-regular-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { toLocaleStringOptions, toLocaleStringOptionsNoDecimals } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
const LandChestStakingCardInfo = ({
  stakedLandChestsCount,
  unbondingLandChestsCount
}: {
  stakedLandChestsCount: number;
  unbondingLandChestsCount: number;
}) => {
  const context = React.useContext(Web3Context);

  const stakedLandChestsCard = () => {
    return (
      <SimpleStatsCard
        title={'Staked Land'}
        value={(stakedLandChestsCount || 0).toLocaleString(
          undefined,
          toLocaleStringOptionsNoDecimals
        )}
        icon={faTreasureChest}
      />
    );
  };
  return (
    <>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Claimable rewards'}
          value={(
            context.stakedLandChestsState?.pendingRewards || 0
          ).toLocaleString(undefined, toLocaleStringOptions)}
          icon={faSackDollar}
          showKosonSymbol
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Total daily reward'}
          value={(
            context.stakedLandChestsState?.totalDailyReward || 0
          ).toLocaleString(undefined, toLocaleStringOptions)}
          icon={faMoneyBillTrendUp}
          showKosonSymbol
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        {stakedLandChestsCard()}
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Unbonding Chests/Plots'}
          value={unbondingLandChestsCount.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faHourglassStart}
        />
      </div>
    </>
  );
};
export default LandChestStakingCardInfo;
