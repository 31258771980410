import { InventoryThumbnail } from 'components/NFT/InventoryThumbnail';
import NFTThumbnail from 'components/NFT/NftThumbnail';
import { StakedAssetThumbnail } from 'components/NFT/StakedAssetThumbnail';
import { nftStakingContractAddress } from 'config';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import React from 'react';
import { AccountNft } from 'types';

const StakeNftsModalContent = ({
  nfts,
  customHeaderText,
  handleStakeNfts
}: {
  nfts: AccountNft[];
  customHeaderText?: string;
  handleStakeNfts: (
    nfts: AccountNft[],
    quantities: { [identifier: string]: number }
  ) => Promise<void>;
}) => {
  const [selectedNfts, setSelectedNfts] = React.useState<AccountNft[]>([]);
  const [isAnySelected, setIsAnySelected] = React.useState(false);
  const [selectedNftsQuantities, setSelectedNftsQuantities] = React.useState<{
    [identifier: string]: number;
  }>({});

  const handleSelect = (nft: AccountNft) => {
    const selectedNftIndex = selectedNfts.indexOf(nft);
    const co = selectedNfts;
    if (selectedNftIndex < 0) {
      co.push(nft);
    } else {
      co.splice(selectedNftIndex);
    }
    setIsAnySelected(co.length > 0);
    setSelectedNfts(co);
  };

  const handleStakeAll = async () => {
    const quantities: { [identifier: string]: number } = {};
    for (let i = 0; i < nfts.length; i++) {
      quantities[nfts[i].identifier] = nfts[i].amount;
    }
    await handleStakeNfts(nfts, quantities);
  };

  const handleStakeSelected = async () => {
    await handleStakeNfts(selectedNfts, selectedNftsQuantities);
  };

  const handleUpdateQuantity = (nft: AccountNft, qty: number) => {
    if (!nft.isLandChest && !nft.isTokenNft) {
      return;
    }
    const co = selectedNftsQuantities;
    co[nft.identifier] = qty;
    setSelectedNftsQuantities(co);
  };

  return (
    <div className='modal-dialog modal-lg' style={{ zIndex: '2050!important' }}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{customHeaderText ?? 'Stake Nfts'}</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div
          className='modal-body with-scroll container-fluid'
          style={{ height: '50vh', width: '100%' }}
        >
          <div className='row'>
            {nfts.map((nft, i) => (
              <StakedAssetThumbnail
                nft={nft}
                handleSelect={() => handleSelect(nft)}
                handleUpdateQuantity={(qty) => handleUpdateQuantity(nft, qty)}
                customColSize='col-lg-4 col-sm-6 col-md-6'
              />
            ))}
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          {nfts.length === 0 && (
            <button type='button' className='btn btn-primary disabled'>
              Stake All
            </button>
          )}
          {nfts.length > 0 && (
            <button
              type='button'
              className='btn btn-primary'
              data-bs-dismiss='modal'
              onClick={handleStakeAll}
            >
              Stake All
            </button>
          )}
          {!isAnySelected && (
            <button type='button' className='btn btn-primary disabled'>
              Stake
            </button>
          )}
          {isAnySelected && (
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleStakeSelected}
              data-bs-dismiss='modal'
            >
              Stake
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StakeNftsModalContent;
