import StakeKosonModalContent from 'components/Modals/StakeKosonModalContent';
import { Web3Context } from 'contexts/Web3Context';
import {
  addr2Hex,
  getCurrentStakeDay,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import { PoolInfo } from 'hooks/useKosonStakingPoolInfo';
import React from 'react';
import { AccountNft } from 'types';
import StakedKosonBatch from './StakedKosonBatch';
import BigNumber from 'bignumber.js';

const StakedBatchesContainer = ({
  nfts,
  icon,
  poolInfo
}: {
  nfts: AccountNft[];
  icon: React.FunctionComponent;
  poolInfo?: PoolInfo;
}) => {
  const { accountState, sendTransaction } = React.useContext(Web3Context);
  const GAS_PER_DAY = 125_000;
  const MIN_GAS_PER_TX = 35_000_000;

  const handleClaimRewards = async (nft: AccountNft) => {
    if ((nft.daysUntilClaimDay || 100) >= 0) {
      window.alert('Cannot claim rewards yet for this batch');
      return;
    }
    await handleTransaction(nft, 'claim');
  };

  const handleUnstake = async (nft: AccountNft) => {
    if ((nft.daysUntilClaimDay || 100) > 0) {
      window.alert('Cannot unstake this batch yet');
      return;
    }
    await handleTransaction(nft, 'unstake');
  };

  const handleTransaction = async (nft: AccountNft, method: string) => {
    const gasLimit = getGasLimit(nft);
    const amount = new BigNumber(nft.unparsedAmount);
    let hexAmount = amount.toString(16);
    if (hexAmount.length % 2 === 1) {
      hexAmount = '0' + hexAmount;
    }

    const data = `ESDTNFTTransfer@${str2hex(nft.collection)}@${int2hex(
      nft.nonce
    )}@${hexAmount}@${addr2Hex(poolInfo?.contractAddress || '')}@${str2hex(
      method
    )}`;

    await sendTransaction(accountState?.account.address || '', gasLimit, data);
  };

  const getGasLimit = (nft: AccountNft) => {
    const currentStakeDay = getCurrentStakeDay();
    const gasLimit = (currentStakeDay - (nft.stakeDay || 0)) * GAS_PER_DAY;
    if (gasLimit < MIN_GAS_PER_TX) {
      return MIN_GAS_PER_TX;
    }

    return gasLimit;
  };

  return (
    <div
      className='card text-center'
      id='card-title-3'
      style={{ minHeight: '600px', maxHeight: '600px' }}
    >
      <div className='card-header text-between'>
        <h5 className='card-title'>Staked KOSON batches</h5>
        {(accountState?.kosonBalance || 0) > 0 ? (
          <>
            <button
              className='btn btn-outline-primary text-white'
              style={{ minWidth: '175px' }}
              data-bs-toggle='modal'
              data-bs-target={`#stakeKosonModal${poolInfo?.poolIndex}`}
            >
              Stake more
            </button>
            <div
              className='modal fade'
              id={`stakeKosonModal${poolInfo?.poolIndex}`}
            >
              <StakeKosonModalContent poolInfo={poolInfo} />
            </div>
          </>
        ) : (
          <>
            <button
              className='btn btn-outline-primary text-white disabled'
              style={{ minWidth: '175px' }}
            >
              Stake more
            </button>
          </>
        )}
      </div>
      <div className='card-body with-scroll' style={{ minHeight: '400px' }}>
        {nfts.length > 0 && (
          <div className='row'>
            {nfts.map((b, i) => (
              <StakedKosonBatch
                nft={b}
                key={`staked-koson-batch-item-key-${i}`}
                icon={icon}
                pendingRewards={b.pendingReward ?? 0}
                handleClaim={async () => await handleClaimRewards(b)}
                handleUnstake={async () => await handleUnstake(b)}
              />
            ))}
          </div>
        )}
        {nfts.length === 0 && (
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
              <h4 className='mt-5'>You have no staked $KOSON in this pool</h4>
            </div>
          </div>
        )}
      </div>
      <div className='card-footer'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-12 pt-3 pb-3'>
            <button className='btn btn-primary btn-lg btn-block disabled'>
              Unstake all
            </button>
            <small>Coming soon</small>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 pt-3 pb-3'>
            <button className='btn btn-primary btn-lg btn-block disabled'>
              Merge batches
            </button>
            <small>Coming soon</small>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 pt-3 pb-3'>
            <button className='btn btn-primary btn-lg btn-block disabled'>
              Claim all rewards
            </button>
            <small>Coming soon</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakedBatchesContainer;
