import { ChainType } from '.';
import { MVXConnectPage } from './MVX';

export const ConnectPageWrapper = ({
  selectedChain,
  onCancel
}: {
  selectedChain: ChainType;
  onCancel: () => void;
}) => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        {selectedChain === 'MVX' && (
          <div className='col-lg-12 col-sm-12 text-center'>
            <MVXConnectPage />
          </div>
        )}
        {selectedChain !== 'MVX' && selectedChain && (
          <div className='col-lg-12 col-sm-12 text-center'>
            <h1 className='mb-5'>Coming Soon</h1>
          </div>
        )}
        {selectedChain !== undefined && (
          <div className='col-lg-12 col-sm-12 text-center'>
            <button className='btn btn-outline-primary' onClick={onCancel}>
              Select other chain
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
