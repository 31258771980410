import axios from 'axios';
import { backendApiAddress } from 'config';

const fetchNftSummoningCounts = () =>
  async function getNftSummoningCounts(fullIdentifiers: string[]) {
    if (fullIdentifiers.length === 0) {
      return {
        data: [],
        success: false
      };
    }
    const results: any = {};
    for (let i = 0; i <= fullIdentifiers.length; i += 20) {
      const batch = fullIdentifiers.slice(i, i + 20);
      const batchResults = await fetchNftSummoningCountsBatch()(batch);
      if (!batchResults.success) {
        return {
          data: [],
          success: false
        };
      }
      for (let j = 0; j <= Object.keys(batchResults.data).length; j++) {
        const key = Object.keys(batchResults.data)[j];
        results[key] = batchResults.data[key];
      }
    }

    return {
      data: results,
      success: true
    };
  };

const fetchNftSummoningCountsBatch = () =>
  async function getNftSummoningCounts(fullIdentifiers: string[]) {
    if (fullIdentifiers.length === 0) {
      return {
        data: [],
        success: false
      };
    }
    const requestParams = fullIdentifiers.join(',');
    try {
      const { data } = await axios.get(
        `${backendApiAddress}/nfts/summoning/${requestParams}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

export const getSummoningCounts = fetchNftSummoningCounts();
