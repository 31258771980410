import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const CollapsibleContainer = ({
  title,
  content
}: {
  title: string;
  content: React.ReactNode;
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  return (
    <div className='card'>
      <div
        className='card-header d-flex justify-content-between'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3>{title}</h3>
        {isCollapsed && (
          <FontAwesomeIcon
            icon={faPlusSquare}
            size='2x'
            style={{ cursor: 'pointer' }}
          />
        )}
        {!isCollapsed && (
          <FontAwesomeIcon
            icon={faMinusSquare}
            size='2x'
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
      {!isCollapsed && <div className='card-body'>{content}</div>}
    </div>
  );
};

export default CollapsibleContainer;
