import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Alert = ({
  strongMessage,
  message,
  type
}: {
  strongMessage: string;
  message: string;
  type: string;
}) => {
  const alertIcon: any =
    type === 'success'
      ? faCircleCheck
      : type === 'warning'
      ? faCircleXmark
      : faCircleExclamation;
  return (
    <div
      className={`alert alert-outline-${type} alert-dismissible fade show`}
      style={{ fontSize: '14px' }}
    >
      <button
        type='button'
        className='btn-close'
        data-bs-dismiss='alert'
        aria-label='btn-close'
      ></button>
      <FontAwesomeIcon icon={alertIcon} className='me-2' />
      {strongMessage && <strong>{strongMessage}</strong>} {message}
    </div>
  );
};

export default Alert;
