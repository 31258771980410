// slices/accountInfo/stakedAssets.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStakedAssetsData } from 'api/v2/getStakedAssetsData';
import { getStakingRewards } from 'api/v2/getStakingRewards';
import { NonceAmountPair } from 'types';
import { StakingRewardsData } from './stakingRewards';

interface StakedAssetInfo {
  assetName: string | undefined;
  circulating: number | undefined;
  staked: number | undefined;
}

interface StakedTokenInfo {
  pool: string;
  amount: number;
}

export interface StakedAssetsData {
  // stakedAssets: StakedAssetInfo[];
  // stakedTokens: StakedTokenInfo[];
  stakedSoulsCount: number;
  stakedLandChests: NonceAmountPair[];
  stakedLandPlots: NonceAmountPair[];
  soulStakingRewardPerNft: number;
  landStakingRewardPerNft: NonceAmountPair[];
  soulStakingAllocation: number;
  landStakingAllocation: number[];
}

const defaultStakedAssetData: StakedAssetsData = {
  stakedSoulsCount: 0,
  stakedLandChests: [],
  stakedLandPlots: [],
  soulStakingRewardPerNft: 0,
  landStakingRewardPerNft: [],
  soulStakingAllocation: 0,
  landStakingAllocation: []
};

interface StakedAssetsState {
  data: StakedAssetsData | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: StakedAssetsState = {
  data: defaultStakedAssetData,
  status: 'idle',
  error: null
};

export const fetchStakedAssets = createAsyncThunk(
  'stakedAssets/fetch',
  async (_, { rejectWithValue }) => {
    try {
      // Initialize with empty arrays
      const data = await getStakedAssetsData();
      const stakingRewardsData = await getStakingRewards(
        data.stakedSoulsCount,
        data.stakedLandChests,
        data.stakedLandPlots
      );
      data.soulStakingRewardPerNft = stakingRewardsData.soulStakingRewardPerNft;
      data.landStakingRewardPerNft = stakingRewardsData.landStakingRewardPerNft;
      data.soulStakingAllocation = stakingRewardsData.soulStakingAllocation;
      data.landStakingAllocation = stakingRewardsData.landStakingAllocation;

      return data;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const stakedAssetsSlice = createSlice({
  name: 'stakedAssets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStakedAssets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStakedAssets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchStakedAssets.rejected, (state, action: any) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default stakedAssetsSlice.reducer;
