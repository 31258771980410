import { Address, ArgSerializer, BigUIntValue } from '@multiversx/sdk-core/out';
import { KosonStakingStartTime } from 'config';

const serializer = new ArgSerializer();
const one_minute = 60;
const one_hour = 60 * one_minute;
const one_day = 24 * one_hour;
const one_week = 7 * one_day;
const one_month = 30 * one_day;
const one_year = 365 * one_day;

export const str2hex = (val: string) => {
  return Buffer.from(val).toString('hex');
};

export const int2hex = (val: number) => {
  const str = val.toString(16);
  if (str.length % 2 === 0) return str;
  return '0' + str;
};

export const int2u64hex = (val: number) => {
  const hex = int2hex(val);
  return padBeforeString(hex, 16);
};

export const addr2Hex = (erdAddress: string) => {
  return new Address(erdAddress).hex();
};

export const bigUint2hex = (val: BigUIntValue) => {
  const hexVal = serializer.valuesToString([val]);
  const bytes = hexVal.argumentsString.length / 2;
  const hexBytes = padBeforeString(int2hex(bytes), 8);
  const res = `${hexBytes}${hexVal.argumentsString}`;
  return res;
};

export const padBeforeString = (val: string, expectedLength: number) => {
  let co = val;
  while (co.length < expectedLength) {
    co = '0' + co;
  }
  return co;
};

export const strNestedEncode = (str: string) => {
  const hexVal = str2hex(str);
  const bytes = hexVal.length / 2;
  const hexBytes = padBeforeString(int2hex(bytes), 8);
  const res = `${hexBytes}${hexVal}`;
  return res;
};

export const getTimeUntil = (timestamp: number) => {
  const now = Math.floor(new Date().getTime() / 1000);
  const diff = timestamp - now;
  return formatTimestampDiffMessage(diff);
};

export const getTimeUntilShort = (timestamp: number) => {
  const now = Math.floor(new Date().getTime() / 1000);
  // const diff = timestamp - now;
  // return formatTimestampDiffMessage(diff);
  const timeDifferenceObj = extractTimeDifference(timestamp - now);
  return formatTimestampDifference(
    timeDifferenceObj,
    TimeDifferenceFormat.SHORT
  );
};

export const getTimeUntilFull = (timestamp: number) => {
  const now = Math.floor(new Date().getTime() / 1000);
  // const diff = timestamp - now;
  // return formatTimestampDiffMessageFull(diff);
  const timeDifferenceObj = extractTimeDifference(timestamp - now);
  return formatTimestampDifference(
    timeDifferenceObj,
    TimeDifferenceFormat.FULL
  );
};

export const getTimeSince = (timestamp: number) => {
  const now = Math.floor(new Date().getTime() / 1000);
  // const diff = now - timestamp;
  // return formatTimestampDiffMessage(diff);
  const timeDifferenceObj = extractTimeDifference(now - timestamp);
  return formatTimestampDifference(
    timeDifferenceObj,
    TimeDifferenceFormat.REGULAR
  );
};

const formatTimestampDiffMessage = (diff: number) => {
  if (diff > one_year) {
    const yearsDiff = Math.floor(diff / one_year);
    let msg = `${yearsDiff} year`;
    if (yearsDiff > 1) {
      msg += 's';
    }
    return msg;
  }
  if (diff > one_month) {
    const monthsDiff = Math.floor(diff / one_month);
    let msg = `${monthsDiff} month`;
    if (monthsDiff > 1) {
      msg += 's';
    }
    return msg;
  }
  if (diff > one_week) {
    const weeksDiff = Math.floor(diff / one_week);
    let msg = `${weeksDiff} week`;
    if (weeksDiff > 1) {
      msg += 's';
    }
    return msg;
  }
  if (diff > one_day) {
    const daysDiff = Math.floor(diff / one_day);
    let msg = `${daysDiff} day`;
    if (daysDiff > 1) {
      msg += 's';
    }
    return msg;
  }
  if (diff > one_hour) {
    const hoursDiff = Math.floor(diff / one_hour);
    let msg = `${hoursDiff} hour`;
    if (hoursDiff > 1) {
      msg += 's';
    }
    return msg;
  }
  if (diff > one_minute) {
    const minutesDiff = Math.floor(diff / one_minute);
    let msg = `${minutesDiff} minute`;
    if (minutesDiff > 1) {
      msg += 's';
    }
    return msg;
  }

  return `${diff} seconds`;
};

const formatTimestampDiffMessageFull = (diff: number) => {
  let msg = '';
  if (diff > one_year) {
    const yearsDiff = Math.floor(diff / one_year);
    msg += `${yearsDiff} year`;
    if (yearsDiff > 1) {
      msg += 's';
    }
    diff -= one_year * yearsDiff;
  }
  if (diff > one_month) {
    const monthsDiff = Math.floor(diff / one_month);
    if (msg.length > 0) {
      msg += ', ';
    }
    msg += `${monthsDiff} month`;
    if (monthsDiff > 1) {
      msg += 's';
    }
    diff -= one_month * monthsDiff;
  }
  if (diff > one_week) {
    const weeksDiff = Math.floor(diff / one_week);
    if (msg.length > 0) {
      msg += ', ';
    }
    msg += `${weeksDiff} week`;
    if (weeksDiff > 1) {
      msg += 's';
    }
    diff -= one_week * weeksDiff;
  }
  if (diff > one_day) {
    const daysDiff = Math.floor(diff / one_day);
    if (msg.length > 0) {
      msg += ', ';
    }
    msg += `${daysDiff} day`;
    if (daysDiff > 1) {
      msg += 's';
    }
    diff -= one_day * daysDiff;
  }
  if (diff > one_hour) {
    const hoursDiff = Math.floor(diff / one_hour);
    if (msg.length > 0) {
      msg += ', ';
    }
    msg += `${hoursDiff} hour`;
    if (hoursDiff > 1) {
      msg += 's';
    }
    diff -= one_hour * hoursDiff;
  }
  if (diff > one_minute) {
    const minutesDiff = Math.floor(diff / one_minute);
    if (msg.length > 0) {
      msg += ', ';
    }
    msg += `${minutesDiff} minute`;
    if (minutesDiff > 1) {
      msg += 's';
    }
    diff -= one_minute * minutesDiff;
  }

  if (msg.length > 0) {
    return msg;
  }
  return `${diff} seconds`;
};

const formatTimestampDifference = (
  timeDifference: TimeDifference,
  formattingType: TimeDifferenceFormat
): string => {
  const msg: string[] = [];
  const timeDifferenceArray = [
    timeDifference.years,
    'Y',
    timeDifference.months,
    'M',
    timeDifference.weeks,
    'W',
    timeDifference.days,
    'D',
    timeDifference.hours,
    'H',
    timeDifference.minutes,
    'MIN',
    timeDifference.seconds,
    'S'
  ];
  for (let i = 0; i < timeDifferenceArray.length; i += 2) {
    if (timeDifferenceArray[i] === undefined) {
      continue;
    }
    const currentTimeFrameMessage =
      timeDifferenceArray[i]?.toString() +
      ' ' +
      getFormattedTimestampTimeframe(
        formattingType,
        timeDifferenceArray[i + 1]?.toString() || 'S',
        (timeDifferenceArray[i] || 0) > 0
      );
    if (
      formattingType === TimeDifferenceFormat.SHORT ||
      formattingType === TimeDifferenceFormat.REGULAR
    ) {
      return currentTimeFrameMessage;
    } else {
      msg.push(currentTimeFrameMessage);
    }
  }
  return msg.join(', ');
};

const getFormattedTimestampTimeframe = (
  formattingType: TimeDifferenceFormat,
  type: string,
  isMany: boolean
): string => {
  switch (formattingType) {
    case TimeDifferenceFormat.SHORT:
    case TimeDifferenceFormat.FULL_SHORT:
      return type;
    case TimeDifferenceFormat.REGULAR:
    case TimeDifferenceFormat.FULL:
      let ret = '';
      switch (type) {
        case 'Y':
          ret = 'year';
          break;
        case 'M':
          ret = 'month';
          break;
        case 'W':
          ret = 'week';
          break;
        case 'D':
          ret = 'day';
          break;
        case 'H':
          ret = 'hour';
          break;
        case 'MIN':
          ret = 'minute';
          break;
        case 'S':
          ret = 'second';
          break;
      }
      if (isMany) {
        ret += 's';
      }
      return ret;
  }
};

export const extractTimeDifference = (diff: number): TimeDifference => {
  const timeDifference: TimeDifference = {};
  if (diff > one_year) {
    const yearsDiff = Math.floor(diff / one_year);
    timeDifference.years = yearsDiff;
    diff -= one_year * yearsDiff;
  }
  if (diff > one_month) {
    const monthsDiff = Math.floor(diff / one_month);
    timeDifference.months = monthsDiff;
    diff -= one_month * monthsDiff;
  }
  if (diff > one_week) {
    const weeksDiff = Math.floor(diff / one_week);
    timeDifference.weeks = weeksDiff;
    diff -= one_week * weeksDiff;
  }
  if (diff > one_day) {
    const daysDiff = Math.floor(diff / one_day);
    timeDifference.days = daysDiff;
    diff -= one_day * daysDiff;
  }
  if (diff > one_hour) {
    const hoursDiff = Math.floor(diff / one_hour);
    timeDifference.hours = hoursDiff;
    diff -= one_hour * hoursDiff;
  }
  if (diff > one_minute) {
    const minutesDiff = Math.floor(diff / one_minute);
    timeDifference.minutes = minutesDiff;
    diff -= one_minute * minutesDiff;
  }

  timeDifference.seconds = diff;
  return timeDifference;
};

export const getCurrentStakeDay = () =>
  Math.floor(
    (Math.floor(new Date().getTime() / 1000) - KosonStakingStartTime) /
      (24 * 3600)
  );

interface TimeDifference {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

enum TimeDifferenceFormat {
  SHORT = 0,
  REGULAR = 1,
  FULL = 2,
  FULL_SHORT = 3
}
