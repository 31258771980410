export const CHEST_CONTENT: any = {
  1: {
    title: 'Continental Chest',
    image: 'images/aoz/landChests/Button_Continental.png',
    contentComponent: (
      <>
        <li className='chest-content'>
          <strong className='important-text'>
            1 Plot of Land in Continental Biome
          </strong>
        </li>
        <li className='chest-content'>
          1 Inferior random piece of{' '}
          <strong className='important-text'>armour/weapon</strong>
        </li>
        <li className='chest-content'>
          Extremely low chance of a{' '}
          <strong className='important-text'>Legendary</strong> item or an{' '}
          <strong className='important-text'>Alpine Land Plot</strong>
        </li>
      </>
    )
  },
  2: {
    title: 'Steepe Chest',
    image: 'images/aoz/landChests/Button_Steepe.png',
    contentComponent: (
      <>
        <li className='chest-content'>
          <strong className='important-text'>
            1 Plot of Land in Steepe Biome
          </strong>
        </li>
        <li className='chest-content'>
          1 Average random piece of{' '}
          <strong className='important-text'>armour/weapon</strong>
        </li>
        <li className='chest-content'>
          Very low chance of a{' '}
          <strong className='important-text'>Legendary</strong> item or an{' '}
          <strong className='important-text'>Alpine Land Plot</strong>
        </li>
      </>
    )
  },
  3: {
    title: 'Panonic Chest',
    image: 'images/aoz/landChests/Button_Panonic.png',
    contentComponent: (
      <>
        <li className='chest-content'>
          <strong className='important-text'>
            1 Plot of Land in Panonic Biome
          </strong>
        </li>
        <li className='chest-content'>
          <strong className='important-text'>1 Advanced</strong> random piece of{' '}
          <strong className='important-text'>armour/weapon</strong>
        </li>
        <li className='chest-content'>
          Low chance of a <strong className='important-text'>Legendary</strong>{' '}
          item or an{' '}
          <strong className='important-text'>Alpine Land Plot</strong>
        </li>
      </>
    )
  },
  4: {
    title: 'Pontic Chest',
    image: 'images/aoz/landChests/Button_Pontic.png',
    contentComponent: (
      <>
        <li className='chest-content'>
          <strong className='important-text'>
            1 Plot of Land in Pontic Biome
          </strong>
        </li>
        <li className='chest-content'>
          <strong className='important-text'>1 Superior</strong> random piece of{' '}
          <strong className='important-text'>armour/weapon</strong>
        </li>
        <li className='chest-content'>
          Chance of a <strong className='important-text'>Legendary</strong> item
          or an <strong className='important-text'>Alpine Land Plot</strong>
        </li>
      </>
    )
  }
};
