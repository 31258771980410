import {
  LandChestContentCollections,
  LandChestNFTCollection,
  OriginNFTCollections,
  SoulsNFTCollections,
  StakedKosonCollections,
  SummonedNFTCollections
} from 'config';
import React from 'react';

const InventoryFilterModalContent = ({
  onApply
}: {
  onApply: (identifiers: string[]) => void;
}) => {
  const checkboxComponent = (filterName: string) => {
    return (
      <div className='form-check'>
        <input
          type='checkbox'
          className='form-check-input'
          defaultChecked
          id={`${filterName}-toggle`}
        />
        <label className='form-check-label mt-1'>Show {filterName}</label>
      </div>
    );
  };

  const handleApply = () => {
    let collectionsToDisplay: string[] = SoulsNFTCollections.concat([
      LandChestNFTCollection
    ])
      .concat(StakedKosonCollections)
      .concat(LandChestContentCollections);
    const filterNames = [
      'Origin',
      'Summoned',
      'Earth',
      'Fire',
      'Aether',
      'Water',
      'Life',
      'Air',
      'Death',
      'Souls',
      'Land Chests',
      'Staked Koson',
      'Token NFTs'
    ];
    for (let i = 0; i < filterNames.length; i++) {
      collectionsToDisplay = updateFilterState(
        filterNames[i],
        collectionsToDisplay
      );
    }
    onApply(collectionsToDisplay);
  };

  const updateFilterState = (filterName: string, currentFilters: string[]) => {
    const checkbox = document.getElementById(
      `${filterName}-toggle`
    ) as HTMLInputElement;
    if (checkbox?.checked) {
      return currentFilters;
    }
    switch (filterName) {
      case 'Origin':
        return currentFilters.filter((f) => !OriginNFTCollections.includes(f));
      case 'Summoned':
        return currentFilters.filter(
          (f) => !SummonedNFTCollections.includes(f)
        );
      case 'Souls':
        return currentFilters.filter((f) => !SoulsNFTCollections.includes(f));
      case 'Land Chests':
        return currentFilters.filter((f) => f !== LandChestNFTCollection);
      case 'Staked Koson':
        return currentFilters.filter(
          (f) => !StakedKosonCollections.includes(f)
        );
      case 'Token NFTs':
        return currentFilters.filter(
          (f) => !LandChestContentCollections.includes(f)
        );
      default:
        const itemsToRemove = SoulsNFTCollections.filter((c) =>
          c.startsWith(filterName.toUpperCase())
        );
        return currentFilters.filter((f) => !itemsToRemove.includes(f));
    }
  };

  return (
    <div className='modal-dialog modal-dialog-centered' role='document'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Filters</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
          ></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              <small>Item type</small>
              {checkboxComponent('Souls')}
              {checkboxComponent('Land Chests')}
              {checkboxComponent('Staked Koson')}
              {checkboxComponent('Token NFTs')}
              <br />
              <small>Soul type</small>
              {checkboxComponent('Origin')}
              {checkboxComponent('Summoned')}
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-left'>
              <small>Soul element</small>
              {checkboxComponent('Earth')}
              {checkboxComponent('Fire')}
              {checkboxComponent('Aether')}
              {checkboxComponent('Water')}
              {checkboxComponent('Life')}
              {checkboxComponent('Air')}
              {checkboxComponent('Death')}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-danger light'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() =>
              onApply(
                SoulsNFTCollections.concat([LandChestNFTCollection]).concat(
                  StakedKosonCollections
                )
              )
            }
            data-bs-dismiss='modal'
          >
            Reset
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => handleApply()}
            data-bs-dismiss='modal'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryFilterModalContent;
