import { getEgldPrice, getKosonPrice } from 'api/backendRequests';
import React from 'react';

const usePriceInfo = () => {
  const [egldPrice, setEgldPrice] = React.useState(0);
  const [kosonPrice, setKosonPrice] = React.useState(0);

  React.useEffect(() => {
    /* eslint-disable-next-line no-empty */
    updatePrices();
  }, []);

  const updatePrices = async () => {
    const kosonPrice = await getKosonPrice();
    const egldPrice = await getEgldPrice();
    if (kosonPrice.success) {
      setKosonPrice(kosonPrice.data);
    }
    if (egldPrice.success) {
      setEgldPrice(egldPrice.data);
    }
    //TODO: import this price from xExchange, pair WEGLD-USDC, use their token identifier from the explorer
  };

  return {
    egldPrice,
    kosonPrice,
    refreshState: updatePrices
  };
};

export default usePriceInfo;

export interface IPriceInfoType {
  egldPrice: number;
  kosonPrice: number;
  refreshState: () => Promise<void>;
}
