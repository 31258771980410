import React from 'react';
import Activity from 'components/Activity';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import LandChestStakingCardInfo from './CardInfo';
import { Web3Context } from 'contexts/Web3Context';
import ActionAlert from 'components/Alerts/ActionAlert';
import { landChestStakingContractAddress } from 'config';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import Loading from 'components/Loading';
import { LandChestStakingContainer } from './LandChestStakingContainer';
import { LandPlotStakingContainer } from './LandPlotStakingContainer';
import Maintenance from 'components/Maintenance';

const LandChestStaking = () => {
  const context = React.useContext(Web3Context);
  const stakedLandChestsCount =
    (context.stakedLandChestsState?.stakedChests.length || 0) > 0
      ? context.stakedLandChestsState?.stakedChests.reduce(
          (prev, crt) => (prev += crt.amount),
          0
        )
      : 0;
  const stakedLandPlotsCount =
    (context.stakedLandChestsState?.stakedPlots.length || 0) > 0
      ? context.stakedLandChestsState?.stakedPlots.reduce(
          (prev, crt) => (prev += crt.amount),
          0
        )
      : 0;

  const unbondingLandChestsCount =
    (context.stakedLandChestsState?.unbondingChests.length || 0) > 0
      ? context.stakedLandChestsState?.unbondingChests.reduce(
          (prev, crt) => (prev += crt.amount),
          0
        )
      : 0;
  const unbondingLandPlotsCount =
    (context.stakedLandChestsState?.unbondingPlots.length || 0) > 0
      ? context.stakedLandChestsState?.unbondingPlots.reduce(
          (prev, crt) => (prev += crt.amount),
          0
        )
      : 0;
  const handleClaimRewards = async () => {
    const gasLimit =
      await context.stakedLandChestsState?.calculateClaimRewardsGasLimit();
    await context.sendTransaction(
      landChestStakingContractAddress,
      gasLimit || 0,
      'claimReward'
    );
  };

  return context.stakedLandChestsState?.isLoading ? (
    <Loading coverFullPage={true} />
  ) : (
    <>
      {(context.stakedLandChestsState?.pendingRewards || 0) > 0 && (
        <ActionAlert
          alertText='You have pending rewards.'
          actionText='Claim now!'
          action={handleClaimRewards}
        />
      )}
      <div className='row'>
        <LandChestStakingCardInfo
          stakedLandChestsCount={
            (stakedLandChestsCount || 0) + (stakedLandPlotsCount || 0)
          }
          unbondingLandChestsCount={
            (unbondingLandChestsCount || 0) + (unbondingLandPlotsCount || 0)
          }
        />
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <LandChestStakingContainer />
        </div>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <LandPlotStakingContainer />
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
          <Activity interaction={ACTIVITY_INTERACTION_TYPES.LandChestStaking} />
        </div>
      </div>
    </>
  );
};

export default LandChestStaking;

export const BASE_GAS_LIMIT = 45_000_000;
export const GAS_PER_NFT = 20_000_000;
