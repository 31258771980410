import BigNumber from 'bignumber.js';

interface ScResultType {
  callType: string;
  gasLimit: number;
  gasPrice: number;
  nonce: number;
  prevTxHash: string;
  receiver?: string;
  sender: string;
  value: string;
  data?: string;
  returnMessage?: string;
}

type TxStatusType = 'pending' | 'notExecuted' | 'success' | 'fail';

export interface TransactionType {
  fee?: string;
  blockHash: string;
  data: string;
  gasLimit: number;
  gasPrice: number;
  gasUsed: string;
  txHash: string;
  miniBlockHash: string;
  nonce: number;
  receiver: string;
  receiverShard: number;
  round: number;
  sender: string;
  senderShard: number;
  signature: string;
  status: TxStatusType;
  timestamp: number;
  value: string;
  scResults?: ScResultType[];
}

export interface StateType {
  transactions: TransactionType[];
  transactionsFetched: boolean | undefined;
}

export enum ACTIVITY_INTERACTION_TYPES {
  Vesting = 1,
  OriginMintOrMigrate = 2,
  Marketplace = 3,
  NftStaking = 4,
  NftSwaps = 5,
  Summoning = 6,
  LandChestStaking = 7,
  LandChestSale = 8,
  KosonStaking = 9,
  LandChestOpening = 10
}

export interface ActivityTransaction {
  timestamp: number;
  interaction: ACTIVITY_INTERACTION_TYPES;
  message: string;
  valueMessage: string;
  txHash: string;
}

export interface AccountNft {
  collection: string;
  identifier: string;
  nonce: number;
  amount: number;
  unparsedAmount: string;
  regularSummoningCount?: number;
  specialSummoningCount?: number;
  isOrigin: boolean;
  isSoul: boolean;
  isNotMigratedOrigin: boolean;
  isLandChest: boolean;
  isStakedKosonBatch: boolean;
  isTokenNft: boolean;
  name: string;
  thumbnailUrl: string;
  fullResourceUrl: string;
  description: string;
  ETAUntilClaim?: string;
  canBeClaimed?: boolean;
  stakeDay?: number;
  daysUntilClaimDay?: number;
  pendingReward?: number;
  rarity?: string;
  videoResourceUrl?: string;
}

export interface MarketplaceListing {
  listingId: number;
  collection: string;
  identifier: string;
  nonce: number;
  regularSummoningCount: number;
  specialSummoningCount?: number;
  isOrigin: boolean;
  name: string;
  thumbnailUrl: string;
  fullResourceUrl: string;
  isBuyout: boolean;
  minBid: BigNumber;
  maxBid: BigNumber;
  originalOwnerAddress: string;
  currentWinnerAddress: string;
  specialAbility: string;
  startTime: number;
  endTime: number;
  hasExpired: boolean;
  description: string;
}

export interface MarketplaceListingV2 {
  listingId: number;
  collection: string;
  identifier: string;
  nonce: number;
  regularSummoningCount: number;
  specialSummoningCount?: number;
  isOrigin: boolean;
  name: string;
  thumbnailUrl: string;
  fullResourceUrl: string;
  isBuyout: boolean;
  minBid: string;
  maxBid: string;
  originalOwnerAddress: string;
  currentWinnerAddress: string;
  specialAbility: string;
  startTime: number;
  endTime: number;
  hasExpired: boolean;
  description: string;
}

export interface DebugType {
  address: string;
}

export interface EventSettings {
  bendisCall: boolean;
  onlyKosonChestPurchase: boolean;
}

export interface CreateUtrustOrderPayload {
  address: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  items: CreateUtrustOrderItemPayload[];
}

export interface CreateUtrustOrderItemPayload {
  chestType: number;
  quantity: number;
}

export interface AccountOrderState {
  hasOrder: boolean;
  orderStatus: ORDER_STATUS;
  redirectUrl: string;
  shouldRedirect: boolean;
  waitingForUtrustPaymentCompletion: boolean;
  awaitsKosonPayment: boolean;
  items: OrderItem[];
  canPlaceNewOrder?: boolean;
}
// {
//   "hasOrder": true,
//   "orderStatus": 0,
//   "redirectUrl": "https://pay.sandbox-utrust.com?uuid=7f77019a-2fb9-4312-a748-64c580dc9ae0",
//   "shouldRedirect": true,
//   "waitingForUtrustPaymentCompletion": false,
//   "awaitsKosonPayment": false,
//   "quantity": 0,
//   "chestType": 0
// }
export enum ORDER_STATUS {
  New = 0, //user pressed purchase button
  Pending = 1, // user made utrust payment
  Paid = 2, // utrust payment confirmed
  PendingKoson = 3, // oracle whitelisted order
  Completed = 4, // user made the koson payment
  Cancelled = 5,
  Invalid = 6
}

export interface LandChestPrice {
  chestId: number;
  fullPriceUsd: number;
  discountedPriceUsd: number;
  fullPriceKosonUsd: number;
  discountPriceKosonUsd: number;
  fullPriceKosonAmount: number;
  discountedPriceKosonAmount: number;
}

export interface OrderItem {
  chestNonce: number;
  orderId: string;
  orderItemId: string;
  quantity: number;
}

export interface NonceAmountPair {
  nonce: number;
  amount: number;
}
