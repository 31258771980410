import { landChestSaleContractAddress } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import { addr2Hex, int2hex } from 'contexts/Web3Context/helpers/generalUtils';
import React, { useContext, useState } from 'react';

export const SendLandChests = () => {
  const { sendTransaction } = useContext(Web3Context);
  const chestTypes = {
    1: 'Continental Chest',
    2: 'Steepe Chest',
    3: 'Panonic Chest',
    4: 'Pontic Chest'
  };
  const [selectedChestType, setSelectedChestType] = useState<
    undefined | 1 | 2 | 3 | 4
  >();
  const [receiverAddress, setReceiverAddress] = useState<string>();
  const [quantity, setQuantity] = useState<number>();
  const handleSendChests = async () => {
    if (selectedChestType === undefined || quantity === undefined) {
      return;
    }
    let data = `withdrawChests@${int2hex(selectedChestType)}@${int2hex(
      quantity
    )}`;
    if (receiverAddress !== undefined && receiverAddress.startsWith('erd')) {
      data += '@' + addr2Hex(receiverAddress);
    }

    await sendTransaction(landChestSaleContractAddress, 20_000_000, data);
  };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-6 col-sm-12'>
          <div className='basic-dropdown'>
            <div className='dropdown'>
              <button
                type='button'
                className='btn btn-outline-primary dropdown-toggle w-100'
                data-bs-toggle='dropdown'
              >
                {selectedChestType === undefined
                  ? 'Select Chest type'
                  : chestTypes[selectedChestType ?? 0]}
              </button>
              <div className='dropdown-menu'>
                <div
                  className='dropdown-item'
                  role='button'
                  onClick={() => setSelectedChestType(1)}
                >
                  Continental Chest
                </div>
                <div
                  className='dropdown-item'
                  role='button'
                  onClick={() => setSelectedChestType(2)}
                >
                  Steepe Chest
                </div>
                <div
                  className='dropdown-item'
                  role='button'
                  onClick={() => setSelectedChestType(3)}
                >
                  Panonic Chest
                </div>
                <div
                  className='dropdown-item'
                  role='button'
                  onClick={() => setSelectedChestType(4)}
                >
                  Pontic Chest
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-sm-12'>
          <input
            type='number'
            className='form-control input-rounded mt-1'
            placeholder='Quantity'
            step={1}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
          />
        </div>
        <div className='col-12'>
          <input
            type='text'
            className='form-control input-rounded mt-3'
            placeholder='Receiver address (leave empty to retrieve the chests on your wallet'
            onChange={(e) => setReceiverAddress(e.target.value)}
          />
        </div>
        <div className='col-12 d-flex justify-content-center'>
          <button
            className='btn btn-primary mt-3 w-50'
            onClick={handleSendChests}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};
