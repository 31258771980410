import React from 'react';
import BigNumber from 'bignumber.js';
import { Web3Context } from 'contexts/Web3Context';
import { getTimeUntil } from 'contexts/Web3Context/helpers/generalUtils';
import { summoningContractAddress } from 'config';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faInfo } from '@fortawesome/free-solid-svg-icons';
import './PendingSummoningCard.css';

const PendingSummoningCard = ({
  summoningId,
  dateToClaim,
  isSpecialSummon
}: {
  summoningId: BigNumber;
  dateToClaim: number;
  isSpecialSummon: boolean;
}) => {
  const context = React.useContext(Web3Context);
  const canBeClaimed = dateToClaim * 1000 < new Date().getTime();
  const timeUntilClaim = getTimeUntil(dateToClaim);

  const handleClaimSummoning = async () => {
    if (!canBeClaimed) {
      return;
    }
    let hex = summoningId.toString(16);
    if (hex.length % 2 === 1) {
      hex = '0' + hex;
    }
    await context.sendTransaction(
      summoningContractAddress,
      35000000,
      `claim@${hex}`
    );
  };
  return (
    <div className='row'>
      <div className='col-lg-12 pending-summoning-wrapper'>
        <div
          className={`me-3 d-flex justify-content-center align-items-center ${
            isSpecialSummon ? 'death-soul' : ''
          }`}
        >
          <img
            src='/images/aoz/pendingSummoning.png'
            className='img-fluid rounded-circle p-1'
          />
          <div className='claim-message' onClick={handleClaimSummoning}>
            {canBeClaimed && 'Claim'}
            {!canBeClaimed && `Claimable in ${timeUntilClaim}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingSummoningCard;
