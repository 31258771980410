import MarketplaceListingThumbnail from 'components/NFT/MarketplaceListingThumbnail';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { MarketplaceListing } from 'types';

const LatestMarketplaceListings = ({
  listings,
  hasLimit
}: {
  listings: MarketplaceListing[];
  hasLimit: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div className='card text-center' style={{ maxHeight: '70vh' }}>
      <div className='card-header text-between'>
        <h5 className='card-title'>🔥 Latest Soul Listings</h5>
        <div>
          <button
            className='btn btn-outline-primary text-white'
            style={{ minWidth: '175px' }}
            onClick={() => navigate(routeNames.marketplace)}
          >
            See all
          </button>
        </div>
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          {listings.map((nft, i) => {
            return hasLimit && i >= 4 ? null : (
              <MarketplaceListingThumbnail
                nft={nft}
                key={`nft-staking-key-${i}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default LatestMarketplaceListings;
