import React from 'react';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from 'routes';
import { Web3Context } from 'contexts/Web3Context';
import { toLocaleStringOptions, toLocaleStringOptionsNoDecimals } from 'config';
import {
  faCirclePlay,
  faCircleX,
  faGhost as faGhostSolid,
  faPanorama,
  faShieldCheck,
  faSword,
  faTreasureChest as faTreasureChestSolid
} from '@fortawesome/pro-solid-svg-icons';
import {
  faLock,
  faLockOpen,
  faGhost as faGhostLight,
  faTreasureChest as faTreasureChestLight
} from '@fortawesome/pro-light-svg-icons';
import { KYC_STATUS } from 'hooks/useAccountInfo';
import Synaps from '@synaps-io/react-verify';
import KycStatusModalContent from 'components/Modals/KycStatusModalContent';

const AccountStatsCards = () => {
  const context = React.useContext(Web3Context);

  const [totalStakedKoson, setTotalStakedKoson] = React.useState(0);
  const [totalPendingRewardsKosonStaking, setTotalPendingRewardsKosonStaking] =
    React.useState(0);
  const [totalVested, setTotalVested] = React.useState(
    context.vestingState?.totalVested
  );

  React.useEffect(() => {
    setTotalVested(context.vestingState?.totalVested);
  }, [context.vestingState?.totalVested]);

  React.useEffect(() => {
    if (
      !context.nftState?.stakedKosonBatches ||
      context.nftState?.stakedKosonBatches.length === 0
    ) {
      return;
    }
    const sum = context.nftState.stakedKosonBatches.reduce(
      (prev, crt) => (prev += crt.amount),
      0
    );
    const rewardsSum = context.nftState.stakedKosonBatches.reduce(
      (prev, crt) => (prev += crt.pendingReward || 0),
      0
    );
    setTotalStakedKoson(sum);
    setTotalPendingRewardsKosonStaking(rewardsSum);
  }, [context.nftState?.stakedKosonBatches]);

  const LockedKosonCard = () => {
    if (totalVested === 0) {
      return (
        <SimpleStatsCard
          title={'Locked KOSON'}
          value={(0).toLocaleString()}
          icon={faLock}
          showKosonSymbol
        />
      );
    }
    if (context.vestingState?.canClaim) {
      return (
        <SimpleStatsCard
          title={'Locked KOSON'}
          value={(context.vestingState?.remainingVested || 0).toLocaleString()}
          icon={faLockOpen}
          badgeText={'Ready to claim'}
          navUrl={routeNames.vesting}
          showKosonSymbol
        />
      );
    } else {
      return (
        <SimpleStatsCard
          title={'Locked KOSON'}
          value={(context.vestingState?.remainingVested || 0).toLocaleString()}
          icon={faLock}
          badgeText={`${context.vestingState?.nextClaimTime}`}
          navUrl={routeNames.vesting}
          showKosonSymbol
        />
      );
    }
  };

  const StakedSoulsCard = () => {
    if ((context.nftStakingState?.pendingRewards ?? 0) > 0) {
      return (
        <SimpleStatsCard
          title={'Staked Souls'}
          value={(
            context.nftStakingState?.stakedNfts.length ?? 0
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          badgeText={`${context.nftStakingState?.pendingRewards.toLocaleString(
            undefined,
            toLocaleStringOptions
          )} $KOSON earned`}
          navUrl={routeNames.nftStaking}
          icon={faGhostSolid}
        />
      );
    }
    return (
      <SimpleStatsCard
        title={'Staked Souls'}
        value={(context.nftStakingState?.stakedNfts.length ?? 0).toLocaleString(
          undefined,
          toLocaleStringOptionsNoDecimals
        )}
        navUrl={routeNames.nftStaking}
        icon={faGhostSolid}
      />
    );
  };

  const StakedLandChestsCard = () => {
    const stakedLandChestsCount =
      (context.stakedLandChestsState?.stakedChests.length || 0) > 0
        ? context.stakedLandChestsState?.stakedChests.reduce(
            (prev, crt) => (prev += crt.amount),
            0
          )
        : 0;

    const stakedLandPlotCount =
      (context.stakedLandChestsState?.stakedPlots.length || 0) > 0
        ? context.stakedLandChestsState?.stakedPlots.reduce(
            (prev, crt) => (prev += crt.amount),
            0
          )
        : 0;
    if ((context.stakedLandChestsState?.pendingRewards ?? 0) === 0) {
      return (
        <SimpleStatsCard
          title={'Staked Land'}
          value={(
            (stakedLandChestsCount || 0) + (stakedLandPlotCount || 0)
          ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
          icon={faTreasureChestSolid}
          navUrl={routeNames.landchestStaking}
        />
      );
    }
    return (
      <SimpleStatsCard
        title={'Staked Land'}
        value={(
          (stakedLandChestsCount || 0) + (stakedLandPlotCount || 0)
        ).toLocaleString(undefined, toLocaleStringOptionsNoDecimals)}
        icon={faTreasureChestSolid}
        navUrl={routeNames.landchestStaking}
        badgeText={`${context.stakedLandChestsState?.pendingRewards.toLocaleString(
          undefined,
          toLocaleStringOptions
        )} $KOSON earned`}
      />
    );
  };

  const StakedKosonCard = () => {
    if (totalPendingRewardsKosonStaking > 0) {
      return (
        <SimpleStatsCard
          title={'Staked KOSON'}
          value={totalStakedKoson.toLocaleString(
            undefined,
            toLocaleStringOptions
          )}
          icon={faCoins}
          badgeText={`${totalPendingRewardsKosonStaking.toLocaleString(
            undefined,
            toLocaleStringOptions
          )} $KOSON earned`}
          navUrl={routeNames.kosonStaking}
          showKosonSymbol
        />
      );
    } else {
      return (
        <SimpleStatsCard
          title={'Staked KOSON'}
          value={totalStakedKoson.toLocaleString(
            undefined,
            toLocaleStringOptions
          )}
          icon={faCoins}
          navUrl={routeNames.kosonStaking}
          showKosonSymbol
        />
      );
    }
  };

  const kycStatusCard = () => {
    let kycStatusText = '';
    let kycStatusIcon: any;
    let kycStatusColor;
    let kycBadgeText;
    switch (context.accountState?.kycStatus.status) {
      case KYC_STATUS.NOT_STARTED:
        kycStatusText = 'Not done';
        kycStatusIcon = faCircleX;
        kycStatusColor = 'red';
        kycBadgeText = 'Start now';
        break;
      case KYC_STATUS.STARTED:
        kycStatusText = 'Started';
        kycStatusIcon = faCirclePlay;
        kycBadgeText = 'Continue now';
        break;
      case KYC_STATUS.VERIFIED:
        kycStatusText = 'Verified';
        kycStatusIcon = faShieldCheck;
        kycStatusColor = 'green';
        break;
    }
    return (
      <>
        <div data-bs-toggle='modal' data-bs-target='#runKycModal'>
          <SimpleStatsCard
            title={'KYC Status'}
            value={kycStatusText}
            icon={kycStatusIcon}
            iconColor={kycStatusColor}
            badgeText={kycBadgeText}
          />
        </div>
        <div
          className='modal fade'
          id='runKycModal'
          tabIndex={-1}
          role='dialog'
          aria-hidden='true'
        >
          <KycStatusModalContent
            sessionId={context.accountState?.kycStatus.sessionId || ''}
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>{LockedKosonCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>{StakedKosonCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Souls'}
            value={context.nftState?.accountSoulNfts.length.toString() || 'N/A'}
            icon={faGhostLight}
          />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>{StakedSoulsCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Land chests'}
            value={
              context.nftState?.accountLandChests.length.toString() || 'N/A'
            }
            icon={faTreasureChestLight}
            badgeText={
              (context.nftState?.accountLandChests.length || 0) > 0
                ? 'Stake now'
                : undefined
            }
            navUrl={
              (context.nftState?.accountLandChests.length || 0) > 0
                ? routeNames.landchestStaking
                : undefined
            }
          />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          {StakedLandChestsCard()}
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>{kycStatusCard()}</div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <SimpleStatsCard
            title={'Inventory items'}
            value={'Coming soon'}
            icon={faSword}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountStatsCards;
