// slices/accountInfo/marketplaceListings.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserListings,
  getUserListings2
} from 'api/backendRequests/marketplaceApi';
import { MarketplaceListingV2 } from 'types';

export interface MarketplaceListingData {
  listings: MarketplaceListingV2[];
}

interface marketplaceListingsState {
  data: MarketplaceListingData | null; // replace with your data type
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: marketplaceListingsState = {
  data: null,
  status: 'idle',
  error: null
};

export const fetchUserMarketplaceListings = createAsyncThunk(
  'userMarketplaceListings/fetch',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await getUserListings2(address); // replace with your API call
      if (!data.success) {
        return [];
      }
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const marketplaceListingsSlice = createSlice({
  name: 'userMarketplaceListings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMarketplaceListings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchUserMarketplaceListings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = {
            listings: action.payload
          };
        }
      )
      .addCase(
        fetchUserMarketplaceListings.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export default marketplaceListingsSlice.reducer;
