// slices/accountInfo/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import vestingReducer from './vesting';
import inventoryReducer from './inventory';
import marketplaceListingsReducer from './marketplaceListings';
import pendingRewardsReducer from './pendingRewards';
import stakedInventoryReducer from './stakedInventory';
// import other reducers

const accountInfoReducer = combineReducers({
  vesting: vestingReducer,
  inventory: inventoryReducer,
  marketplaceListings: marketplaceListingsReducer,
  pendingRewards: pendingRewardsReducer,
  stakedInventory: stakedInventoryReducer
  // other subfeatures
});

export default accountInfoReducer;
