export const REWARD_IMG_MAPPING: { [key: string]: string } = {
  'LANDTOKENS-07db50-05':
    'https://media.elrond.com/nfts/asset/QmTtmkCvEktF2gDjsCwJj8ti82UCuzSuQQFSys4AxnPwLp',
  'LANDTOKENS-07db50-04':
    'https://media.elrond.com/nfts/asset/QmdmHroUw4U8nknn3wGrUy3aePYsqJ4XZwndhuftfJiQWg',
  'LANDTOKENS-07db50-03':
    'https://media.elrond.com/nfts/asset/QmQVFRWd5RYYrXD3kxbtM7Y3T8HWCt5vuJdtQye9KWi4ZP',
  'LANDTOKENS-07db50-02':
    'https://media.elrond.com/nfts/asset/QmfU5Ku6giqd3YsJSiQJvdbhpQA3xxtMDkyYPxXXFYYjLy',
  'LANDTOKENS-07db50-01':
    'https://media.elrond.com/nfts/asset/QmRjVA2k43GiP33RAkeCsFyMr6Hwk1q5VKHUuvMQFDeDCG',
  'WTOKENS-1f9216-05':
    'https://media.elrond.com/nfts/asset/QmfZ1dg2GTeyWf5wYhYiJAcZkVXZDwgFtrnRhGMSHCSeT7',
  'WTOKENS-1f9216-04':
    'https://media.elrond.com/nfts/asset/QmSK32W6E9qYG5k6hmoPD7DGQbdT5RJ34fwTUjciu7H5rn',
  'WTOKENS-1f9216-03':
    'https://media.elrond.com/nfts/asset/QmUoZoWugBoQYMF2sinJDuRj4pyYMkuSTt2Npa6DFpt4rd',
  'WTOKENS-1f9216-02':
    'https://media.elrond.com/nfts/asset/QmV7ugTvjCmnDU1yCQbcEEvAArnsEZBGrnojtv6GXPqcKF',
  'WTOKENS-1f9216-01':
    'https://media.elrond.com/nfts/asset/QmPnBUaWxJQa4jXP85MF4jugXraVYZpwxQkpUa85QJ7DJa',
  'ATOKENS-92926e-05':
    'https://media.elrond.com/nfts/asset/QmXyQpFfMyVjccPzyXVA4ApLhedYJMxrgWrqk4YrYvdQHw',
  'ATOKENS-92926e-04':
    'https://media.elrond.com/nfts/asset/QmQ2j3JUUANWNW8QnF4iEvrx3EpBnfU6fHBJ5X7WgXYyVf',
  'ATOKENS-92926e-03':
    'https://media.elrond.com/nfts/asset/QmaBYRf6dhrxThQqKMf1fVCg34h2zyNFL8iQkK9JaWLUbd',
  'ATOKENS-92926e-02':
    'https://media.elrond.com/nfts/asset/QmRCq7dKyDTRkpWADCzaLJ28VxdkGuk449H4aYzQ9dUrXs',
  'ATOKENS-92926e-01':
    'https://media.elrond.com/nfts/asset/QmVt4CBmFLZUzPGDPSzUEEEjrRFDkHrCZCv2njAEAZscBV',
  'MISCTOKENS-b160cf-05':
    'https://media.elrond.com/nfts/asset/QmTQMjZnFSw6DPJAknWHPxMspLdmKwdjyTzVk1opSz3PK7',
  'MISCTOKENS-b160cf-04':
    'https://media.elrond.com/nfts/asset/QmPLDG3MUVzCkJ4tSwdJC6PZ1AdY2jJmBkdRLbxNPXwh7S',
  'MISCTOKENS-b160cf-03':
    'https://media.elrond.com/nfts/asset/QmbBFFzQM9bErrdN4ZJTzggwxwBNtTvnJmCMk3BMmLPuTV',
  'MISCTOKENS-b160cf-02':
    'https://media.elrond.com/nfts/asset/QmYMmfzLjA5VVZ8Yop86rf7Ktxe1CcPgkL5FAMaDwmX6vX',
  'MISCTOKENS-b160cf-01':
    'https://media.elrond.com/nfts/asset/QmZQeLzWtTaDWzY7daU8FLqNHvDQdvdMcbLB7oKiSqDjwP',
  'CTOKENS-d87759-05':
    'https://media.elrond.com/nfts/asset/QmTz6JkK4BaKatq2d5tBREuDPTAB6dXaUhSuKezMryKzM1',
  'CTOKENS-d87759-04':
    'https://media.elrond.com/nfts/asset/QmQ7UdYZfKVESUSiQ8cSeXVD1APe7VBzu5CJ2pNuPybmEG',
  'CTOKENS-d87759-03':
    'https://media.elrond.com/nfts/asset/QmTfn6MVsQ6dQacrW74tzFmigDoQJx5LPMQYirZh3LbbaA',
  'CTOKENS-d87759-02':
    'https://media.elrond.com/nfts/asset/QmZ1wh5XxXogo9qYGxJNPTzp5VufDv9pBYuRePbQGBAmKE',
  'CTOKENS-d87759-01':
    'https://media.elrond.com/nfts/asset/QmfDPLpaw3sPaLXiLivZVruQtsqnjrD7uiTZgrxsAGe8cq'
};
