import { BigUIntValue } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import NftStaking from 'components/Containers/NftStaking';
import { landChestStakingContractAddress } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import {
  addr2Hex,
  int2hex,
  str2hex,
  bigUint2hex,
  padBeforeString
} from 'contexts/Web3Context/helpers/generalUtils';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getLandPlotStakingInfo } from 'store/slices/accountInfo/selectors/getNftStakingInfo';
import { AccountNft } from 'types';
import { BASE_GAS_LIMIT, GAS_PER_NFT } from '.';

export const LandPlotStakingContainer = () => {
  const context = React.useContext(Web3Context);
  const { address } = useGetAccountInfo();

  const { stake, availableNfts, status } = useSelector(getLandPlotStakingInfo);

  const handleStake = async (
    nfts: AccountNft[],
    quantities: { [identifier: string]: number }
  ) => {
    let data = `MultiESDTNFTTransfer@${addr2Hex(
      landChestStakingContractAddress
    )}@${int2hex(nfts.length)}`;

    for (let i = 0; i < nfts.length; i++) {
      const token = str2hex(nfts[i].collection);
      const nonce = int2hex(nfts[i].nonce);
      const qty = int2hex(quantities[nfts[i].identifier]);
      data += `@${token}@${nonce}@${qty}`;
    }
    data += `@${str2hex('stake')}`;

    await context.sendTransaction(
      address,
      BASE_GAS_LIMIT + nfts.length * GAS_PER_NFT,
      data
    );
  };

  const handleUnstake = async (
    nfts: AccountNft[],
    quantities: { [identifier: string]: number }
  ) => {
    let data = 'unstakePlotsSfts@';
    for (let i = 0; i < nfts.length; i++) {
      if (quantities[nfts[i].identifier] === undefined) {
        continue;
      }
      const qty = bigUint2hex(new BigUIntValue(quantities[nfts[i].identifier]));
      const nonce = padBeforeString(int2hex(nfts[i].nonce), 16);
      data += `${nonce}${qty}`;
    }
    const gasLimit = BASE_GAS_LIMIT + GAS_PER_NFT * nfts.length;
    await context.sendTransaction(
      landChestStakingContractAddress,
      gasLimit,
      data
    );
  };

  const handleClaimUnbonded = async () => {
    if (!context.stakedLandChestsState?.hasClaimableUnbondingPlots) {
      return;
    }
    await context.sendTransaction(
      landChestStakingContractAddress,
      BASE_GAS_LIMIT +
        context.stakedLandChestsState.unbondingPlots.filter(
          (uc) => uc.canBeClaimed
        ).length *
          GAS_PER_NFT,
      'claimPlotUnstake'
    );
  };

  const handleUnstakeAll = async () => {
    let data = 'unstakePlotsSfts@';
    if (!context.stakedLandChestsState?.stakedPlots) {
      return;
    }
    for (let i = 0; i < context.stakedLandChestsState.stakedPlots.length; i++) {
      const nft = context.stakedLandChestsState.stakedPlots[i];
      const qty = bigUint2hex(new BigUIntValue(nft.amount));
      const nonce = padBeforeString(int2hex(nft.nonce), 16);
      data += `${nonce}${qty}`;
    }
    const gasLimit =
      BASE_GAS_LIMIT +
      GAS_PER_NFT * context.stakedLandChestsState.stakedPlots.length;
    await context.sendTransaction(
      landChestStakingContractAddress,
      gasLimit,
      data
    );
  };
  return status === 'loading' ? null : (
    <NftStaking
      headerText='Staked Land Plots NFTs'
      nothingStakedMessage="You haven't staked any land plots yet"
      stakeMoreNftsHeaderMessage='Stake Land Plots'
      stakeButtonDisabled={
        // ignore alpine plots
        availableNfts.filter((p) => p.nonce > 1)?.length === 0
      }
      stakedNfts={(stake?.unbondingNfts ?? []).concat(stake?.stakedNfts ?? [])}
      availableNfts={availableNfts.filter((p) => p.nonce > 1) ?? []}
      canClaimUnbonded={
        (stake?.unbondingNfts ?? []).length > 0 &&
        (stake?.canClaimUnbondedNfts ?? false)
      }
      handleStakeNfts={handleStake}
      handleUnstake={handleUnstake}
      handleClaimUnbonded={handleClaimUnbonded}
      handleUnstakeAllCustom={handleUnstakeAll}
    />
  );
};
