import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import LatestMarketplaceListings from 'components/Containers/LatestMarketplaceListings';
import { Web3Context } from 'contexts/Web3Context';
import Loading from 'components/Loading';

const Home = () => {
  const { accountState, marketplaceState } = React.useContext(Web3Context);
  const isLoggedIn = Boolean(accountState?.account.address);
  const navigate = useNavigate();
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
          <img
            src='images/aoz/logoaoz.png'
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </div>
        {!isLoggedIn && (
          <div className='col-lg-12 col-md-12 col-sm-12 text-center mb-5'>
            <button
              className='btn btn-primary btn-lg'
              onClick={() => navigate(routeNames.unlock)}
            >
              ⚡️ Connect
            </button>
          </div>
        )}
      </div>
      {marketplaceState?.isLoading ? (
        <Loading />
      ) : (
        <div className='row'>
          <LatestMarketplaceListings
            listings={marketplaceState?.freshListings || []}
            hasLimit={false}
          />
        </div>
      )}
    </>
  );
};

export default Home;
