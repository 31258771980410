import {
  useGetIsLoggedIn,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { getTransactionInfo } from 'api/apiRequests';
import {
  LandChestNFTCollection,
  landChestOpeningContractAddress
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import { REWARD_IMG_MAPPING } from 'contexts/Web3Context/helpers/constants';
import {
  addr2Hex,
  int2hex,
  str2hex
} from 'contexts/Web3Context/helpers/generalUtils';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { AccountNft } from 'types';
import { OpenChestTypeModal } from './OpenChestTypeModal';
import { ThumbnailCard } from './ThumbnailCard';
import { ChestRewardModal } from './ChestRewardModal';

export const OpeningCard = ({
  landChests,
  refreshDataTrigger
}: {
  landChests: AccountNft[];
  refreshDataTrigger: () => Promise<void>;
}) => {
  const { accountState } = useContext(Web3Context);

  const [openedRewards, setOpenedRewards] = useState<any[]>([]);
  const loggedIn = useGetIsLoggedIn();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState('');
  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      if (transactionStatus.transactions) {
        const hash = transactionStatus.transactions[0].hash;
        getTransactionInfo(hash).then((res) => {
          setOpenedRewards(
            res.data.operations.filter(
              (op: any) => op.collection !== LandChestNFTCollection
            )
          );
          refreshDataTrigger().then(() => {
            // do nothing
          });
        });
      }
    }
  });

  const noLandChestsFound = () => {
    return (
      <div className='text-center mt-5'>
        {!loggedIn && <h3>Connect your wallet and open some chests</h3>}
        {!loggedIn && (
          <button
            className='btn btn-primary mt-3'
            onClick={() => navigate(routeNames.unlock)}
          >
            Connect
          </button>
        )}
        {loggedIn && <h3>No Land Chests found on your wallet</h3>}
      </div>
    );
  };

  const handleOpening = async (quantity: number, nonce?: number) => {
    if (accountState?.account?.address === undefined) {
      return;
    }
    const data = `ESDTNFTTransfer@${str2hex(
      LandChestNFTCollection
    )}@0${nonce}@${int2hex(quantity)}@${addr2Hex(
      landChestOpeningContractAddress
    )}@${str2hex('openChests')}`;

    const transaction = {
      receiver: accountState?.account.address,
      gasLimit: 20_000_000 + quantity * 1_000_000,
      data: data,
      value: 0
    };
    const { sessionId } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Opening chests',
        errorMessage: 'An error has occured during processing your transaction',
        successMessage: 'Transaction processed successfully',
        transactionDuration: 10000
      }
    });
    setSessionId(sessionId);
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h2>Open Land Chests</h2>
      </div>
      <div className='card-body container-fluid p-0 flex-grow-1'>
        {(landChests.length ?? 0) === 0 && noLandChestsFound()}
        <div className='row'>
          {landChests.map((chest, idx) => (
            <ThumbnailCard
              chest={chest}
              idx={idx}
              handleOpening={handleOpening}
            />
          ))}
        </div>
      </div>
      <ChestRewardModal
        openedRewards={openedRewards}
        onDismiss={() => setOpenedRewards([])}
      />
    </div>
  );
};
