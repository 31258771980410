import {
  faChartLine,
  faHourglassStart,
  faMoneyBill,
  faMoneyBillTrendUp,
  faSackDollar,
  faUnlock
} from '@fortawesome/free-solid-svg-icons';
import { faGhost } from '@fortawesome/pro-regular-svg-icons';
import SimpleStatsCard from 'components/Cards/SimpleStatsCard';
import { toLocaleStringOptions, toLocaleStringOptionsNoDecimals } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';

const NftStakingCardInfo = ({
  pendingRewards,
  totalDailyRewardPerNft,
  totalStakedSouls,
  totalStakedSoulsByUser,
  totalUnbondingSouls
}: {
  pendingRewards: number;
  totalDailyRewardPerNft: number;
  totalStakedSouls: number;
  totalStakedSoulsByUser: number;
  totalUnbondingSouls: number;
}) => {
  return (
    <>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Claimable rewards'}
          value={pendingRewards.toLocaleString(
            undefined,
            toLocaleStringOptions
          )}
          icon={faSackDollar}
          showKosonSymbol
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Daily reward'}
          value={(
            totalStakedSoulsByUser * totalDailyRewardPerNft
          ).toLocaleString(undefined, toLocaleStringOptions)}
          badgeText={`~ ${totalDailyRewardPerNft.toLocaleString(
            undefined,
            toLocaleStringOptions
          )} $KOSON per NFT`}
          icon={faMoneyBillTrendUp}
          showKosonSymbol
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Staked Soul NFTs'}
          value={totalStakedSoulsByUser.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faGhost}
          badgeText={`Out of ${totalStakedSouls} staked souls`}
        />
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12'>
        <SimpleStatsCard
          title={'Unbonding NFTs'}
          value={totalUnbondingSouls.toLocaleString(
            undefined,
            toLocaleStringOptionsNoDecimals
          )}
          icon={faHourglassStart}
        />
      </div>
    </>
  );
};
export default NftStakingCardInfo;
