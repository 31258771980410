import {
  LAND_CHEST_STAKING_DAILY_ALLOC,
  NFT_SOUL_STAKING_DAILY_ALLOC
} from 'globalconsts';
import { StakingRewardsData } from 'store/slices/generalStats/stakingRewards';
import { NonceAmountPair } from 'types';

export const getStakingRewards = async (
  stakedSoulsCount: number,
  stakedChestsCount: NonceAmountPair[],
  stakedPlotsCount: NonceAmountPair[]
): Promise<StakingRewardsData> => {
  const dailyRewardPerNft = NFT_SOUL_STAKING_DAILY_ALLOC / stakedSoulsCount;
  const dailyChestRewardPerNft = calcLandRewardPerNft(
    stakedChestsCount,
    stakedPlotsCount
  );

  return {
    soulStakingRewardPerNft: dailyRewardPerNft,
    landStakingRewardPerNft: dailyChestRewardPerNft,
    soulStakingAllocation: NFT_SOUL_STAKING_DAILY_ALLOC,
    landStakingAllocation: LAND_CHEST_STAKING_DAILY_ALLOC
  };
};

const calcLandRewardPerNft = (
  chests: NonceAmountPair[],
  plots: NonceAmountPair[]
): NonceAmountPair[] => {
  const map = new Map<number, number>();
  const processList = (list: NonceAmountPair[]) => {
    list.forEach(({ nonce, amount }) => {
      const currentAmount = map.get(nonce) || 0;
      map.set(nonce, currentAmount + amount);
    });
  };

  processList(chests);
  processList(plots);

  const mergedArray = Array.from(map, ([nonce, amount]) => ({ nonce, amount }));
  const resultArray: NonceAmountPair[] = [];

  for (let i = 0; i < mergedArray.length; i++) {
    resultArray.push({
      nonce: mergedArray[i].nonce,
      amount:
        LAND_CHEST_STAKING_DAILY_ALLOC[mergedArray[i].nonce - 1] /
        mergedArray[i].amount
    });
  }

  return resultArray;
};
