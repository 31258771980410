// slices/accountInfo/pendingRewards.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getPendingRewards } from 'api/v2/getPendingRewards';
// import { getPendingRewards } from '../../api';

export interface PendingRewardsData {
  soulStaking: number;
  landChestStaking: number;
  kosonStakingTotal: number;
  kosonStakingClaimable: number;
}

export const DefaultState: PendingRewardsData = {
  soulStaking: 0,
  landChestStaking: 0,
  kosonStakingTotal: 0,
  kosonStakingClaimable: 0
};

interface PendingRewardsState {
  data: PendingRewardsData | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PendingRewardsState = {
  data: DefaultState,
  status: 'idle',
  error: null
};

export const fetchPendingRewards = createAsyncThunk(
  'pendingRewards/fetch',
  async (address: string, { rejectWithValue }) => {
    try {
      const data = await getPendingRewards(address); // replace with your API call
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const pendingRewardsSlice = createSlice({
  name: 'pendingRewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingRewards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchPendingRewards.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(
        fetchPendingRewards.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export default pendingRewardsSlice.reducer;
