import React from 'react';
import BigNumber from 'bignumber.js';
import { Web3Context } from 'contexts/Web3Context';
import { KOSON_TICKER } from 'config';
import BalanceCard from './BalanceCard';

const AccountInfo = () => {
  const context = React.useContext(Web3Context);
  const [showSendKosonInput, setShowSendKosonInput] = React.useState(false);
  const [sendKosonInputAmount, setSendKosonInputAmount] = React.useState('');
  const [sendKosonInputAddress, setSendKosonInputAddress] = React.useState('');
  const [pendingKosonRewards, setPendingKosonRewards] = React.useState(0);
  const [totalEarnRate, setTotalEarnRate] = React.useState<number>();
  const handleSendKoson = async () => {
    if (!showSendKosonInput) {
      setShowSendKosonInput(true);
      return;
    }
    const amount = new BigNumber(sendKosonInputAmount).multipliedBy(
      new BigNumber(10).pow(18)
    );
    let hexAmount = amount.toString(16);
    if (hexAmount.length % 2 === 1) {
      hexAmount = '0' + hexAmount;
    }
    context.sendTransaction(
      sendKosonInputAddress,
      1000000,
      `ESDTTransfer@${Buffer.from(KOSON_TICKER).toString('hex')}@${hexAmount}`
    );
  };

  React.useEffect(() => {
    const kosonStakingRewards =
      (context.nftState?.stakedKosonBatches || []).length > 0
        ? context.nftState?.stakedKosonBatches.reduce(
            (prev, crt) => (prev += crt.pendingReward || 0),
            0
          )
        : 0;
    const landChestStakingRewards =
      context.stakedLandChestsState?.pendingRewards || 0;
    const soulStakingRewards = context.nftStakingState?.pendingRewards || 0;
    setPendingKosonRewards(
      (kosonStakingRewards || 0) + landChestStakingRewards + soulStakingRewards
    );
  }, [
    context.nftState?.accountNfts,
    context.nftStakingState?.pendingRewards,
    context.stakedLandChestsState?.pendingRewards,
    context.nftState?.stakedKosonBatches
  ]);

  const handleAssignHeroTag = async () => {
    window.alert('TODO');
  };

  return (
    <div className='card overflow-hidden'>
      <div
        className='text-center p-5 overlay-box'
        // style='background-image: url(images/big/img5.jpg);'
      >
        <img
          src='images/profile/default.png'
          width='100'
          className='img-fluid rounded-circle'
          alt=''
        />
        <div className='mt-3'>
          <small>Welcome back,</small>
          <h4 className='mb-0 text-white'>
            {context.accountState?.heroTag
              ? `@${context.accountState?.heroTag}`
              : 'Dacian'}
          </h4>
          <small>{context.accountState?.account.address}</small>
          {/* {context.accountState?.heroTag === '' && (
            <small>
              <a href='#' onClick={handleAssignHeroTag}>
                Don&apos;t have your herotag? Assign one now!
              </a>
            </small>
          )} */}
        </div>
      </div>
      <div className='card-body'>
        <div className='row text-center'>
          <div className='col-6'>
            <BalanceCard
              amount={context.accountState?.egldBalance || 0}
              isEgld={true}
              text={'EGLD Balance'}
            />
          </div>
          <div className='col-6'>
            <BalanceCard
              amount={context.accountState?.kosonBalance || 0}
              isEgld={false}
              text={'$KOSON Balance'}
            />
          </div>
        </div>
        <div className='row text-center mt-3'>
          <div className='col-12'>
            <BalanceCard
              amount={pendingKosonRewards}
              isEgld={false}
              text={'Pending $KOSON Rewards'}
              secondaryText={totalEarnRate ? '' : undefined}
            />
          </div>
        </div>
      </div>
      <div className='card-footer mt-0'>
        {showSendKosonInput && (
          <div className='mb-4'>
            <form>
              <input
                type='number'
                className='form-control input-rounded mb-2'
                placeholder='1.2345'
                onChange={(e) => setSendKosonInputAmount(e.target.value)}
              />
              <input
                type='text'
                className='form-control input-rounded'
                placeholder='erd1..'
                onChange={(e) => setSendKosonInputAddress(e.target.value)}
              />
            </form>
          </div>
        )}
        <div>
          <button
            className='btn btn-primary btn-lg btn-block'
            onClick={handleSendKoson}
          >
            Send $KOSON
          </button>
        </div>
        {showSendKosonInput && (
          <div className='d-flex justify-content-center mt-2'>
            <a href='#' onClick={() => setShowSendKosonInput(false)}>
              Cancel
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
