import Activity from 'components/Activity';
import Loading from 'components/Loading';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { ACTIVITY_INTERACTION_TYPES } from 'types';
import PendingSummoningsContainer from './PendingSummoningsContainer';
import SummoningProcessContainer from './SummoningProcessContainer';

const Summoning = () => {
  const { summoningState } = React.useContext(Web3Context);
  return summoningState?.isLoading ? (
    <Loading coverFullPage={true} />
  ) : (
    <>
      <div className='row'>
        <div className='col-lg-9'>
          <SummoningProcessContainer />
        </div>
        <div className='col-lg-3'>
          <PendingSummoningsContainer
            pendingSummonings={
              summoningState?.completedSummoningProcesses || []
            }
          />
        </div>
        <div className='col-lg-12'>
          <Activity interaction={ACTIVITY_INTERACTION_TYPES.Summoning} />
        </div>
      </div>
    </>
  );
};

export default Summoning;
