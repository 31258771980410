import { faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarketplaceListingThumbnail from 'components/NFT/MarketplaceListingThumbnail';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';

const ListingsContainer = ({
  listings,
  listings2
}: {
  listings: MarketplaceListing[];
  listings2: MarketplaceListingV2[];
}) => {
  return (
    <div className='card text-center' style={{ maxHeight: '700px' }}>
      <div className={'card-header text-start'}>
        <h5 className='card-title'>Your listings</h5>
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          {listings2 === undefined &&
            listings.map((nft, i) => {
              return (
                <MarketplaceListingThumbnail
                  nft={nft}
                  key={`nft-staking-key-${i}`}
                />
              );
            })}
          {listings2 !== undefined &&
            listings2.map((nft, i) => {
              return (
                <MarketplaceListingThumbnail
                  nft={nft}
                  key={`nft-staking-key-${i}`}
                />
              );
            })}
          {(listings.length === 0 && listings2 === undefined) ||
            (listings.length === 0 && listings2?.length === 0 && (
              <div className='col-12 text-center p-0'>
                <FontAwesomeIcon
                  icon={faWind}
                  className='mt-3 mb-5'
                  size='5x'
                />
                <h3 className='mt-2'>No NFT listings available to display</h3>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default ListingsContainer;
