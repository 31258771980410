import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import CollapsibleContainer from 'components/CollapsibleContainer';
import { adminAddresses } from 'config';
import ChangeContractOwnershipContent from './ChangeContractOwnershipContent';
import { SendLandChests } from './SendLandChests';

const Admin = () => {
  const { address } = useGetAccountInfo();
  const isAdmin = adminAddresses.includes(address);

  const contractOwnershipContainer = () => (
    <ChangeContractOwnershipContent address={address} />
  );

  return !isAdmin ? null : (
    <div className='row'>
      <div className='col-lg-12 col-sm-12 col-md-12'>
        <CollapsibleContainer
          title='Send land chests'
          content={<SendLandChests />}
        />
      </div>
      <div className='col-lg-12 col-sm-12 col-md-12'>
        <CollapsibleContainer
          title='Contract management'
          content={contractOwnershipContainer()}
        />
      </div>
    </div>
  );
};

export default Admin;
