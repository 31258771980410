import Loading from 'components/Loading';
import InventoryFilterModalContent from 'components/Modals/InventoryFilterModalContent';
import ListNftModal from 'components/Modals/ListNftModal';
import NFTThumbnail from 'components/NFT/NftThumbnail';
import {
  LandChestContentCollections,
  LandChestNFTCollection,
  SoulsNFTCollections,
  StakedKosonCollections
} from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React, { useEffect } from 'react';
import { AccountNft } from 'types';
import InventoryCardInfo from './CardInfo';
import { faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InventoryThumbnail } from 'components/NFT/InventoryThumbnail';

const Inventory = () => {
  const context = React.useContext(Web3Context);
  const [collectionsToDisplay, setCollectionsToDisplay] = React.useState(
    SoulsNFTCollections.concat([LandChestNFTCollection])
      .concat(StakedKosonCollections)
      .concat(LandChestContentCollections)
  );
  const [selectedNft, setSelectedNft] = React.useState<AccountNft>();
  const updateFilters = (identifiers: string[]) => {
    setCollectionsToDisplay(identifiers);
  };

  useEffect(() => {
    console.log('nfts', context.nftState?.accountSoulNfts);
    console.log('items', context.nftState?.tokenNfts);
  }, []);

  return context.nftState?.isLoading ? (
    <Loading coverFullPage={true} />
  ) : (
    <>
      <div className='row'>
        <InventoryCardInfo />
      </div>
      <div className='row'>
        <div className='col-lg-12 d-flex justify-content-between'>
          <h3>Inventory</h3>
          <button
            className='btn btn-outline-primary text-white'
            style={{ minWidth: '175px' }}
            data-bs-toggle='modal'
            data-bs-target='#inventoryFiltersModal'
          >
            Filter
          </button>
          <div className='modal fade' id='inventoryFiltersModal'>
            <InventoryFilterModalContent onApply={updateFilters} />
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        {context.nftState?.accountSoulNfts
          .filter((nft) => collectionsToDisplay.includes(nft.collection))
          .map((nft, i) => (
            <InventoryThumbnail
              nft={nft}
              key={`inventory-soul-nfts-item-key-${i}`}
              // onClick={(nft) => setSelectedNft(nft)}
              // openModalName='#random-test-modal'
            />
          ))}
        {context.nftState?.accountLandChests
          .filter((nft) => collectionsToDisplay.includes(nft.collection))
          .map((nft, i) => (
            <InventoryThumbnail
              nft={nft}
              key={`inventory-land-chest-nfts-item-key-${i}`}
            />
          ))}
        {context.nftState?.tokenNfts
          .filter((nft) => collectionsToDisplay.includes(nft.collection))
          .map((nft, i) => (
            // <NFTThumbnail
            //   nft={nft}
            //   key={`inventory-token-nft-item-key-${i}`}
            //   isVideo
            // />
            <InventoryThumbnail
              nft={nft}
              key={`inventory-token-nft-item-key-${i}`}
            />
          ))}
        {/* {context.nftState?.stakedKosonBatches
          .filter((nft) => collectionsToDisplay.includes(nft.collection))
          .map((nft, i) => (
            <NFTThumbnail
              nft={nft}
              key={`inventory-staked-koson-batch-item-key-${i}`}
            />
          ))} */}

        {context.nftState?.accountSoulNfts.length === 0 &&
          context.nftState.accountLandChests.length === 0 &&
          context.nftState.tokenNfts.length === 0 &&
          context.nftState.stakedKosonBatches.length === 0 && (
            <div className='text-center mt-5'>
              <FontAwesomeIcon icon={faWind} className='mt-3 mb-5' size='10x' />
              <h3 className='mt-2'>No items to display in your inventory</h3>
            </div>
          )}
        <div className='modal fade' id='random-test-modal'>
          <ListNftModal nft={selectedNft} />
        </div>
      </div>
    </>
  );
};

export default Inventory;
