import React from 'react';
import { AccountOrderState } from 'types';
import { PAYMENT_PROVIDERS } from 'pages/LandChestSale/CheckoutCard/CheckoutSelectPaymentProvider';
import { cancelUtrustOrder, getPendingOrderStatus } from 'api/backendRequests';

const useUtrustOrders = ({
  address
}: {
  address: string;
}): IUtrustOrdersType => {
  const [accountOrderState, setAccountOrderState] =
    React.useState<AccountOrderState>();
  const [canPlaceNewOrder, setCanPlaceNewOrder] = React.useState<boolean>(true);
  const [step1PaymentProvider, setStep1PaymentProvider] =
    React.useState<PAYMENT_PROVIDERS>();
  const [step2PaymentProvider, setStep2PaymentProvider] =
    React.useState<PAYMENT_PROVIDERS>();
  const [currentPaymentStep, setCurrentPaymentStep] = React.useState(0);
  const [canResumeUtrustOrder, setCanResumeUtrustOrder] = React.useState(false);
  const [pendingOrderCartItems, setPendingOrderCartItems] = React.useState<
    {
      nonce: number;
      quantity: number;
    }[]
  >([]);

  React.useEffect(() => {
    if (!address.startsWith('erd')) {
      return;
    }
    refreshState().then(() => {
      // console.log(accountOrderState);
    });
  }, []);

  const refreshState = async () => {
    const pendingOrderState = await getPendingOrderStatus(address);
    if (pendingOrderState.success) {
      const orderState: AccountOrderState = pendingOrderState.data;
      //TODO: remove this
      // orderState.waitingForUtrustPaymentCompletion = true;
      // console.log('orderState webhook', orderState);
      setAccountOrderState(orderState);
      setCanPlaceNewOrder(!orderState.hasOrder);
      if (orderState.hasOrder) {
        setStep1PaymentProvider(PAYMENT_PROVIDERS.Utrust);
        setStep2PaymentProvider(PAYMENT_PROVIDERS.KOSON);
        setCanResumeUtrustOrder(
          orderState.redirectUrl !== null && !orderState.awaitsKosonPayment
        );
        setCurrentPaymentStep(orderState.awaitsKosonPayment ? 4 : 2);
      } else {
        setStep1PaymentProvider(PAYMENT_PROVIDERS.Utrust);
        setStep2PaymentProvider(PAYMENT_PROVIDERS.KOSON);
        setCanResumeUtrustOrder(false);
        setCurrentPaymentStep(orderState.awaitsKosonPayment ? 4 : 2);
      }
    }
  };

  const cancelOrder = async (orderId: string) => {
    await cancelUtrustOrder(orderId);
    await refreshState();
  };

  return {
    orderState: accountOrderState,
    canPlaceOrder: canPlaceNewOrder,
    step1PaymentProvider: step1PaymentProvider,
    step2PaymentProvider: step2PaymentProvider,
    currentPaymentStep: currentPaymentStep,
    canResumeUtrustOrder: canResumeUtrustOrder,
    pendingOrderCartItems: pendingOrderCartItems,
    cancelUtrustOrder: cancelOrder,
    refreshState: refreshState
  };
};
export default useUtrustOrders;

export interface IUtrustOrdersType {
  orderState: AccountOrderState | undefined;
  canPlaceOrder: boolean;
  step1PaymentProvider?: PAYMENT_PROVIDERS;
  step2PaymentProvider?: PAYMENT_PROVIDERS;
  currentPaymentStep?: number;
  canResumeUtrustOrder?: boolean;
  pendingOrderCartItems?: { nonce: number; quantity: number }[];
  cancelUtrustOrder: (orderId: string) => Promise<void>;
  refreshState: () => Promise<any>;
}
