import React from 'react';
import axios from 'axios';
import {
  AllNFTCollections,
  apiAddress,
  KOSON_TICKER,
  LandChestContentCollections,
  LandChestNFTCollection,
  landChestOpeningContractAddress,
  landChestSaleContractAddress,
  LandPlotsTokenIdentifier,
  nftStakingContractAddress,
  SMARTCONTRACT_ADDRESSES,
  StakedKosonCollections
} from 'config';

const fetchKosonBalance = (tokenIdentifier: string) =>
  async function getKosonBalance(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/tokens?identifier=${tokenIdentifier}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchActivityInfo = () =>
  async function getActivityInfo(address: string) {
    try {
      const receivers = SMARTCONTRACT_ADDRESSES.join(',');
      const { data } = await axios.get(
        `${apiAddress}/transactions?sender=${address}&status=success&receiver=${receivers}&size=1000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountNfts = (tokenIdentifiers: string[]) =>
  async function getAccountNfts(address: string) {
    try {
      const collections = tokenIdentifiers.join(',');
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts?collections=${collections}&size=10000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountNftsCount = (address: string) =>
  async function getAccountNftsCount() {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts/count`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchAccountSmartContracts = () =>
  async function getAccountSmartContracts(address: string) {
    try {
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/contracts?size=10000`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchSmartContractOwnerAddress = () =>
  async function getSmartContractOwnerAddress(address: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/accounts/${address}`);
      return {
        data: data.ownerAddress,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchSpecificAccountNfts = (address: string, identifiers: string[]) =>
  async function getSpecificAccountNfts() {
    try {
      let collectionsParam = '';
      if (identifiers.length > 0) {
        collectionsParam = '?collections=' + identifiers.join(',');
      }
      const { data } = await axios.get(
        `${apiAddress}/accounts/${address}/nfts${collectionsParam}`
      );
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

const fetchTransactionInfo = () =>
  async function getTransactionInfo(txHash: string) {
    try {
      const { data } = await axios.get(`${apiAddress}/transactions/${txHash}`);
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false,
        data: []
      };
    }
  };

export const getKosonBalance = fetchKosonBalance(KOSON_TICKER);
export const getActivityTransactions = fetchActivityInfo();
export const getValidAccountNfts = fetchAccountNfts(AllNFTCollections);
export const getTotalStakedSoulNfts = fetchAccountNftsCount(
  nftStakingContractAddress
);
export const getLandChestNfts = fetchAccountNfts([LandChestNFTCollection]);
export const getLandPlotNfts = fetchAccountNfts([LandPlotsTokenIdentifier]);
export const getAccountSmartContracts = fetchAccountSmartContracts();
export const getSmartContractOwnerAddress = fetchSmartContractOwnerAddress();
export const getRemainingLandChests = fetchSpecificAccountNfts(
  landChestSaleContractAddress,
  []
);
export const getRemainingChestContent = fetchSpecificAccountNfts(
  landChestOpeningContractAddress,
  [LandChestContentCollections[0]]
);
export const getStakedKoson = (address: string) =>
  fetchSpecificAccountNfts(address, StakedKosonCollections);
export const getTransactionInfo = fetchTransactionInfo();
