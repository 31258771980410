import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import Footer from './Footer';
import { ReactComponent as Logo } from '../../assets/images/aoz/logo.svg';
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers';
import SideBar from './Sidenav2';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <nav className='navbar sticky-top navbar-expand-lg'>
        <div className='logo-box p-0' style={{ zIndex: 0 }}>
          <Logo width={40} height={40} className='logo-min' />{' '}
          <img
            src={'images/aoz/logoaoz.png'}
            className={`logo ${isCollapsed ? 'd-none' : ''}`}
            alt='Logo'
            height={50}
          />
        </div>
        <FontAwesomeIcon
          icon={faBars as any}
          className='collapse-btn'
          style={{ left: isCollapsed ? '190px' : '300px' }}
          onClick={() => setIsCollapsed(!isCollapsed)}
          role='button'
          size='2x'
        />
      </nav>
      <div className='w-100 bg-dark d-flex align-items-center'></div>
      <div className='d-flex flex-row'>
        <SideBar
          isCollapsed={isCollapsed}
          toggleCollapse={() => setIsCollapsed(!isCollapsed)}
        />
        <main className='main-content'>
          <AuthenticatedRoutesWrapper
            routes={routes}
            unlockRoute={`${routeNames.unlock}${search}`}
          >
            <div className='container-fluid'>{children}</div>
          </AuthenticatedRoutesWrapper>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Layout;
