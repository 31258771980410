import React, { useEffect } from 'react';
import {
  useGetIsLoggedIn,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import BigNumber from 'bignumber.js';
import {
  debugAddress,
  kosonStakingPools,
  landChestSaleContractAddress,
  landChestStakingContractAddress,
  marketplaceContractAddress,
  nftStakingContractAddress,
  summoningContractAddress,
  vestingContractAddress
} from 'config';
import useLandChestSaleInfo, {
  ILandChestSaleInfoType
} from 'hooks/useLandChestSaleInfo';
import useMarketplaceInfo, {
  IMarketplaceInfoType
} from 'hooks/useMarketplaceInfo';
import useUtrustOrders, { IUtrustOrdersType } from 'hooks/useUtrustOrders';
import useAccountInfo, { IAccountInfoType } from '../../hooks/useAccountInfo';
import useAccountNftInfo, {
  IAccountNftInfoType
} from '../../hooks/useAccountNftInfo';
import useKosonStakingPoolInfo, {
  IKosonStakingStateType
} from '../../hooks/useKosonStakingPoolInfo';
import useLandChestStakingInfo, {
  ILandChestStakingInfoType
} from '../../hooks/LandChestStakingInfo';
import useNftStakingInfo, {
  INFTStakingType
} from '../../hooks/useNftStakingInfo';
import usePriceInfo, { IPriceInfoType } from '../../hooks/usePriceInfo';
import useSummoningInfo, {
  ISummoningInfoType
} from '../../hooks/useSummoningInfo';
import useVestingInfo, { IVestingInfoType } from '../../hooks/useVestingInfo';
import { useCustomDispatch } from 'store/useCustomDispatch';
import { Transaction } from '@multiversx/sdk-core/out';

export interface IWeb3Context {
  address: string;
  accountState?: IAccountInfoType;
  priceState?: IPriceInfoType;
  nftState?: IAccountNftInfoType;
  stakedLandChestsState?: ILandChestStakingInfoType;
  vestingState?: IVestingInfoType;
  nftStakingState?: INFTStakingType;
  kosonStakingState?: IKosonStakingStateType;
  summoningState?: ISummoningInfoType;
  marketplaceState?: IMarketplaceInfoType;
  refreshEntireState: () => void;
  orderState?: IUtrustOrdersType;
  landChestSaleState?: ILandChestSaleInfoType;
  sendTransaction: (
    receiver: string,
    gasLimit: number,
    data: string,
    value?: BigNumber
  ) => Promise<void>;
  sendTransactionV2: (transaction: Transaction) => Promise<void>;
}

const defaultState = {
  address: '',
  refreshEntireState: () => {
    // do nothing
  },
  sendTransaction: async () => {
    // do nothing
  },
  sendTransactionV2: async () => {
    // do nothing
  }
};

export const Web3Context = React.createContext<IWeb3Context>(defaultState);

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const [currentSessionId, setCurrentSessionId] = React.useState<string>('');
  const transactionStatus = useTrackTransactionStatus({
    transactionId: currentSessionId,
    onSuccess: () =>
      onSuccessWrapperHandle().then(() => {
        customDispatch.dispatchAllAccountInfo(accountState.account.address);
      }),
    onCancelled: () =>
      onCancelledWrapperHandle().then(() => {
        /* */
      }),
    onFail: () =>
      onFailedWrapperHandle().then(() => {
        /* */
      })
  });

  const accountState = useAccountInfo();
  const { address } =
    debugAddress.address !== '' ? debugAddress : accountState?.account;
  const isLoggedIn = useGetIsLoggedIn();
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    if (address && isLoggedIn) {
      customDispatch.dispatchAllAccountInfo(address);
    }
  }, [address]);

  useEffect(() => {
    customDispatch.dispatchAllStats();
  }, []);

  const priceState = usePriceInfo();
  const nftState = useAccountNftInfo({ address });
  const vestingState = useVestingInfo({ address });
  const stakedNftsState = useNftStakingInfo({ address });
  const stakedLandChestsState = useLandChestStakingInfo({ address });
  const kosonStakingState = useKosonStakingPoolInfo();
  const summoningState = useSummoningInfo({ address });
  const marketplaceState = useMarketplaceInfo({ address });
  const orderState = useUtrustOrders({ address });
  const landChestSaleState = useLandChestSaleInfo({ address });
  React.useEffect(() => {
    vestingState.refreshState();
  }, []);
  const onSuccessWrapperHandle = async () => {
    await handleTxSuccess();
    if (transactionStatus.transactions) {
      const id = setTimeout(async () => {
        await handleTxSuccess();
        clearTimeout(id);
      }, 3000);
    }
  };

  const handleTxSuccess = async () => {
    if (!transactionStatus.transactions) {
      await refreshAllStates();
      return;
    }
    const transaction = transactionStatus.transactions[0];
    switch (transaction.receiver) {
      case vestingContractAddress:
        await vestingState.refreshState();
        break;
      case nftStakingContractAddress:
        await nftState.refreshEntireState();
        await stakedNftsState.refreshState();
        break;
      case landChestStakingContractAddress:
        await stakedLandChestsState.refreshState();
        break;
      case summoningContractAddress:
        await summoningState.refreshState();
        break;
      case landChestSaleContractAddress:
        await orderState.refreshState();
        await landChestSaleState.refreshState();
        break;
      case marketplaceContractAddress:
        await nftState.refreshEntireState();
        await accountState.refreshState();
        await marketplaceState.refreshState();
        break;
      default:
        if (kosonStakingPools.includes(transaction.receiver)) {
          await nftState.refreshEntireState();
          await kosonStakingState.refreshState();
        }
        break;
    }
    await accountState.refreshState();
    await priceState.refreshState();
  };

  const onCancelledWrapperHandle = async () => {
    //
  };

  const onFailedWrapperHandle = async () => {
    //
  };

  const sendTransactionWrapper = async (
    receiver: string,
    gasLimit: number,
    data: string,
    value?: BigNumber
  ) => {
    if (gasLimit > 600_000_000) {
      gasLimit = 600_000_000;
    }

    const transaction = {
      receiver: receiver,
      gasLimit: gasLimit,
      data: data,
      value: value ?? new BigNumber(0)
    };
    const { sessionId } = await sendTransactions({
      transactions: [transaction],
      transactionsDisplayInfo: {
        processingMessage: 'Processing your transaction',
        errorMessage: 'An error has occured during processing your transaction',
        successMessage: 'Transaction processed successfully',
        transactionDuration: 10000
      }
    });
    setCurrentSessionId(sessionId);
  };

  const refreshAllStates = async () => {
    await accountState.refreshState();
    await priceState.refreshState();
    await nftState.refreshEntireState();
    await vestingState.refreshState();
    await stakedNftsState.refreshState();
    await stakedLandChestsState.refreshState();
    await kosonStakingState.refreshState();
    await summoningState.refreshState();
    await marketplaceState.refreshState();
    await orderState.refreshState();
    // await landChestSaleState.refreshState();
  };

  const sendTransactionV2 = async (transaction: Transaction) => {
    const value = transaction.getValue().toString();
    await sendTransactionWrapper(
      transaction.getReceiver().bech32(),
      transaction.getGasLimit().valueOf(),
      transaction.getData().toString(),
      new BigNumber(value || '0')
    );
  };

  return (
    <Web3Context.Provider
      value={{
        address,
        accountState: accountState,
        priceState: priceState,
        nftState: nftState,
        vestingState: vestingState,
        nftStakingState: stakedNftsState,
        stakedLandChestsState: stakedLandChestsState,
        kosonStakingState: kosonStakingState,
        summoningState: summoningState,
        marketplaceState: marketplaceState,
        orderState: orderState,
        landChestSaleState: landChestSaleState,
        refreshEntireState: refreshAllStates,
        sendTransaction: sendTransactionWrapper,
        sendTransactionV2: sendTransactionV2
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
