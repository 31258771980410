import React from 'react';
import { AddressValue, Address } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { getLandChestNfts, getLandPlotNfts } from 'api/apiRequests';
import {
  LandChestNFTCollection,
  landChestStakingContractAddress,
  LANDCHEST_UNBONDING_TIME_PENALTY,
  LandPlotsTokenIdentifier
} from 'config';
import {
  getSmartContract,
  Provider,
  Parser
} from 'contexts/Web3Context/helpers/getScObj';
import { transformStakedNft } from 'contexts/Web3Context/helpers/nftUtils';
import { AccountNft } from 'types';

const useLandChestStakingInfo = ({ address }: { address: string }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const DAILY_LAND_CHEST_STAKING_REWARDS: any = [
    1294.52054795, 5178.08219178, 7767.12328767, 11650.6849315
  ];
  const [stakedChests, setStakedChests] = React.useState<AccountNft[]>([]);
  const [pendingRewards, setPendingRewards] = React.useState(0);
  const [totalStakedChests, setTotalStakedChests] = React.useState<number[]>(
    []
  );
  const [dailyRewards, setDailyRewards] = React.useState<number[]>([]);
  const [totalDailyReward, setTotalDailyReward] = React.useState(0);
  const [unbondingChests, setUnbondingChests] = React.useState<AccountNft[]>(
    []
  );
  const [hasClaimableUnbondingChests, setHasClaimableUnbondingChests] =
    React.useState(false);

  const [stakedPlots, setStakedPlots] = React.useState<AccountNft[]>([]);
  const [unbondingPlots, setUnbondingPlots] = React.useState<AccountNft[]>([]);
  const [hasClaimableUnbondingPlots, setHasClaimableUnbondingPlots] =
    React.useState(false);

  React.useEffect(() => {
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, [address]);

  const refreshState = async () => {
    const chestInfo = await setChestInfo();
    const plotInfo = await setPlotInfo();
    await handleDailyRewardsComputation(
      chestInfo.userStakedChests,
      plotInfo.userStakedPlots
    );
    const userPendingRewards = await getUserPendingRewards();
    setPendingRewards(userPendingRewards);
  };

  const loadStakedChests = async () => {
    const stakedLandChests = await getLandChestNfts(
      landChestStakingContractAddress
    );
    if (stakedLandChests.success) {
      const stakedSupplies = [0, 0, 0, 0];
      for (let i = 0; i < stakedLandChests.data.length; i++) {
        stakedSupplies[stakedLandChests.data[i].nonce - 1] = parseInt(
          stakedLandChests.data[i].balance
        );
      }
      return stakedSupplies;
    }
    return [0, 0, 0, 0];
  };

  const loadStakedPlots = async () => {
    const stakedPlots = await getLandPlotNfts(landChestStakingContractAddress);
    if (stakedPlots.success) {
      const stakedSupplies = [0, 0, 0, 0];
      for (let i = 0; i < stakedPlots.data.length; i++) {
        stakedSupplies[5 - stakedPlots.data[i].nonce] = parseInt(
          stakedPlots.data[i].balance
        );
      }
      return stakedSupplies;
    }
    return [0, 0, 0, 0];
  };

  const handleDailyRewardsComputation = async (
    userStakedChests: any[],
    userStakedPlots: any[]
  ) => {
    const stakedChests = await loadStakedChests();
    const stakedPlots = await loadStakedPlots();
    const mergedSupplies = [];
    for (let i = 0; i < 5; i++) {
      mergedSupplies.push(stakedChests[i] + stakedPlots[i]);
    }
    setTotalStakedChests(mergedSupplies);
    const rewards = [0, 0, 0, 0];
    for (let i = 0; i < mergedSupplies.length; i++) {
      const dailyReward =
        DAILY_LAND_CHEST_STAKING_REWARDS[i] / mergedSupplies[i];
      rewards[i] = dailyReward;
    }
    setDailyRewards(rewards);

    let userTotalDailyReward = 0;
    for (let i = 0; i < userStakedChests.length; i++) {
      userTotalDailyReward +=
        rewards[userStakedChests[i].nonce - 1] * userStakedChests[i].amount;
    }

    for (let i = 0; i < userStakedPlots.length; i++) {
      userTotalDailyReward +=
        rewards[5 - userStakedPlots[i].nonce] * userStakedPlots[i].amount;
    }
    setTotalDailyReward(userTotalDailyReward);
  };

  const setChestInfo = async () => {
    const userStakedChests = await getUserStakedChests();
    setStakedChests(userStakedChests);
    const userUnbondingChests = await getUserUnbondingChests();
    setUnbondingChests(userUnbondingChests);
    setHasClaimableUnbondingChests(
      userUnbondingChests.filter((nft) => nft.canBeClaimed).length > 0
    );
    return {
      userStakedChests,
      userUnbondingChests
    };
  };

  const setPlotInfo = async () => {
    const userStakedPlots = await getUserStakedPlots();
    setStakedPlots(userStakedPlots);
    const userUnbondingPlots = await getUserUnbondingPlots();
    setUnbondingPlots(userUnbondingPlots);
    setHasClaimableUnbondingPlots(
      userUnbondingPlots.filter((nft) => nft.canBeClaimed).length > 0
    );
    return {
      userStakedPlots,
      userUnbondingPlots
    };
  };

  const getUserStakedChests = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getUserStakedChests([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const stakedNfts = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < stakedNfts.length; i++) {
        const parsedNft = transformStakedNft(
          LandChestNFTCollection,
          stakedNfts[i].nonce.toNumber(),
          stakedNfts[i].reward.toNumber()
        );
        items.push(parsedNft);
      }
      return items;
    }
    return [];
  };

  const getUserStakedPlots = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getUserStakedPlots([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const stakedNfts = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < stakedNfts.length; i++) {
        const parsedNft = transformStakedNft(
          LandPlotsTokenIdentifier,
          stakedNfts[i].nonce.toNumber(),
          stakedNfts[i].reward.toNumber()
        );
        items.push(parsedNft);
      }
      return items;
    }
    return [];
  };

  const getUserUnbondingChests = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getUserPendingUnstake([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const stakedNfts = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < stakedNfts.length; i++) {
        const unstakeTime = stakedNfts[i].field0.toNumber();
        const expectedClaimTime =
          unstakeTime + LANDCHEST_UNBONDING_TIME_PENALTY;
        const parsedNft = transformStakedNft(
          LandChestNFTCollection,
          stakedNfts[i].field1.nonce.toNumber(),
          stakedNfts[i].field1.reward.toNumber(),
          expectedClaimTime
        );
        items.push(parsedNft);
      }
      return items;
    }
    return [];
  };

  const getUserUnbondingPlots = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getUserPlotPendingUnstake([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const items = [];
      const stakedNfts = parsedResponse.firstValue?.valueOf();
      for (let i = 0; i < stakedNfts.length; i++) {
        const unstakeTime = stakedNfts[i].field0.toNumber();
        const expectedClaimTime =
          unstakeTime + LANDCHEST_UNBONDING_TIME_PENALTY;
        const parsedNft = transformStakedNft(
          LandPlotsTokenIdentifier,
          stakedNfts[i].field1.nonce.toNumber(),
          stakedNfts[i].field1.reward.toNumber(),
          expectedClaimTime
        );
        items.push(parsedNft);
      }
      return items;
    }
    return [];
  };

  const getUserPendingRewards = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getPendingRewards([
      addressArg
    ]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value.dividedBy(new BigNumber(10).pow(18)).toNumber();
    }
    return 0;
  };

  const getStakingTime = async () => {
    const addressArg = new AddressValue(new Address(address));

    const contract = await getSmartContract(landChestStakingContractAddress);
    const interaction = contract.methodsExplicit.getStakedTime([addressArg]);
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value;
    }
    return 0;
  };

  const calculateClaimRewardsGasLimit = async () => {
    const stakeTime = await getStakingTime();
    const time = new Date().getTime() / 1000;
    const daysSinceStaking = (time - stakeTime) / (24 * 3600);
    let gasLimit = daysSinceStaking * 1_200_000;
    if (gasLimit < 25_000_000) {
      gasLimit = 25_000_000;
    }
    if (gasLimit > 600_000_000) {
      gasLimit = 600_000_000;
    }
    return Math.round(gasLimit);
  };

  return {
    stakedChests,
    unbondingChests,
    hasClaimableUnbondingChests,
    stakedPlots,
    unbondingPlots,
    hasClaimableUnbondingPlots,

    totalDailyReward,
    pendingRewards,
    totalStakedChests,
    dailyRewards,
    refreshState,
    isLoading,
    calculateClaimRewardsGasLimit
  };
};

export default useLandChestStakingInfo;

export interface ILandChestStakingInfoType {
  stakedChests: AccountNft[];
  unbondingChests: AccountNft[];
  hasClaimableUnbondingChests: boolean;

  stakedPlots: AccountNft[];
  unbondingPlots: AccountNft[];
  hasClaimableUnbondingPlots: boolean;

  totalDailyReward: number;
  pendingRewards: number;
  totalStakedChests: number[];
  dailyRewards: number[];
  refreshState: () => Promise<void>;
  isLoading: boolean;
  calculateClaimRewardsGasLimit: () => Promise<number>;
}
