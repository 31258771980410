import { explorerAddress } from 'config';
import { ACTIVITY_INTERACTION_TYPES } from './../../types';
import React from 'react';
import { getTimeSince } from 'contexts/Web3Context/helpers/generalUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const ActivityInfo = ({
  timestamp,
  interaction,
  message,
  valueMessage,
  txHash
}: {
  timestamp: number;
  interaction: ACTIVITY_INTERACTION_TYPES;
  message: string;
  valueMessage: string;
  txHash: string;
}) => {
  const [activityTimePassedSince, setActivityTimePassedSince] =
    React.useState('');

  React.useEffect(() => {
    if (activityTimePassedSince === '') {
      setActivityTimePassedSince(getTimeSince(timestamp));
    }
  }, []);

  const getActivityColorCode = () => {
    // primary - marketplace, swaps
    // info - origin migrate, vesting
    // danger - summoning
    // success - nftStaking, landChestStaking
    // warning - landchestSale
    // dark - kosonStaking

    switch (interaction) {
      case ACTIVITY_INTERACTION_TYPES.Vesting:
        return 'info';
      case ACTIVITY_INTERACTION_TYPES.OriginMintOrMigrate:
        return 'info';
      case ACTIVITY_INTERACTION_TYPES.Marketplace:
        return 'primary';
      case ACTIVITY_INTERACTION_TYPES.NftStaking:
        return 'success';
      case ACTIVITY_INTERACTION_TYPES.NftSwaps:
        return 'primary';
      case ACTIVITY_INTERACTION_TYPES.Summoning:
        return 'danger';
      case ACTIVITY_INTERACTION_TYPES.LandChestStaking:
      case ACTIVITY_INTERACTION_TYPES.LandChestOpening:
        return 'success';
      case ACTIVITY_INTERACTION_TYPES.LandChestSale:
        return 'warning';
      case ACTIVITY_INTERACTION_TYPES.KosonStaking:
        return 'dark';
      default:
        return 'info';
    }
  };

  return (
    <>
      <a
        className={`row timeline-badge mt-2 mb-2 rounded ${getActivityColorCode()}`}
        href={`${explorerAddress}/transactions/${txHash}`}
        target='_blank'
        rel='noreferrer'
      >
        <div className='col-sm-12 col-md-10 d-flex align-items-center'>
          <FontAwesomeIcon icon={faUpRightFromSquare} className='me-2' />
          <h6 className='mb-0'>
            {message}{' '}
            {valueMessage && (
              <strong className='text-warning'>{valueMessage}</strong>
            )}
          </h6>
        </div>
        <div className='col-sm-12 col-md-2 text-end'>
          <span className='text-muted'>{activityTimePassedSince} ago</span>
        </div>
      </a>
    </>
  );
};

export default ActivityInfo;
