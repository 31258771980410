import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export interface ConfirmModalProps {
  title?: string;
  prompt?: string;
  onConfirm: () => void;
  onDecline?: () => void;
  buttonComponent: React.ReactNode;
}

export const ConfirmModal = ({
  title,
  prompt,
  onConfirm,
  onDecline,
  buttonComponent
}: ConfirmModalProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleAgreement = () => {
    setShowModal(false);
    onConfirm();
  };

  const handleDecline = () => {
    setShowModal(false);
    if (onDecline) {
      onDecline();
    }
  };

  return (
    <>
      <div onClick={() => setShowModal(true)}>{buttonComponent}</div>
      <Modal show={showModal} size='lg' centered>
        <Modal.Header style={{ border: 'none' }} className='p-4'>
          {title ?? 'Confirm action'}
        </Modal.Header>
        <Modal.Body className='p-5'>
          <span style={{ fontSize: 'large' }}>{prompt}</span>
        </Modal.Body>
        <Modal.Footer
          className='d-flex justify-content-between p-4'
          style={{ border: 'none' }}
        >
          <button className='btn btn-primary' onClick={handleAgreement}>
            Proceed
          </button>
          <button className='btn btn-danger' onClick={handleDecline}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
