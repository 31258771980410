import BigNumber from 'bignumber.js';
import { MarketplaceListing, MarketplaceListingV2 } from 'types';

export type StoreStatusType = 'idle' | 'loading' | 'succeeded' | 'failed';

export const mergeStatuses = (statuses: StoreStatusType[]): StoreStatusType => {
  if (statuses.filter((s) => s === 'failed').length > 0) {
    return 'failed';
  }

  if (statuses.filter((s) => s === 'loading').length > 0) {
    return 'loading';
  }

  return 'succeeded';
};

export const getAvailableListingInteractions = (
  userInfo: {
    address: string;
    balance: string;
  },
  listing: MarketplaceListing | MarketplaceListingV2
): AvailableListingInteractions => {
  const isOwner = listing.originalOwnerAddress === userInfo.address;
  const isWinner = listing.currentWinnerAddress === userInfo.address;
  const hasBids =
    !listing.isBuyout && listing.currentWinnerAddress.startsWith('erd1');
  const canCancel = isOwner && !hasBids;
  const canClaim = (isOwner || isWinner) && listing.hasExpired;
  const hasFunds =
    userInfo.address !== listing.originalOwnerAddress &&
    new BigNumber(userInfo.balance).isGreaterThan(listing.minBid);
  const canBid =
    !listing.hasExpired &&
    !isOwner &&
    !isWinner &&
    !listing.isBuyout &&
    hasFunds;
  const canBuy =
    !listing.hasExpired && !isOwner && listing.isBuyout && hasFunds;

  return {
    hasFunds,
    isOwner,
    canBuy,
    canBid,
    canClaim,
    canCancel
  };
};

export interface AvailableListingInteractions {
  hasFunds: boolean;
  isOwner: boolean;
  canBuy: boolean;
  canBid: boolean;
  canClaim: boolean;
  canCancel: boolean;
}
