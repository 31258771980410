import { EVENT_SETTINGS } from 'config';
import { Web3Context } from 'contexts/Web3Context';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import BigNumber from 'bignumber.js';
import PendingPaymentContainer from './PendingPaymentContainer';
import { NewSummoningContainer } from './NewSummoning';

const SummoningProcessContainer = () => {
  const { summoningState, nftState } = React.useContext(Web3Context);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [expectedKosonPayment, setExpectedKosonPayment] = React.useState(
    new BigNumber(0)
  );
  const [expectedFeePayment, setExpectedFeePayment] = React.useState(
    new BigNumber(0)
  );
  const [summoningId, setSummoningId] = React.useState(0);

  React.useEffect(() => {
    if (!summoningState) {
      return;
    }
    if (summoningState.pendingSummoningProcesses.length > 0) {
      const currentSummoning = summoningState.pendingSummoningProcesses[0];
      if (
        currentSummoning.payment.koson_payment_time.isEqualTo(new BigNumber(0))
      ) {
        setCurrentStep(1);
        setExpectedKosonPayment(
          currentSummoning.expected_koson_payment || new BigNumber(0)
        );
      } else if (
        currentSummoning.payment.koson_payment_time.isGreaterThan(
          new BigNumber(0)
        ) ||
        currentSummoning.payment.egld_payment_time.isEqualTo(new BigNumber(0))
      ) {
        setCurrentStep(2);
        setExpectedFeePayment(
          currentSummoning.expected_egld_payment || new BigNumber(0)
        );
      }
      setSummoningId(currentSummoning.summoning_id.toNumber());
    } else {
      setCurrentStep(0);
    }
  }, [
    summoningState?.completedSummoningProcesses,
    summoningState?.pendingSummoningProcesses,
    nftState?.accountSoulNfts
  ]);
  return (
    <div
      className='card text-center'
      id='card-title-3'
      style={{ height: '600px' }}
    >
      <div className='card-header d-flex justify-content-between'>
        <h5 className='card-title'>New summoning</h5>
        <FontAwesomeIcon
          icon={faCircleInfo}
          data-tip='Summoning a new soul is a 3 step process: deposit NFTs, deposit $KOSON offering, pay the summoning fee'
          size='1x'
        />
        <ReactTooltip />
      </div>
      <div className='card-body with-scroll'>
        <div className='row'>
          {currentStep === 0 && <NewSummoningContainer />}
          {currentStep === 1 && (
            <PendingPaymentContainer
              summoningId={summoningId}
              isKosonOffering={true}
              stepNo={2}
              expectedPayment={expectedKosonPayment}
            />
          )}
          {currentStep === 2 && (
            <PendingPaymentContainer
              summoningId={summoningId}
              isKosonOffering={EVENT_SETTINGS.bendisCall}
              stepNo={3}
              expectedPayment={expectedFeePayment}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummoningProcessContainer;
