import { DebugType, EventSettings } from 'types';

export const debugAddress: DebugType = {
  // address: 'erd1s0spgae348y5hsdw9m744n5lhue6zw3p7m7p0skcn04wrdtq57csa85ccg'
  address: ''
};

export const contractAddress =
  'erd1qqqqqqqqqqqqqpgq72l6vl07fkn3alyfq753mcy4nakm0l72396qkcud5x';

export const dAppName = 'Age Of Zalmoxis';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
// export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';
export const walletConnectV2ProjectId = '42c7a7e62eabcc790986a821ff4b143a';

export const KOSON_TICKER = 'KOSON-5dd4fa';
// export const apiAddress =
//   'https://elrond-api.blastapi.io/c2485369-76d3-435f-bd00-41a93b35dd93';
// export const gatewayAddress =
//   'https://elrond-mainnet.blastapi.io/c2485369-76d3-435f-bd00-41a93b35dd93';
export const apiAddress = 'https://api.multiversx.com';
export const gatewayAddress = 'https://gateway.multiversx.com';
export const chainId = 1;
export const explorerAddress = 'https://explorer.multiversx.com';
// export const backendApiAddress = 'https://aoz-web-be.azurewebsites.net';
export const backendApiAddress = 'https://ageofzamolxis.com';
// export const backendApiAddress = 'http://localhost:5000';

export const toLocaleStringOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export const toLocaleStringOptionsNoDecimals = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

export const kosonStakingPools = [
  'erd1qqqqqqqqqqqqqpgqrg2yady60jezk3zpks0hr4uzq9cslt4jyl5s2rdn3h',
  'erd1qqqqqqqqqqqqqpgqgts4dfjyh28p07zjye326kwdqq58wsasyl5s2duk0j',
  'erd1qqqqqqqqqqqqqpgqt2czgzjm4j7enrer9uqxyd0tdvkkqaw2yl5sdpmuk9',
  'erd1qqqqqqqqqqqqqpgqvlnjqtqnxpyt87qgczgj5tc08n3007aqyl5sp726e9',
  'erd1qqqqqqqqqqqqqpgqk05lklxqd679zf30qfc90wl3ahcf4s0myl5slvw9xz',
  'erd1qqqqqqqqqqqqqpgqhp5ex0at4e8fy2gen0vl2a8k6ln7f325yl5sk3yzk8'
];
export const kosonStakingPoolNames = [
  'Plebea',
  'Comati',
  'Pileati',
  'Tarabostes',
  'Strategos',
  'Basileus'
];
export const vestingContractAddress =
  'erd1qqqqqqqqqqqqqpgqe5nrv2wpcq8jrc307klfhpg7xxc2xszqlwfqymjaxd';
export const mintingContractAddress =
  'erd1qqqqqqqqqqqqqpgqv9pzdh94lnr2s9aha3zlf4yxmjuhjwhtyl5squ2whs';
export const marketplaceContractAddress =
  'erd1qqqqqqqqqqqqqpgqfz8nmyy6gfvn48hpffnlnmulnnuzx25cyl5s9w8m2v';
export const nftStakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqz5jh90tx3d45rjxd8yqkmgt69dqp5ckeyl5s34wdmf';
export const nftSwapsContractAddress =
  'erd1qqqqqqqqqqqqqpgqkv9uee67h5rq6604zfczdmupvtgqymkeyl5sy6lkvh';
export const summoningContractAddress =
  'erd1qqqqqqqqqqqqqpgqcuws9ujvmp984x53y3f4dg7h66axualuyl5sxdhdxw';
// 'erd1qqqqqqqqqqqqqpgqjlcgewzlgzy0g8u6ne9zpy673jmp5nsg6ppsemng8m';//devnet
export const landChestSaleContractAddress =
  'erd1qqqqqqqqqqqqqpgq68rc5hdt9ssnzvufe056sc5nxelqygjtyl5s066emh';
export const landChestStakingContractAddress =
  'erd1qqqqqqqqqqqqqpgqyg86jc0u9tuxyn49fzcyygjtpcqjmzajyl5sep0a4g';
export const landChestOpeningContractAddress =
  'erd1qqqqqqqqqqqqqpgq5hyj8le8xpedcmqjw2444azcvjv6derfyl5sc29r66';

export const SMARTCONTRACT_ADDRESSES = [
  'erd1qqqqqqqqqqqqqpgqrg2yady60jezk3zpks0hr4uzq9cslt4jyl5s2rdn3h',
  'erd1qqqqqqqqqqqqqpgqgts4dfjyh28p07zjye326kwdqq58wsasyl5s2duk0j',
  'erd1qqqqqqqqqqqqqpgqt2czgzjm4j7enrer9uqxyd0tdvkkqaw2yl5sdpmuk9',
  'erd1qqqqqqqqqqqqqpgqvlnjqtqnxpyt87qgczgj5tc08n3007aqyl5sp726e9',
  'erd1qqqqqqqqqqqqqpgqk05lklxqd679zf30qfc90wl3ahcf4s0myl5slvw9xz',
  'erd1qqqqqqqqqqqqqpgqhp5ex0at4e8fy2gen0vl2a8k6ln7f325yl5sk3yzk8',
  vestingContractAddress,
  mintingContractAddress,
  marketplaceContractAddress,
  nftStakingContractAddress,
  nftSwapsContractAddress,
  summoningContractAddress,
  landChestSaleContractAddress,
  landChestStakingContractAddress,
  landChestOpeningContractAddress
];

export const OriginNFTCollections = [
  'ORIGIN-fe949f',

  'AIRSOUL-1d1c12',
  'EARTHSOUL-7719e9',
  'FIRESOUL-7719e9',
  'LIFESOUL-fbbc7b',
  'AETHERSOUL-fbbc7b',
  'WATERSOUL-2d8eeb'
];

export const SummonedNFTCollections = [
  'SAIRSOUL-48f4fe',
  'SEARTHSOUL-01c5d1',
  'SFIRESOUL-01c5d1',
  'SLIFESOUL-01c5d1',
  'SAETHSOUL-48f4fe',
  'SWATERSOUL-9fbd17',
  'DEATHSOUL-9fbd17'
];

export const LandPlotsTokenIdentifier = 'LANDTOKENS-07db50';

export const LandChestContentCollections = [
  LandPlotsTokenIdentifier,
  'WTOKENS-1f9216',
  'ATOKENS-92926e',
  'MISCTOKENS-b160cf',
  'CTOKENS-d87759'
];

export const StakedKosonCollections = [
  'SKOSON-1dd90e',
  'SKOSON-491e23',
  'SKOSON-75b733',
  'SKOSON-eaa2d2',
  'SKOSON-10c2de',
  'SKOSON-fe3fca'
];
export const LandChestNFTCollection = 'LANDCHEST-1f6b76';

export const SoulsNFTCollections = OriginNFTCollections.concat(
  SummonedNFTCollections
);
export const AllNFTCollections = SoulsNFTCollections.concat(
  StakedKosonCollections
)
  .concat(LandChestNFTCollection)
  .concat(LandChestContentCollections);

export const NFT_UNBONDING_TIME_PENALTY = 3600 * 24 * 7;
export const NFT_SUMMONING_TIME_PENALTY = 3600 * 24 * 7;
export const LANDCHEST_UNBONDING_TIME_PENALTY = 3600 * 24; // * 7;
export const KosonStakingStartTime = 1663718400;

export const stakePoolUnlockPenaltiesDays = [1, 90, 180, 365, 2 * 365, 4 * 365];

export const LandChestNames = ['Continental', 'Steepe', 'Panonic', 'Pontic'];
export const adminAddresses = [
  'erd1042x0s9pknlpdfhtjz5s7wm46rkewpsrcnr7wt6qvuketyv2g06qnwr2lx',
  'erd1j43ssgjr8x9m0s7zz0xhn7qdgssdetm86jc6a2dzsegs74fmyl5ssv44c4',
  'erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg'
];

export const EVENT_SETTINGS: EventSettings = {
  bendisCall: false,
  onlyKosonChestPurchase: true
};
export const PLATFORM_ID = '2';

export const OGList = [
  'erd17mknzc69kd4jkyhtdshxm05p6z3mlv5refer8g29g3sq857dqlgqe0hdnn',
  'erd1rzut9n7wye379gxfc9f0380prseyvzs00p6sev3ekdpvwztgqens809379',
  'erd1hnehm682lm4mrl0v05e79yk830mq8n25tt3c9du0c5nlt9kaeytscz9kgz',
  'erd1v6q80anj6fg6jxvlq69f6ughpmpz5u0fz7m9kenv6v8vg7jv7jlswlyss8',
  'erd1rgdzdj2h40cp0vgyjczccahy8klaa3r4wqdz4lfpqklk802f9dnq6t9swl',
  'erd1h6lh2tqjscs4n69c4w4wunu4qw2mz708qn8mqk4quzsyz2syn0aq5gu64s',
  'erd13denxmugk36lhy28e8a7q63xpqv5gr4q78d62c4hnh8rnlf5qpqq02aat0',
  'erd1hxr0wf25hd4mmwq8zfxmx7x7qz2lx8s27uq5cw4z2h796q7455jq0k96f5',
  'erd1ztcl4c7n98dld03k8xvftct9j0cdatrmpe7qchmpr89e9gj94qkqntx3ha',
  'erd1gq3ayxjtnwwfehx0e2yanee56k7rg845rwfshwjs5d4h95p4zquqshdg3y',
  'erd1wa7h9ea30j0enjm8k9x8ymf0s334qde8x4c3fpzkgj28xfkyt7nqdttpst',
  'erd1affy97gxpvjcl798903v7km3v2g8fden4k59zfektjttzy3kp5usqamuea',
  'erd1640c9n2cck2326jy0tg87nawhgxdxvzqg9psezg2yjzkxvg6gkcqzfdp6j',
  'erd1uvms684yx9frtv0x29zy5nlzrpdagar5wr2sfgengv77qsxs7q5s38quxp',
  'erd1yuf8crfqrsgqht597pfkrntxj2hkt5hrw4seza9r52hzwjj42ygssgmn4z',
  'erd17ycm0wxmc8t2my8ukhn7aknq9g32wyvdxn9s28tsnrctf86mp0ysatmdhz',
  'erd1kl5e5qfjreawehg5gmvz35gsswd69lms6e3r69p5v2e28esuevqsuznz04',
  'erd16w5vy79gfpdhkghqjz232e7mckec2aln4s4aa8yjc3s55v5wfnysm5a64y',
  'erd1zjgjafe24jnlk94gtxmqfd4690cm6n9le73gp3d94g98lc6ryfwshqt8dc',
  'erd1knx4hu2u6zxyt2mqk9zfyf8m9pz980puw98hqsmv26d3eggxvfmsv4xlpq',
  'erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg',
  'erd1042x0s9pknlpdfhtjz5s7wm46rkewpsrcnr7wt6qvuketyv2g06qnwr2lx',
  'erd1nl3vegf3eaj0k59at6cyj5xn5hjy34vwcm957843lz40z07tjj7qwtw03r',
  'erd1xrujyvk3gr9facy897dle6spvx0rvtxzgdh7hmkmc8lhzzltmwts2a0fte',
  'erd1rf46v7kw26vgymwnw9ljnvl8tspkp8ncaey6qpscw9cdeam2x38qjpaqpt',
  'erd1qw4qh64mpyx50xvmnfscj4ntczmn5q52tna6j4p9ar8qka7cl5aq6ckdh0',
  'erd1eev0r2cap3xukmxa5urwds9wac74tc87cndqsaec77xcq78enyhqs0cc6l',
  'erd1pg9zhgkcymzltf0j3pfy4jkhavgujpkf9xn0uxxnh4gl6xta3xpqshhgkg'
];
export const ONLY_OG = false;

export const DEFAULT_TOKEN_DECIMALS = 18;
