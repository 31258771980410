// slices/accountInfo/tokenStats.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface TokenStatsData {
  // your state fields
  nft: number;
  land: number;
  soulStakingAllocation: number;
  landStakingAllocation: number;
}

interface TokenStatsState {
  data: TokenStatsData | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: TokenStatsState = {
  data: null,
  status: 'idle',
  error: null
};

export const fetchTokenStats = createAsyncThunk(
  'tokenStats/fetch',
  async (_, { rejectWithValue }) => {
    try {
      // Initialize with zeros
      const data: TokenStatsData = {
        nft: 0,
        land: 0,
        soulStakingAllocation: 0,
        landStakingAllocation: 0
      };
      return data;
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const tokenStatsSlice = createSlice({
  name: 'tokenStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokenStats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTokenStats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTokenStats.rejected, (state, action: any) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default tokenStatsSlice.reducer;
