// store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import generalStatsReducer from './slices/generalStats';
import accountInfoReducer from './slices/accountInfo';
import marketplaceReducer from './slices/marketplace';

const store = configureStore({
  reducer: {
    generalStats: generalStatsReducer,
    accountInfo: accountInfoReducer,
    marketplace: marketplaceReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
