import React from 'react';
import BigNumber from 'bignumber.js';
import { kosonStakingPools, StakedKosonCollections } from 'config';
import {
  getSmartContract,
  Parser,
  Provider
} from 'contexts/Web3Context/helpers/getScObj';
import { AccountNft } from 'types';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export interface PoolInfo {
  apr: number;
  apy: number;
  lockupTimeframe: string;
  totalStakedByUser: number;
  batches: AccountNft[];
  contractAddress: string;
  poolIndex: number;
}

export interface IKosonStakingStateType {
  getPoolInfo: (poolIndex: number) => PoolInfo;
  getPendingRewards: (identifier: string) => number;
  getPoolApy: (poolIndex: number) => number;
  refreshState: () => Promise<void>;
  isLoading: boolean;
}

const useKosonStakingPoolInfo = (): IKosonStakingStateType => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [aprs, setAprs] = React.useState([0, 0, 0, 0, 0, 0]);
  const [apys, setApys] = React.useState([0, 0, 0, 0, 0, 0]);
  const [pendingRewards] = React.useState<{
    [identifier: string]: number;
  }>({});

  const stakedKoson = useSelector(
    (state: RootState) => state.accountInfo.stakedInventory.data?.stakedKoson
  );

  const getPendingRewards = (identifier: string) => {
    return pendingRewards[identifier];
  };

  const getPoolApy = (poolIndex: number) => {
    return apys[poolIndex];
  };

  const getPoolInfo = (poolIndex: number) => {
    const stakedPoolBatches =
      stakedKoson === undefined
        ? []
        : stakedKoson.filter(
            (b) => b.collection === StakedKosonCollections[poolIndex]
          );
    const totalPoolStake =
      stakedPoolBatches.length === 0
        ? 0
        : stakedPoolBatches.reduce((prev, crt) => (prev += crt.amount), 0);
    const poolInfo: PoolInfo = {
      apr: aprs[poolIndex],
      apy: apys[poolIndex],
      lockupTimeframe: getLockupTimeframe(poolIndex),
      batches: stakedPoolBatches,
      totalStakedByUser: totalPoolStake,
      contractAddress: kosonStakingPools[poolIndex],
      poolIndex: poolIndex
    };
    return poolInfo;
  };

  const getLockupTimeframe = (poolIndex: number) => {
    switch (poolIndex) {
      case 0:
        return '1 Day';
      case 1:
        return '3 Months';
      case 2:
        return '6 Months';
      case 3:
        return '12 Months';
      case 4:
        return '2 Years';
      case 5:
        return '4 Years';
      default:
        return 'N/A';
    }
  };

  React.useEffect(() => {
    refreshState().then(() => {
      setIsLoading(false);
    });
  }, [stakedKoson]);

  const refreshState = async () => {
    const rps = await loadAllRps();
    const poolAprs = [0, 0, 0, 0, 0, 0];
    const poolApys = [0, 0, 0, 0, 0, 0];
    for (let i = 0; i < rps.length; i++) {
      const apr = rps[i] * 365 * 100;
      const apy = Math.pow(1 + apr / 100 / 365, 365) - 1;
      poolAprs[i] = apr;
      poolApys[i] = apy * 100;
    }
    setAprs(poolAprs);
    setApys(poolApys);
  };

  const loadAllRps = async () => {
    const rps = [0, 0, 0, 0, 0, 0];
    for (let i = 0; i < kosonStakingPools.length; i++) {
      const poolRps = await getRps(kosonStakingPools[i]);
      rps[i] = poolRps;
    }
    return rps;
  };

  const getRps = async (scAddress: string) => {
    const contract = await getSmartContract(scAddress);
    const interaction = contract.methodsExplicit.getLastRPS();
    const query = interaction.buildQuery();
    const response = await Provider.queryContract(query);
    const endpointDef = interaction.getEndpoint();
    const parsedResponse = Parser.parseQueryResponse(response, endpointDef);
    if (parsedResponse.returnCode.isSuccess()) {
      const value = parsedResponse.firstValue?.valueOf();
      return value.div(new BigNumber(10).pow(9)).toNumber();
    }
    return 0;
  };

  return {
    getPoolInfo: getPoolInfo,
    getPendingRewards: getPendingRewards,
    getPoolApy: getPoolApy,
    refreshState: refreshState,
    isLoading: isLoading
  };
};

export default useKosonStakingPoolInfo;
