import { toLocaleStringOptions, toLocaleStringOptionsNoDecimals } from 'config';
import React, { useEffect, useState } from 'react';
import { AccountNft } from 'types';
import BigNumber from 'bignumber.js';
import { Web3Context } from 'contexts/Web3Context';
import { getCurrentStakeDay } from 'contexts/Web3Context/helpers/generalUtils';
import { ConfirmModal } from 'components/Modals/ConfirmModal';

const StakedKosonBatch = ({
  nft,
  icon,
  pendingRewards,
  handleClaim,
  handleUnstake
}: {
  nft: AccountNft;
  icon: React.FunctionComponent;
  pendingRewards: number;
  handleClaim: () => Promise<void>;
  handleUnstake: () => Promise<void>;
}) => {
  const [canClaim] = useState(
    (nft.daysUntilClaimDay === undefined ? 100 : nft.daysUntilClaimDay) <= 0
  );
  const [canUnstake] = useState(
    (nft.daysUntilClaimDay === undefined ? 100 : nft.daysUntilClaimDay) <= 0
  );
  return (
    <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12'>
      <div className='card border border-primary'>
        <div className='card-header border-0 pb-0'>
          <>
            Batch #{nft.nonce}
            <h2 className='card-title'>
              <strong>
                {nft.amount.toLocaleString(undefined, toLocaleStringOptions)}
              </strong>
            </h2>
          </>
        </div>
        <div className='card-body pb-0'>
          <ul className='list-group list-group-flush'>
            <li className='list-group-item d-flex px-0 justify-content-between bg-main text-white'>
              <strong>Staked</strong>
              <span className='mb-0'>
                {(getCurrentStakeDay() - (nft.stakeDay || 0)).toLocaleString(
                  undefined,
                  toLocaleStringOptionsNoDecimals
                )}{' '}
                days ago
              </span>
            </li>
            <li className='list-group-item d-flex px-0 justify-content-between bg-main text-white'>
              <strong>Earned</strong>
              <span className='mb-0'>
                {pendingRewards.toLocaleString(
                  undefined,
                  toLocaleStringOptions
                )}{' '}
                $KOSON
              </span>
            </li>
            <li className='list-group-item d-flex px-0 justify-content-between bg-main text-white'>
              <strong>Unlocks in</strong>
              {(nft.daysUntilClaimDay || 100) <= 0 ? (
                <span>Unlocked</span>
              ) : (
                <span className='mb-0'>
                  {(nft.daysUntilClaimDay || 0).toLocaleString(
                    undefined,
                    toLocaleStringOptionsNoDecimals
                  )}{' '}
                  days
                </span>
              )}
            </li>
          </ul>
        </div>
        <div className='card-footer pt-0 pb-0 text-center'>
          <div className='row'>
            {!canClaim && (
              <div className='col-md-6 col-sm-12 pt-3 pb-3'>
                <button className='btn btn-outline-primary btn-sm btn-rounded disabled'>
                  Claim rewards
                </button>
              </div>
            )}
            {canClaim && (
              <div className='col-md-6 col-sm-12 pt-3 pb-3'>
                <ConfirmModal
                  onConfirm={handleClaim}
                  title='Confirm Koson Staking claim rewards'
                  prompt='Claiming rewards will LOCK the staked $KOSON batch again - which means you will have to wait for it to unlock again in order to claim the reward again.'
                  buttonComponent={
                    <button className='btn btn-outline-primary btn-sm btn-rounded'>
                      Claim rewards
                    </button>
                  }
                />
              </div>
            )}
            {!canUnstake && (
              <div className='col-md-6 col-sm-12 pt-3 pb-3'>
                <button className='btn btn-outline-danger btn-sm btn-rounded disabled'>
                  Unstake
                </button>
              </div>
            )}
            {canUnstake && (
              <div className='col-md-6 col-sm-12 pt-3 pb-3'>
                <button
                  className='btn btn-outline-danger btn-sm btn-rounded'
                  onClick={handleUnstake}
                >
                  Unstake
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakedKosonBatch;
