// slices/accountInfo/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import marketplaceListingsReducer from './marketplaceListings';
// import other reducers

const marketplaceReducer = combineReducers({
  marketplaceListings: marketplaceListingsReducer
  // other subfeatures
});

export default marketplaceReducer;
